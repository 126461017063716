import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../../redux/Store";
import { ACM, showStatText } from "../../../../utils/utils";
import { editTitleOperation } from "../../../../utils/ProjectTitleUtils";
import { showMessage } from "../../../../utils/koolio-workspace";
import { projectTitleSliceActions } from "../../../../redux/slice/ProjectTitleSlice";

export default function ProjectTitle(props) {
  const [fileName, setFileName] = useState(sessionStorage.getItem("title"));
  const projectTitle = useSelector((state) => state.projectTitle.projectTitle);
  const projectEnv = useSelector((state) => state.projectTitle.env);
  if (projectEnv === "Recordspace") {
    sessionStorage.setItem("title", projectTitle);
  }

  const dispatch = useDispatch();
  //!  need to test do we really need this useEffect
  // useEffect(() => {
  //   let newFile = props.title;
  //   console.log(newFile);
  //   console.log(projectEnv);
  //   if (newFile && projectEnv !== "NestedRecording") {
  //     console.log("here");
  //     setFileName(props.title);
  //   } else if (newFile && projectEnv === "NestedRecording") {
  //     setFileName("New Recording");
  //   } else {
  //     let projectTitle = store.getState().projectData.resultStat.project_title; //! from here came from the title
  //     if (projectTitle) {
  //       setFileName(projectTitle);
  //     }
  //   }
  // }, [props.title]);
  const projectnameRef = useRef(null);
  useEffect(() => {
    // console.log(projectEnv);
    if (projectEnv === "NestedRecording") {
      setFileName("New Recording");
    } else if (projectTitle) {
      setFileName(projectTitle);
    }
  }, [projectTitle]);

  function editProjectname(event) {
    // console.log("edit btn click");
    if (store.getState().viewEditMode.mode != "edit") {
      showStatText("operation not permitted in view mode");
      return false;
    }

    const permResponse = ACM.checkPermission("editProjectTitle");
    if (!permResponse.success) {
      return showStatText(permResponse.message);
    }

    if (event) event.preventDefault();
    // const projectname = document.getElementById("projectname");
    // const projectname = document.getElementById("nested-projectname");
    const projectname = projectnameRef.current;
    const oldProjectName = projectname.textContent;
    projectname.contentEditable = true;
    projectname.focus();

    // Moving the cursor at the end of the title of project name
    const textNode = projectname.childNodes[0];
    // console.log(textNode);
    const range = document.createRange();
    const selection = window.getSelection();
    range.setStart(textNode, oldProjectName.length);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);

    projectname.addEventListener("keypress", function (e) {
      if (e.which === 13 || e.keyCode === 13) {
        e.target.blur();
        e.preventDefault();
      }
    });

    // Hide tooltip when double clicked
    projectname.setAttribute("title", "");

    projectname.removeEventListener("blur", handleBlur);
    projectname.addEventListener("blur", handleBlur);

    function handleBlur() {
      projectname.contentEditable = false;
      let newProjectName = projectname.textContent.trim();
      // console.log(newProjectName);
      // console.log(oldProjectName);
      if (!newProjectName) {
        projectname.textContent = oldProjectName;
        showStatText("project title cannot be empty");
        return false;
      }
      if (
        oldProjectName !== newProjectName &&
        projectEnv !== "NestedRecording"
      ) {
        editTitle(newProjectName, oldProjectName);
      } else if (projectEnv === "NestedRecording") {
        // console.log("here");
        // console.log(projectname);
        dispatch(
          projectTitleSliceActions.updateNestedRecordTitle({
            nestedRecordTitle: newProjectName,
          }),
        );
      }
    }
  }

  function copyProjectname(event) {
    if (event) event.preventDefault();

    const projectname = document.getElementById("projectname");
    const text = projectname.textContent;

    navigator.clipboard.writeText(text);

    // showStatText("Project name copied to clipboard");
    showMessage("Text copied.", "", 2000, "success", "workspace");
  }

  function editTitle(newTitle, oldTitle) {
    editTitleOperation(newTitle, oldTitle);
  }

  return (
    <div className="text-center header-project-name">
      <span title="save project" id="save-icon" className="save-icon" hidden>
        <i className="fa fa-circle" aria-hidden="true"></i>
      </span>
      <span
        // id="projectname"
        ref={projectnameRef}
        id={
          projectEnv === "NestedRecording"
            ? "nested-projectname"
            : "projectname"
        }
        // onDoubleClick={(event) => projectnameDblClick(event)}
        className="project-name tt"
        data-bs-placement="bottom"
      >
        {/* {projectEnv === "NestedRecording" ? fileName : ""} */}
        {console.log(projectEnv)}
        {projectEnv === "NestedRecording" || "Recordspace"
          ? fileName
          : projectTitle}
        {/* You can set the content of the project name here */}
      </span>
      <span id="projectTitle-edit-copy">
        <img
          title="projectTitle-edit"
          class="img-fluid"
          id="projectTitle-edit"
          src="/img/edit-orange.png"
          alt=""
          onClick={(event) => editProjectname(event)}
        />
        <img
          title="projectTitle-copy"
          class="img-fluid"
          id="projectTitle-copy"
          src="/img/copy-orange.png"
          alt=""
          onClick={(event) => copyProjectname(event)}
        />
      </span>
    </div>
  );
}
