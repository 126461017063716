import React from "react";
import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";
import {
  onwssclose,
  onwsserror,
  onwssmessage,
  onwssopen,
  sendLog,
  sendOperation,
  showStatText,
  timeout,
} from "../utils/utils";
import store from "../redux/Store";
import { Koolioai } from "../utils/cognitoAuth";
import { getNotifications } from "../services/NotificationServices";
import { viewNotification } from "../components/pages/kooliospace/kooliospaceUtils";

function IdleTimerComponent({ children }) {
  // Handler to be called when the user goes idle
  const handleOnIdle = () => {
    // console.log("User is idle");
    let jobname = store.getState().projectData.resultStat.jobname || null;
    sendLog({
      activity: "listner",
      target: "user is idle or away",
      timeStamp: new Date().toISOString(),
      jobname,
      status: "success",
      statusMsg: "success",
    });
  };

  // Handler for when the user becomes active again
  const handleOnVisible = async () => {
    // console.log("User is visible");
    let jobname = store.getState().projectData.resultStat.jobname || null;
    sendLog({
      activity: "listner",
      target: "user is active or back",
      timeStamp: new Date().toISOString(),
      jobname,
      status: "success",
      statusMsg: "success",
    });
    await timeout(5000);
    if (!Koolioai.isLive) {
      showStatText("reconnecting...", false);
      Koolioai.goLive(onwssopen, onwsserror, onwssclose, onwssmessage).catch(
        Koolioai.handleError,
      );
    }
  };

  const handleOnAwayBack = async () => {
    // console.log("User is back from being away", Koolioai.isLive);
    await timeout(5000);
    if (!Koolioai.isLive) {
      let isOperationsTransActive =
        store.getState().operationListData.isOperationsTransActive;
      if (!isOperationsTransActive) sendOperation();
      showStatText("reconnecting...", false);
      try {
        await Koolioai.goLive();
        setTimeout(async () => {
          try {
            const notifications = await getNotifications();
            if (notifications) {
              const filteredNotifications = notifications.notifications.filter(
                (notification) =>
                  notification.notification_action.includes("release_id"),
              );
              if (
                filteredNotifications.length > 0 &&
                !filteredNotifications[0].is_viewed
              ) {
                viewNotification(
                  null,
                  filteredNotifications[0].notification_action,
                  true,
                );
              }
            }
          } catch (error) {
            console.error("Error fetching notifications:", error);
          }
        }, 5000);
      } catch (error) {
        Koolioai.handleError(error);
      }
    }
  };

  // Use IdleTimer to monitor user's activity
  useIdleTimer({
    timeout: 5000, // 5 seconds
    onIdle: handleOnIdle,
    onActive: handleOnAwayBack,
    onVisible: handleOnVisible,
    debounce: 500, // Optional: helps reduce the number of state updates
  });

  return <>{children}</>;
}

export default IdleTimerComponent;
