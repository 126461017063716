import React, { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import KoolioNavbar from "./KoolioNavbar";
import FileUploader from "../kooliospace/projectUpload/FileUploader";
import RecordingFileName from "../../modals/RecordingFileName";
import {
  create_UUID,
  getDuration,
  handleWindowLoadForKoolioTagline,
  showStatText,
} from "../../../utils/utils";
import {
  ProjectUploadStatus,
  cueFormatters,
  hideplaylistloader,
  showplaylistloader,
  uploadAudio,
  saveKooliospace,
  checkForUnSyncedOperationInBackend,
  getStatus,
  updateKSFromLocal,
} from "./kooliospaceUtils";
import { useDispatch, useSelector } from "react-redux";
import { projectDataSliceActions } from "../../../redux/slice/ProjectDataSlice";
import { Koolioai, _config } from "../../../utils/cognitoAuth";
import {
  addAudioFile,
  getArrayBufferFromSrcURL,
  retrieveAudioFileByJobname,
} from "../../../utils/indexedDButils";
import {
  initiateJobForRecordedFile,
  initiateJobForUploadedFile,
} from "../../../services/uploadService";
import "bootstrap/dist/css/bootstrap.css";
import "../../../css/dark/LandingPage.css";
import "../../../css/dark/Kooliospace.css";
import { uploadFileToWorkspaceActions } from "../../../redux/slice/FileSelectedForWorkspaceSlice";
import { recordActions } from "../../../redux/slice/RecordSlice";
import { exportOptionSliceActions } from "../../../redux/slice/ExportOptionsSlice";
import NotificationModalView, {
  NotificationModalAlreadyViewed,
  NotificationWelcomeModal,
} from "./modals/NotificationModal";
import axios from "axios";
import store from "../../../redux/Store";
import { screenReloadSliceActions } from "../../../redux/slice/ScreenReloadSlice";
import { getKooliospace } from "../../../services/KooliospaceServices";
import { getResultStat } from "../../../services/globalServices";
import DiarizationModal from "../../modals/DiarizationModal";
import TranscriptionModal from "../../modals/TranscriptionModal";
import { projectTitleSliceActions } from "../../../redux/slice/ProjectTitleSlice";
import { uploadProgressActions } from "../../../redux/slice/uploadprogreestatus";
import DeleteModal from "../../modals/DeleteModal";
import topbar from "topbar";
import useBootstrapTooltips from "../../../hooks/useBootstrapTooltips";
import { playlistLoaderSliceActions } from "../../../redux/slice/PlaylistLoaderSlice";
import { parse } from "querystring";
import jsPDF from "jspdf";
import { ChevronUp, ChevronDown } from "lucide-react";

// for uploaded file list

const Kooliospace = () => {
  useBootstrapTooltips();
  const navigate = useNavigate();

  const [addprojectOptionBtn, setAddProjectOptionBtn] = useState(true);
  const [addBtnIcon, setAddBtnIcon] = useState(true); // better if we can remove it
  const [projectList, setProjectList] = useState(true);
  const [enableRecordFileNameModal, setEnableRecordFileNameModal] =
    useState(false);
  const notificationUpdateModalData = useSelector(
    (state) => state.notificationUpdateModalInfo,
  );
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showNotificationModalInfo, setShowNotificationModalInfo] =
    useState("");
  const [showNotificationModalViewed, setShowNotificationModalViewed] =
    useState(false);
  const [showWelcomeUserModal, setWelcomeUserModal] = useState(false);
  // for transcription and diarization modal
  const [showTranscriptionModal, setShowTranscriptionModal] = useState(false);

  const [showDiarizationModal, setShowDiarizationModal] = useState(false);
  const [selectedFile, setselectedFile] = useState("");

  let newRecordedFile = useSelector((state) => state.file);
  const [fileList, setFileList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [jobName, setJobName] = useState("");
  const [deletedFileName, setDeletedFileName] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  let dispatch = useDispatch();
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    // Check if display name is stored in localStorage
    const prevstate = sessionStorage.getItem("prevstate");
    let username = JSON.parse(localStorage.getItem("username"));
    if (username) {
      setDisplayName(username);
    }
    setTimeout(() => {
      let username = Koolioai.getDisplayName();
      const profileImageUrl = `https://ui-avatars.com/api/?name=${username}&background=181818&color=fff&rounded=true&border=ffa500&size=56`;
      document.getElementById("user-profile-home").src = profileImageUrl;
      setDisplayName(username);
      document.title = `koolio.ai`;
    }, 2000);
    if (
      window.location.href.includes("/kooliospace") &&
      prevstate !== "workspace" &&
      prevstate !== "recordspace"
    ) {
      handleWindowLoadForKoolioTagline(false);
    }
    handlewindowLoad();
    window.addEventListener("beforeunload", handlewindowBeforeLoad);
    return () => {
      window.removeEventListener("beforeunload", handlewindowBeforeLoad);
    };
  }, []);

  const projectOptions = addprojectOptionBtn
    ? "uploadProject"
    : "uploadProject-active";
  const addBtn = addBtnIcon ? "" : "addBtn-active";
  // const addBtnTitleCase = addBtnIcon ? `Welcome, ${username}` : "Add project";
  const addBtnTitleCase = addBtnIcon
    ? `Welcome, ${displayName}`
    : "Add project";
  const displayProjectList = projectList ? "" : "hideProjectList";
  let uploadProgressStatus = {
    recordUploadProgress: 0,
    uploadedProjectList: [],
    internetSpeed: 1,
    timeout: 30000, //! need to check why use 30s delay
  };
  function handleProjectOptionButton() {
    const addBtn = document.getElementById("add-btn");

    if (addBtn)
      addBtn.title =
        addBtn.title === "New Project" ? "Return to projects" : "New Project";

    setAddProjectOptionBtn(!addprojectOptionBtn);
    setAddBtnIcon(!addBtnIcon);
    setProjectList(!projectList);
  }

  async function deleteKoolioJob(kooliojob) {
    // ksUpdatedLocally.push(true);
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
      .then((tokens) =>
        axios.delete(_config.api + "/kooliospace", {
          data: {
            jobname: kooliojob,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        }),
      )
      .then(() =>
        getKooliospace().then((kooliospace) => {
          kooliospace = kooliospace.filter((a) => a.jobname !== kooliojob);
          saveKooliospace(kooliospace);
          setIsDeleted(true);
          return Promise.resolve();
        }),
      );
  }

  function prepareKooliospaceUI(uploadFileList) {
    // Append the selected file name to the 'koolio-project' div
    const koolioProjectDiv = document.getElementById("koolio-project");
    koolioProjectDiv.innerHTML = ``;
    uploadFileList.map((uploadedFile) => {
      if (koolioProjectDiv) {
        let jobname = uploadedFile.jobname || uploadedFile.jobName;
        let filename = uploadedFile.name || uploadedFile.projectTitle;

        const fileContainer = document.createElement("div");
        const fileNameDiv = document.createElement("div");

        fileNameDiv.id = jobname;
        fileNameDiv.classList.add("uploadedFiles");

        const fileNameDivInner = document.createElement("div");
        if (uploadedFile.name) {
          fileNameDivInner.textContent =
            uploadedFile.name + uploadedFile.format;
        } else if (uploadedFile.projectTitle) {
          fileNameDivInner.textContent =
            uploadedFile.projectTitle + "." + uploadedFile.format;
        } else {
          fileNameDivInner.textContent =
            uploadedFile.file.name + uploadedFile.format;
        }
        // fileNameDivInner.textContent = uploadedFile.name? uploadedFile.name: (uploadedFile.file.name || uploadedFile.projectTitle)
        fileNameDivInner.id = jobname + "_file";

        const fileTimeDuration = document.createElement("div");
        fileTimeDuration.classList.add("koolio-project-duration");

        let MHSTime = cueFormatters("mm:ss")(uploadedFile.duration);
        fileTimeDuration.textContent = MHSTime;

        // fileTimeDuration.id = jobname + "_projectDuration";

        // Create "View" button
        const viewBtn = document.createElement("button");
        viewBtn.id = jobname + "_view";
        viewBtn.classList.add("viewBtn");
        viewBtn.textContent = "View";
        viewBtn.style.display = "none"; // Initially hidden
        viewBtn.addEventListener("click", async () => {
          showStatText("fetching resultstat", false);
          let fileName = uploadedFile.projectTitle || uploadedFile.file.name;
          try {
            await viewKoolioProject(jobname, fileName);
          } catch (err) {
            console.error(err);
          }
        });

        // Create "Delete" button
        const deleteBtn = document.createElement("button");
        deleteBtn.id = jobname + "_del";
        deleteBtn.classList.add("deleteBtn");
        deleteBtn.textContent = "Delete";
        deleteBtn.style.display = "none"; // Initially hidden
        deleteBtn.addEventListener("click", () => {
          setShowDeleteModal(true);
          setJobName(jobname);
          setDeletedFileName(filename);
        });
        let originalDuration;

        // Handle hover events
        fileNameDiv.addEventListener("mouseenter", () => {
          originalDuration = fileTimeDuration.textContent;

          viewBtn.style.display = "inline";

          deleteBtn.style.display = "inline";
          fileNameDivInner.style.display = "none";
          fileTimeDuration.style.opacity = "0.4";
          fileTimeDuration.textContent = fileNameDivInner.textContent;
        });

        fileNameDiv.addEventListener("mouseleave", () => {
          viewBtn.style.display = "none";
          deleteBtn.style.display = "none";
          fileNameDivInner.style.display = "unset";
          fileTimeDuration.style.opacity = "1";
          fileTimeDuration.textContent = originalDuration;
        });

        fileNameDiv.appendChild(viewBtn);
        fileNameDiv.appendChild(deleteBtn);
        fileNameDiv.appendChild(fileNameDivInner);

        fileContainer.appendChild(fileNameDiv);
        fileContainer.appendChild(fileTimeDuration);

        koolioProjectDiv.prepend(fileContainer);
      }
    });
  }

  async function viewKoolioProject(jobname, fileName) {
    // show loader when we click on view
    store.dispatch(
      playlistLoaderSliceActions.updateLoaderValue({ value: true }),
    );
    // fetch resultstat from db or backend
    const resultStat = await getStatus(jobname);
    //export filename
    dispatch(
      exportOptionSliceActions.updateFileName({
        fileName,
      }),
    );
    dispatch(
      projectTitleSliceActions.updateProjectTitle({
        env: "Kooliospace",
        projectTitle: fileName,
      }),
    );
    dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
    dispatch(exportOptionSliceActions.clearData());
    navigate(`/workspace/${jobname}`, { replace: true });
  }

  function createProjectProgressBar(kooliojob, isTranscribing = false) {
    const progressBar = document.createElement("a");
    progressBar.id = kooliojob.jobname
      ? kooliojob.jobname + "_progress"
      : "progressBar_placeholder";
    progressBar.title = "Project upload progress";
    progressBar.classList.add(
      "d-flex",
      // "w-100",
      "mx-auto",
      "justify-content-center",
    );

    const progressBarOuter = document.createElement("div");
    progressBarOuter.setAttribute(
      "id",
      kooliojob.jobname
        ? kooliojob.jobname + "_progressBarOuter"
        : "progressBarOuter_placeholder",
    );
    progressBarOuter.classList.add("progress", "progress-style");
    progressBar.appendChild(progressBarOuter);

    const p = document.createElement("p");
    const span = document.createElement("span");
    // Initialize progress to 1% if jobname is not available
    let initialProgress = kooliojob.jobname ? 2 : 1;
    span.textContent = initialProgress + "";

    span.id = kooliojob.jobname
      ? kooliojob.jobname + "_innerProgressText"
      : "innerProgressText_placeholder";
    const text = isTranscribing
      ? document.createTextNode("% transcribed")
      : document.createTextNode("% uploaded");
    p.appendChild(span);
    p.appendChild(text);
    progressBarOuter.appendChild(p);

    const progressBarInner = document.createElement("div");
    progressBarInner.classList.add("progress-bar", "progress-increment");
    progressBarInner.id = kooliojob.jobname
      ? kooliojob.jobname + "_innerProgress"
      : "innerProgress_placeholder";
    if (kooliojob.jobname) {
      const uploadedProject = uploadProgressStatus.uploadedProjectList.find(
        (project) => project.id === kooliojob.jobname,
      );
      if (uploadedProject && uploadedProject.progress) {
        const progress = Math.ceil(uploadedProject.progress);
        progressBarInner.style.width = progress + "";
        span.textContent = progress;
      } else {
        // If progress is not available, set it to initial progress
        progressBarInner.style.width = initialProgress + "%";
      }
    }

    progressBarInner.setAttribute("role", "progressbar");
    progressBarInner.setAttribute("aria-valuenow", initialProgress.toString());
    progressBarInner.setAttribute("aria-valuemin", "0");
    progressBarInner.setAttribute("aria-valuemax", "100");
    progressBarOuter.appendChild(progressBarInner);

    return progressBar;
  }

  async function handleFileSelect(
    file,
    transcriptOption,
    diarization,
    isFileRecorded = false,
    durationOfRecordedFile = false,
  ) {
    const koolioProjectDiv = document.getElementById("koolio-project");
    if (koolioProjectDiv) {
      let duration = isFileRecorded
        ? parseFloat(durationOfRecordedFile)
        : await getDuration(URL.createObjectURL(file));
      const minutes = Math.floor(duration / 60);
      const remainingSeconds = (duration % 60).toFixed(0);
      let formattedMinutes = String(minutes).padStart(2, "0");
      let formattedSeconds = String(remainingSeconds).padStart(2, "0");

      const fileContainer = document.createElement("div");
      fileContainer.setAttribute("class", "uploadFileContainer");

      const FileNameDivInner = document.createElement("div");
      FileNameDivInner.classList.add("uploadedFiles");

      const fileNameDiv = document.createElement("div");
      fileNameDiv.textContent = file.name;

      const fileTimeDuration = document.createElement("div");
      fileTimeDuration.classList.add("koolio-project-duration");
      fileTimeDuration.textContent = `${formattedMinutes}:${formattedSeconds}`;
      fileTimeDuration.style.display = "none";

      const viewBtn = document.createElement("button");
      const Jobname = "temp_jobname";
      viewBtn.id = Jobname + "_view";
      viewBtn.classList.add("viewBtn");
      viewBtn.textContent = "View";
      viewBtn.style.display = "none";
      viewBtn.style.opacity = "0.4";
      viewBtn.disabled = true;

      const deleteBtn = document.createElement("button");
      deleteBtn.id = Jobname + "_del";
      deleteBtn.classList.add("deleteBtn");
      deleteBtn.textContent = "Delete";
      deleteBtn.style.display = "none";
      deleteBtn.style.opacity = "0.4";
      deleteBtn.disabled = true;
      deleteBtn.addEventListener("click", () => {
        setShowDeleteModal(true);
        setJobName(jobname);
        setDeletedFileName(filename);
      });

      fileContainer.appendChild(FileNameDivInner);
      FileNameDivInner.appendChild(viewBtn);
      FileNameDivInner.appendChild(deleteBtn);
      FileNameDivInner.appendChild(fileNameDiv);
      fileContainer.appendChild(fileTimeDuration);
      koolioProjectDiv.prepend(fileContainer);
      let progressBar;
      if (transcriptOption) {
        progressBar = createProjectProgressBar({}); // Initially with 0% progress
        progressBar.style.display = "none"; // Initially hide the progress bar
        fileContainer.appendChild(progressBar);
      }

      let uploadedProject = new ProjectUploadStatus();
      uploadedProject.uploadedFile = file;
      const filename = file.name;
      const kooliojob = await Koolioai.createJob({
        duration,
        filename,
        diarization,
      });

      if (!transcriptOption) {
        kooliojob.status = "completed";
        updateKSFromLocal(kooliojob);
        showplaylistloader();
      }
      const resultStat = isFileRecorded
        ? await initiateJobForRecordedFile(kooliojob)
        : await initiateJobForUploadedFile(kooliojob);
      const jobname = resultStat.jobname;
      resultStat.transcribed = transcriptOption;
      resultStat.page_loadable = true;
      fileNameDiv.id = jobname + "_file";
      fileTimeDuration.id = jobname + "_projectDuration";
      deleteBtn.id = jobname + "_del";
      viewBtn.id = jobname + "_view";

      dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
      kooliojob.jobname = jobname;
      uploadedProject.id = jobname;
      uploadProgressStatus.uploadedProjectList.push(uploadedProject);
      dispatch(
        uploadProgressActions.updateProgStatus({ uploadProgressStatus }),
      );

      const uploadedFile = {
        file: file,
        kooliojob: kooliojob,
        resultStat: resultStat,
        uploadedProject: uploadedProject,
      };

      // Update progress bar after jobname is fetched
      if (transcriptOption) {
        progressBar = createProjectProgressBar(
          kooliojob,
          kooliojob.status.toLowerCase() !== "uploading", //! this line causing the issue for sho
        );
        progressBar.style.display = "block"; // Show the progress bar now that we have the job name
        fileContainer.replaceChild(progressBar, fileContainer.lastChild);
      }

      fileContainer.addEventListener("mouseenter", () => {
        viewBtn.style.display = "inline";
        deleteBtn.style.display = "inline";
        fileNameDiv.style.display = "none";
        fileTimeDuration.style.visibility = "hidden";
        if (progressBar) {
          progressBar.style.visibility = "hidden";
        }
      });

      fileContainer.addEventListener("mouseleave", () => {
        viewBtn.style.display = "none";
        deleteBtn.style.display = "none";
        // fileContainer.style.display="unset";
        fileNameDiv.style.display = "unset";
        fileTimeDuration.style.visibility = "visible";
        if (progressBar) {
          progressBar.style.visibility = "visible";
        }
      });

      // Add click event for view button
      viewBtn.addEventListener("click", () => {
        viewKoolioProject(
          uploadedFile.kooliojob.jobname,
          uploadedFile.kooliojob.project_title,
        ); // Make sure viewKoolioProject is defined and accessible
      });

      await uploadAudio(uploadedFile, transcriptOption, diarization);
    }
  }

  async function handleRecord() {
    setAddProjectOptionBtn(!addprojectOptionBtn);
    enableRecordFileNameModal
      ? setEnableRecordFileNameModal(false)
      : setEnableRecordFileNameModal(true);
  }

  useEffect(() => {
    getKooliospace().then(() => {
      let uploadedFile = JSON.parse(localStorage.getItem("kooliospace"));
      let uploadFileList = [];
      if (uploadedFile) {
        // Array to store formatted data
        const formattedData = [];

        // Iterate over each object in the data array
        for (const item of uploadedFile) {
          // Extract necessary information
          const formattedItem = {
            duration: item.duration,
            updatedAt: new Date(item.updated_at),
            projectTitle: item.project_title,
            jobName: item.jobname,
            format: item.format,
          };
          // Push formatted item into the array
          uploadFileList.push(formattedItem);
        }
        uploadFileList.reverse();
        setFileList(uploadFileList);
        prepareKooliospaceUI(uploadFileList);
        setIsDeleted(false);
        if (newRecordedFile.file.file) {
          // uploadFileList.push(newRecordedFile.file)
          dispatch(recordActions.clearRecordedFileFromStore());
          handleFileSelect(
            newRecordedFile.file.file,
            newRecordedFile.file.transcription,
            newRecordedFile.file.diarization,
            true,
            newRecordedFile.file.audioDuration,
          ).then(() => {
            newRecordedFile = "";
          });
        }
      }
    });
  }, [isDeleted]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Perform actions before the component unloads
      event.preventDefault();
      event.returnValue = "";
      if (
        jobName != null &&
        fileList.length > 0
        //|| isOperationsTransActive === true
      ) {
        event.returnValue = "You have some unsaved changes !";
      } else {
        for (const uploadedProject of uploadProgressStatus.uploadedProjectList) {
          if (uploadedProject.status.toLowerCase() === "uploading")
            event.returnValue = "Your project is still uploading!";
        }
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  function handleFileWithDiarizationAndTranscription(file) {
    setselectedFile(file);
    handleProjectOptionButton();
    handleTranscriptionMOdal();
  }
  const handleTranscriptionMOdal = async () => {
    setShowTranscriptionModal(true);
  };

  async function handleTranscriptionYes() {
    // setTranscription(true)
    setShowTranscriptionModal(false);
    let transcriptionSelected = true;
    sessionStorage.setItem(
      "transcriptUploadOption",

      JSON.stringify(transcriptionSelected),
    );
    await handleDiarizationModal();
  }
  function handleTranscriptionNo() {
    // setTranscription(false)
    let transcriptionSelected = false;
    sessionStorage.setItem(
      "transcriptUploadOption",
      JSON.stringify(transcriptionSelected),
    );
    setShowTranscriptionModal(false);
    handleFileSelect(selectedFile, false, false);
  }

  function handleTranscirptionClose() {
    setShowTranscriptionModal(false);
  }

  function handleDeleteModalYes() {
    topbar.show();
    (function step() {
      setTimeout(function () {
        if (topbar.progress("+.01") < 1) step();
      }, 32);
    })();
    deleteKoolioJob(jobName).then(() => {
      topbar.hide();
      setShowDeleteModal(false);
    });
  }
  function handleDeleteModalCancel() {
    setShowDeleteModal(false);
  }

  // diarization
  const handleDiarizationModal = async () => {
    setShowDiarizationModal(true);
  };

  function handleDiarizationYes() {
    // setDiarization(true)
    setShowDiarizationModal(false);
    handleFileSelect(selectedFile, true, true);
  }
  function handleDiarizationNo() {
    // setDiarization(false)
    setShowDiarizationModal(false);
    handleFileSelect(selectedFile, true, false);
  }

  function handleDiarizationClose() {
    setShowDiarizationModal(false);
  }

  useEffect(() => {
    if (notificationUpdateModalData.data != "") {
      if (notificationUpdateModalData.data.is_viewed) {
        setShowNotificationModalViewed(true);
      } else {
        setShowNotificationModalInfo(notificationUpdateModalData.data);
        setShowNotificationModal(true);
      }
    }
  }, [notificationUpdateModalData]);

  function closeNotificationModal() {
    setShowNotificationModalViewed(false);
  }
  function closeNotificationWelcomeModal() {
    setWelcomeUserModal(false);
  }

  function handlewindowLoad() {
    const savedValue = localStorage.getItem("savedVariable");
    if (savedValue) {
      let screenReloads = JSON.parse(savedValue);
      if (screenReloads) {
        setTimeout(() => {
          // welcoming user after updates
          setWelcomeUserModal(true);
          // $('#half-modals').html(notificationWelcomeModal)
          // $('#half-modals').css('visibility', 'visible')
          // koolioModals()
        }, 5000);
        /*We are sending that the notification when the specific release id is opened for the first time in frontend*/
        const param = localStorage.getItem("latest_release_id");
        axios
          .post(_config.api + "/api-release-notifications", param)
          .then((res) => {
            return res;
          })
          .catch((err) => {
            // console.log(err)
          });
        store.dispatch(
          screenReloadSliceActions.updateScreenReload({ value: false }),
        );
      }
    }
  }

  function handlewindowBeforeLoad() {
    sessionStorage.setItem("prevstate", "kooliospace");
  }

  const scrollKoolioProject = (e, direction) => {
    e.preventDefault();

    const koolioProject = document.getElementById("koolio-project");
    if (!koolioProject) return; // Does not exist, return

    const scrollHeight = koolioProject.scrollTop;
    koolioProject.scrollTo({
      top: direction === "up" ? scrollHeight - 100 : scrollHeight + 100,
      behavior: "smooth",
    });
  };

  return (
    <>
      <article className="post col-8-mod">
        <div className="post-content" id="post-content">
          <div id="playlist-loader">
            <div
              className="spinner-container"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.4vw",
              }}
            >
              <img
                src="/image/koolio-icon-workspace.svg"
                style={{
                  width: "5.856515373352855vw",
                }}
                className="ajax-loader"
                alt="Loading Spinner"
              />
              <p
                className="text-white"
                style={{
                  fontSize: "1.171303074670571vw",
                }}
              >
                Please Wait...
              </p>
            </div>
          </div>
          <div
            id="playlist"
            style={{
              borderLeft: "double #E2522B",
              borderRight: "double #E2522B",
            }}
          ></div>
        </div>
      </article>
      <div id="landing-screen">
        <KoolioNavbar
          fileList={fileList}
          prepareKooliospaceUI={prepareKooliospaceUI}
        />

        <div id="waveform_time"></div>
        {/* <!-- landing animations --> */}
        <div
          className="koolio-landing text-center"
          style={{ position: "relative", top: "10%" }}
        >
          <div className="koolio-logo">
            <img
              src="../../image/landing-page/koolio.ai.png"
              id="koolio-logo"
              className="logo"
              alt="koolio.ai"
            />
          </div>
        </div>
        <div
          id="landing-taglines-profile"
          className="landing-taglines d-flex justify-content-center"
        ></div>
        {/* <!-- koolio-space-area --> */}
        <div className="row koolio-space" id="koolio-space">
          {/* <!-- kooliospace text --> */}
          <div className="col-12 landing-koolio-text text-center">
            <div className="landing-text">
              <span className="koolio-space-text">KOOLIOSPACE</span>
            </div>
            <div className="landing-user text-white">
              <span className="koolio-user" id="add-project-user">
                {addBtnTitleCase}
              </span>
            </div>
          </div>

          {/* <!-- kooliospace project titles --> */}
          <div className="col-4"></div>
          <div
            className={`col-4 project-titles ${displayProjectList}`}
            id="koolio-project"
          >
            <input
              type="file"
              id="drag-n-drop-file"
              accept=".wav,.mp3,.mp4,.aac,.ogg,.m4a"
              style={{ display: "none" }}
            />
          </div>
          <div className="col-4"></div>

          {/* Chevron Icons */}
          {displayProjectList === "" && (
            <div className="chevron-icons-container">
              <ChevronUp
                className="chevron-up"
                onClick={(e) => scrollKoolioProject(e, "up")}
              />
              <ChevronDown
                className="chevron-down"
                onClick={(e) => scrollKoolioProject(e, "down")}
              />
            </div>
          )}

          {/* <!-- Upload options --> */}
          <div id="addProject" className={`upload-project ${projectOptions}`}>
            <div className="record" id="recordbtn">
              <img
                className=""
                data-bs-placement="right"
                title="Start Recording"
                src="../../image/Record.svg"
                alt=""
                onClick={handleRecord}
              />
              {enableRecordFileNameModal && (
                <RecordingFileName closeModal={handleRecord} />
              )}
            </div>
            <div className="upload" id="upbtn" title="Upload Project">
              <FileUploader
                src="kooliospace"
                onFileSelect={handleFileWithDiarizationAndTranscription}
              />
            </div>
          </div>

          {/* <!-- add-close-button --> */}
          <div
            className="d-flex justify-content-center align-items-center fixed-bottom "
            style={{ paddingBottom: "2%", zIndex: "0" }}
          >
            <img
              src="../../image/landing-page/Add-Project-Btn.png"
              className={`add-btn ${addBtn}`}
              id="add-btn"
              data-bs-placement="right"
              title="New Project"
              onClick={handleProjectOptionButton}
              alt=""
            />
          </div>
        </div>
        {showDeleteModal && (
          <DeleteModal
            projectName={deletedFileName}
            onClose={handleDeleteModalCancel}
            onDeleteYes={handleDeleteModalYes}
          />
        )}
        {showTranscriptionModal && (
          <TranscriptionModal
            onClose={handleTranscirptionClose}
            onTranscriptYes={handleTranscriptionYes}
            onTranscriptNo={handleTranscriptionNo}
          ></TranscriptionModal>
        )}
        {showDiarizationModal && (
          <DiarizationModal
            onClose={handleDiarizationClose}
            onDiarizationYes={handleDiarizationYes}
            onDiarizationNo={handleDiarizationNo}
          ></DiarizationModal>
        )}

        {showNotificationModal && (
          <NotificationModalView
            data={showNotificationModalInfo}
          ></NotificationModalView>
        )}
        {showNotificationModalViewed && (
          <NotificationModalAlreadyViewed
            onClose={closeNotificationModal}
          ></NotificationModalAlreadyViewed>
        )}
        {showWelcomeUserModal && (
          <NotificationWelcomeModal
            onClose={closeNotificationWelcomeModal}
          ></NotificationWelcomeModal>
        )}
        <div className="footer">
          <span id="stat-text"></span>
        </div>
      </div>
    </>
  );
};

export default Kooliospace;
