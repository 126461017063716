import { Koolioai, _config } from "../utils/cognitoAuth";
import axios from "axios";
import { showStatText } from "../utils/utils";
import store from "../redux/Store";
import { json } from "react-router-dom";

export async function populateSrc(data) {
  if (!data.statusCode && "track_types" in data.resultStat) {
    for (let k = 0; k < data.resultStat.track_types.length; k++) {
      let trackType = data.resultStat.track_types[k] + "_segments";
      if (trackType in data.resultStat) {
        for (let i = 0; i < data.resultStat[trackType].length; i++) {
          if ("filename" in data.resultStat[trackType][i]) {
            data.resultStat[trackType][i].src = await Koolioai.getSignedObject(
              data.resultStat[trackType][i].filename,
            );
          }
        }
      }
    }
  }
  return Promise.resolve(data);
}

export function getResultStatFromBackend(
  jobname,
  trans = false,
  refid,
  newArch = false,
) {
  if (trans) {
    trans = false;
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
      .then((tokens) =>
        axios.get(_config.api + "/job-status", {
          params: {
            jobname,
            refid,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        }),
      )
      .then((response) => populateSrc(response.data));
  } else {
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
      .then((tokens) =>
        axios.get(_config.api + "/get-latest-result-stat", {
          params: {
            jobname,
            refid,
            newArch,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        }),
      )
      .then((response) => populateSrc(response.data));
  }
}

export function getResultStat(jobname, trans = false, newArch = false) {
  return getResultStatFromBackend(jobname, trans, null, newArch).then(
    (response) => {
      if (response.statusCode) {
        return Promise.resolve(response);
      } else {
        return Promise.resolve(response.resultStat);
      }
      //else if (response.resultStat.job_code === 101) {
      //    let latestLocalOpRefidData = JSON.parse(localStorage.getItem("latestLocalOpRefid"))
      //    if(latestLocalOpRefidData && latestLocalOpRefidData[jobname])
      //        playlist.setJobHead(latestLocalOpRefidData[jobname])
      //    else{
      //        playlist.setJobHead(response.refid)
      //    }
      //    //playlist.setJobHead(response.refid)
      //    playlist.setJobCode(101)
      //    return Promise.resolve(response.resultStat)
      //}
      //else if (response.resultStat.job_code === 102) {
      //    recordPlaylist.setJobHead(response.refid)
      //    playlist.setMode('edit')
      //    recordPlaylist.setJobCode(102)
      //    return Promise.resolve(response.resultStat)
      //}
    },
  );
}

export async function sendOperationToBackend(operationInfo) {
  let params;
  if (operationInfo.params) {
    params = { ...operationInfo.params };
    params.inputs = { ...operationInfo.params.inputs };
    params.inputs.tracks = [];
    if (
      operationInfo.params.inputs.tracks &&
      operationInfo.params.inputs.tracks.length
    ) {
      operationInfo.params.inputs.tracks.map((track) => {
        params.inputs.tracks.push({ ...track });
      });
    }
  } else {
    params = { ...operationInfo.operationInfo };
  }
  if (params.inputs.tracks && params.inputs.tracks.length) {
    const promises = params.inputs.tracks.map((track) => {
      if (typeof track.src !== "string") {
        if (
          operationInfo.trackInfo &&
          operationInfo.trackInfo.customClass.split("-")[0] === "sfx" &&
          track.attachmentid
        ) {
          return new Promise((r) =>
            r(
              Koolioai.getSignedCompressed(
                track.attachmentid,
                params.inputs.genAi,
              ),
            ),
          );
        } else {
          return new Promise((r) =>
            r(
              Koolioai.getSignedClipboard(
                track.id + "." + params.inputs.fileFormat,
              ),
            ),
          );
        }
      } else {
        return new Promise((r) => r(track.src));
      }
    });
    const urls = await Promise.all(promises);
    urls.map((url, i) => {
      params.inputs.tracks[i].src = url;
    });
    showStatText("saving...", false);
    let tokens = await Promise.all([
      Koolioai.authToken(),
      Koolioai.accessToken(),
    ]);
    return axios
      .post(_config.api + "/operations", params, {
        headers: {
          Authorization: `${tokens[0]}`,
          AccessToken: `${tokens[1]}`,
        },
      })
      .then((response) => {
        return Promise.resolve(response.data);
      });
  } else if (params.inputs.items) {
    params = JSON.parse(JSON.stringify(operationInfo.params));
    params.inputs.items[1].tracks[0].src =
      operationInfo.params.inputs.items[1].tracks[0].src;
    const item = params.inputs.items.find((item, i) => item.tracks);
    if (item) {
      const index = params.inputs.items.indexOf(item);
      if (item.tracks.length) {
        return new Promise((r) => {
          const promises = item.tracks.map((track) => {
            if (typeof track.src !== "string") {
              if (
                operationInfo.trackInfo.customClass.split("-")[0] === "sfx" &&
                track.attachmentid
              ) {
                return new Promise((r) =>
                  r(
                    Koolioai.getSignedCompressed(
                      track.attachmentid,
                      params.inputs.genAi,
                    ),
                  ),
                );
              } else {
                return new Promise((r) =>
                  r(
                    Koolioai.getSignedClipboard(
                      track.id + "." + params.inputs.fileFormat,
                    ),
                  ),
                );
              }
            } else {
              return new Promise((r) => r(track.src));
            }
          });
          Promise.all(promises).then((urls) => {
            urls.map((url, i) => {
              item.tracks[i].src = url;
            });
            params.inputs.items[index] = item;
            showStatText("saving...", false);
            Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
              (tokens) => {
                return axios
                  .post(_config.api + "/operations", params, {
                    headers: {
                      Authorization: `${tokens[0]}`,
                      AccessToken: `${tokens[1]}`,
                    },
                  })
                  .then((response) => {
                    return r(response.data);
                  });
              },
            );
          });
        });
      }
    } else {
      showStatText("saving...", false);
      let tokens = await Promise.all([
        Koolioai.authToken(),
        Koolioai.accessToken(),
      ]);
      return axios
        .post(_config.api + "/operations", params, {
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          return Promise.resolve(response.data);
        });
    }
  } else {
    showStatText("saving...", false);
    let tokens = await Promise.all([
      Koolioai.authToken(),
      Koolioai.accessToken(),
    ]);
    return axios
      .post(_config.api + "/operations", params, {
        headers: {
          Authorization: `${tokens[0]}`,
          AccessToken: `${tokens[1]}`,
        },
      })
      .then((response) => {
        return Promise.resolve(response.data);
      });
  }
}

export function getAccessibleAttributes() {
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
    .then((tokens) =>
      axios.get(_config.api + "/get-accessible-attributes", {
        headers: {
          Authorization: `${tokens[0]}`,
          AccessToken: `${tokens[1]}`,
        },
      }),
    )
    .then((res) => res.data);
}

export function getUserPlan() {
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
    .then((tokens) =>
      axios.get(_config.api + "/get-user-plan", {
        headers: {
          Authorization: `${tokens[0]}`,
          AccessToken: `${tokens[1]}`,
        },
      }),
    )
    .then((res) => res.data);
}
export async function getOperationsList(refid = null, jobname) {
  // let jobname = store.getState().projectData.resultStat.jobname;
  let token = await Koolioai.authToken();
  return axios
    .get(_config.api + "/jobhead", {
      params: {
        querymethod: "LIST",
        jobname,
        refid,
      },
      headers: {
        Authorization: `${token}`,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
}

export async function applyOperationsInBackend(job, scheduler = false) {
  const token = await Koolioai.authToken();
  return axios
    .get(_config.api + "/apply-operations", {
      params: {
        jobname: job,
        scheduler,
      },
      headers: {
        Authorization: `${token}`,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
}

export async function getHistory(offset, limit = 20) {
  const jobname = store.getState().projectData.resultStat.jobname;
  const authToken = await Koolioai.authToken();
  return axios
    .get(_config.api + "/jobhead", {
      params: {
        jobname,
        querymethod: "LISTHISTORY",
        offset: offset,
        number_of_records: limit,
        count_records_required: true,
      },
      headers: {
        Authorization: `${authToken}`,
      },
    })
    .then((response) => Promise.resolve(response.data));
}

export async function restoreProjectHistory(fromRefid, toRefid, isPassiveOP) {
  const jobname = store.getState().projectData.resultStat.jobname;
  isPassiveOP = isPassiveOP ? true : false;
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) =>
      axios
        .get(_config.api + "/restore", {
          params: {
            jobname,
            fromRefid,
            toRefid,
            isPassiveOP,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          // should check for status code
          return getResultStatFromBackend(
            jobname,
            true,
            response.data.refid,
          ).then((res) => {
            response.data.resultStat = res.resultStat;
            return Promise.resolve(response.data);
          });
        }),
  );
}
