// /* globals closeHalfModal,
//    getGainFromVolumeSlider, getResultStat,
//    LoudnessMeter,
//    playlist, updateVolumeSlider, updateVolumeText
// */
// let volumeOnClickActiveTrackSelected = true
// let checkOperationTransactioncount = 0
// function destroySliders () {
//     try {
//         $('.speakers-sliders').slick('unslick')
//         $('.slider-speakers > a[data-slide]').off('click')

import axios from "axios";
import anime from "animejs";
import $ from "jquery";
import store from "../redux/Store";
import { Koolioai, _config } from "./cognitoAuth";
import { apiProviderBuzzsprout } from "./publish-api/ApiIntegrationAuth";
import { screenWidth, toastRes } from "./Res";
import {
  ACM,
  checkFileConversionStatus,
  create_UUID,
  editFeatureTitle,
  listofSharedUser,
  moreOptionsAlert,
  sendLog,
  showStatText,
  validateEmail,
} from "./utils";
// import { showMessage } from "./ShowMessage";
import {
  checkIsTheProjectShared,
  getLiveShareholders,
  getSharedUser,
  lockFeatureForOtherCollaborators,
  shareProject,
} from "../services/ShareAndCollabeServices";
import {
  getOpListFromIndexedDb,
  retrieveAudioFileByJobname,
} from "./indexedDButils";
import { applyOperations } from "../components/operations/frontendApplyOperations";
import {
  showplaylistloader,
  saveKooliospace,
} from "../components/pages/kooliospace/kooliospaceUtils";
import { frontendApplySliceActions } from "../redux/slice/FrontendApplySlice";
import { ShowProjectModalActions } from "../redux/slice/ShowProjectShareModal";
import { genAiActions } from "../redux/slice/GenAiWebsocketDataSlice";
import { Tooltip } from "bootstrap";
const supportedExportFormats = ["wav", "mp3", "m4a"];
//         $('.slider-speakers > a').off('click')
//     } catch (err) {
//         // console.log("slick may not have initialized!")
//     }
//     try {
//         $('.sfx-sliders').slick('unslick')
//         $('.slider-sfx > a').off('click')

//         $('.prev, .next').off('click')
//     } catch (err) {
//         // console.log("slick may not have initialized!")
//     }
// }

// function capitalize(string) {
//     return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
// }

// function closeLandingScreen() {
//     document.getElementById('landing-screen').style.display = 'none'
//     document.getElementById('upload-widget').style.display = 'block'
// }

// let state = false
// function addProjectKoolioSpace() {
//     hideNotificationWindow()
//     if (ACM.role === null) {
//         initiateACM().then(acm => {
//             if (ACM.checkAccExpiry() && ACM.planName !== 'Free' && ACM.planName !== 'admin') { //ACM.role === 'trial'
//                 /*if (ACM.getPlanID() !== '797906cf-69ba-3af3-b661-d7fbfdee6927') showExpiryExtensionModal()
//                 else showExpiredModal()*/
//                 showExpiredModal()
//                 return false
//             }
//             addProjectToKoolioSpace()
//         })
//             .catch(e => {
//                 addProjectToKoolioSpace()
//             })
//     } else {
//         if (ACM.checkAccExpiry() && ACM.planName !== 'Free' && ACM.planName !== 'admin') { //ACM.role === 'trial'
//             /*if (ACM.getPlanID() !== '797906cf-69ba-3af3-b661-d7fbfdee6927') showExpiryExtensionModal()
//             else showExpiredModal()*/
//             showExpiredModal()
//             return false
//         }
//         addProjectToKoolioSpace()
//     }
// }

// function addProjectToKoolioSpace() {
//     searchBarInvisible()
//     if (state === false) {
//         sendLog({
//             activity: 'click',
//             target: 'open add project',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'success',
//             statusMsg: 'success'
//         })
//         $("#add-btn").tooltip('dispose')
//         document.getElementById('add-btn').title = 'Return to projects'
//         $("#add-btn").tooltip('enable')
//         document.getElementById('koolio-project').style.display = 'none'
//         document.getElementById('addProject').style.display = 'block'
//         document.getElementById('add-project-user').innerText = 'Add Project'
//         //tour call for record and import
//         destroytour()
//         startTour('landing_page_add')
//         anime.timeline({ loop: false })
//             .add({
//                 targets: '.add-btn',
//                 rotate: 45,
//                 duration: 400,
//                 easing: 'easeOutExpo'
//             })
//         state = true
//     } else if (state === true) {
//         sendLog({
//             activity: 'click',
//             target: 'close add project',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'success',
//             statusMsg: 'success'
//         })
//         destroytour()
//         $("#add-btn").tooltip('dispose')
//         document.getElementById('add-btn').title = 'New project'
//         $("#add-btn").tooltip('enable')
//         document.getElementById('koolio-project').style.display = 'block'
//         document.getElementById('addProject').style.display = 'none'
//         closeOverlayModal()
//         document.getElementById('add-project-user').innerText = 'Welcome, ' + Koolioai.getDisplayName().charAt(0).toUpperCase() + Koolioai.getDisplayName().slice(1)
//         document.getElementById('user-profile').src = 'https://ui-avatars.com/api/?name=' + Koolioai.getDisplayName() + '&background=181818&color=fff&rounded=true&size=31'
//         document.getElementById('user-profile-home').src = 'https://ui-avatars.com/api/?name=' + Koolioai.getDisplayName() + '&background=181818&color=fff&rounded=true&size=31'
//         anime.timeline({ loop: false })
//             .add({
//                 targets: '.add-btn',
//                 rotate: 0,
//                 duration: 400,
//                 easing: 'easeOutExpo'
//             })
//         state = false
//     }
// }

// // Clearing UI elements
// function refreshUI() {
//     $('#control-mode').css('visibility', 'visible')
//     $('#record-control-mode').css('visibility', 'visible')
// }

// function clearUI() {
//     // Icons
//     document.getElementById('btn-play').classList.replace('btn-pause', 'btn-play')
//     playButtonToggler()

//     document.getElementById('btn-loop').classList.replace('loopActive', 'loopInactive')
//     $(loopCont).empty()
//     loopCont.appendChild(loopIcon)
//     loopIconSize = ((24.5 / 1440) * window.innerWidth) + 'px'
//     $('#btn-loop-icon').css({ width: loopIconSize })

//     // Control mode
//     document.getElementById('control-mode').style.visibility = 'visible'
//     document.getElementById('record-control-mode').style.visibility = 'visible'

//     // Sub menu
//     document.getElementById('sub-menus-section').style.visibility = 'hidden'
// }

// function clearRecordUI() {
//     // Icons
//     document.getElementById('record-btn-play').classList.replace('btn-pause', 'btn-play')
//     playButtonToggler()

//     // Control mode
//     document.getElementById('control-mode').style.visibility = 'visible'
//     document.getElementById('record-control-mode').style.visibility = 'visible'

//     // Sub menu
//     document.getElementById('sub-menus-section').style.visibility = 'hidden'
// }

// // Close workspaces
// async function closeKoolioWorkspace(isDiscardPressed = false) {
//     if(isTranscriptDrivenEdit) {
//         switchTranscriptDriven()
//         let spkSliderWidth = document.getElementById("speakers-sliders").clientWidth
//         let spkSlider = document.getElementById("speakers-sliders").parentElement.removeChild(document.getElementById("speakers-sliders"))
//         let sfxSlider = document.getElementById("sfx-slider-cont").parentElement.removeChild(document.getElementById("sfx-slider-cont"))
//         document.getElementById("add-spk-sfx").parentElement.insertBefore(spkSlider, document.getElementById("add-spk-sfx"))
//         $("#speakers-sliders").css({"top":""})
//         speakersSegmentHeight = isTranscriptDrivenEdit? 0 : ((waveHeight / 2) - (((((31 / screenWidth)) * window.innerWidth)) / 2))
//         $("#speakers-sliders").css({"top":speakersSegmentHeight, "width":"60%"})
//         document.getElementById("add-spk-sfx").parentElement.insertBefore(sfxSlider, document.getElementById("add-spk-sfx").nextSibling)

//         sfxSegmentHeight = ((waveHeight / 2) + addSpkHeight)
//         $("#sfx-slider-cont").css({"top":sfxSegmentHeight,"width":""})
//         $("#transcriptToggler").attr("src","static/img/workspace/controls/toggle-off-solid.svg")
//         closeTranscriptLoader()
//     }
//     document.getElementById("vol-ctrl-menu").style.visibility = 'hidden'
//     updateOnlineOfflineStatus(false)
//     sendLog({
//         activity: 'close active job',
//         target: 'close add project',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })
//     isCacheCleared = false
//     selectedSfxDomElement = undefined
//     clearFlags()
//     if (recordPlaylist.duration > 0 && (document.getElementById("progress-modals").style.visibility === "visible")) {
//         notAllowedToCloseUploadWindow()
//         return
//     }
//     if (document.getElementById("progress-modals").style.visibility === "visible") {
//         if (document.getElementById("progress-content").innerHTML === "Uploading Project") {
//             closeRecordWorkspace(false, true)
//             uploadProgressStatus.recordUploadProgress = parseFloat(document.getElementById('progress-percentage-set').style.width).toFixed(2)
//             destroyProgressBar()
//             destroyVanta()
//             return
//         }
//     }
//     destroyProgressBar()
//     if ((document.getElementById('koolio_record_workspace').style.display === 'block') && (recordPlaylist && recordPlaylist.duration > 0)) {
//         sendLog({
//             activity: 'click',
//             target: 'close koolio record workspace',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'success',
//             statusMsg: 'success'
//         })
//         closeRecordWorkspace(isDiscardPressed)
//         return
//     }

//     if ((document.getElementById('koolio_workspace').style.display === 'block') && (playlist && playlist.duration > 0)) {
//         try {
//             document.getElementById('status-disp').style.display = 'flex'
//             if (document.getElementById('koolio_workspace').style.display === 'block') {
//                 $('#track-controls').css('display', 'none')
//                 $('#cont').css('display','none')
//                 $('#koolio_workspace').css('visibility', 'hidden')
//                 $('#control-mode').css('visibility', 'hidden')
//                 $('#waveforms-sub-menu').css('display', 'none')
//                 $('#progress-modals').css('visibility', 'visible')
//                 constantProgress(10, 'Saving Your Workspace')
//             }
//             createVanta()
//             await checkOperationTransaction()
//         } catch (e) {
//             destroyProgressBar()
//             destroyVanta()
//             document.getElementById('status-disp').style.display = 'none'
//             if (document.getElementById('koolio_workspace').style.display === 'block') {
//                 $('#track-controls').css('display', '')
//                 $('#cont').css('display','block')
//                 $('#koolio_workspace').css('visibility', 'visible')
//                 $('#control-mode').css('visibility', 'visible')
//                 $('#progress-modals').css('visibility', 'hidden')
//             }
//             showStatText('Network Error...', false)
//         }
//     }
//     sendLog({
//         activity: 'click',
//         target: 'close koolio workspace',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })
//     if (playlist && playlist.tracks.length) ee.emit('stop')
//     if (recordPlaylist && recordPlaylist.tracks.length) ee.emit('stop')
//     if (playlist && playlist.tracks.length) {
//         let koolioJobs = await getKSFromLocal()
//         let job = koolioJobs.filter(job => job.jobname === playlist.jobname)[0]
//         job.duration = playlist.duration
//         job.updated_at = new Date()
//         await updateKooliospace(job)
//         await syncOperationsInBackend()
//         applyOperationsInBackend(job.jobname)
//         clearBackendApplyScheduler()
//         clearOperationCountHandlerScheduler()
//         clearSendOperationBackendScheduler()
//         await updateResultstatByJobname(playlist.jobname, playlist.jobhead)
//         await updateIndexDBoperationsData("jobname, frontend_apply_status, deleted", [playlist.jobname, "pending","false"], "frontend_apply_status", "completed")
//         await unlockFeatureForOtherCollaborators(editFeatureTitle)
//         isEditFeatureEnabled = false
//         editFeatureLockedBy = ''

//         websocketOperations = []
//         isWebsocketApplyOperationActive = false
//         isTheProjectShared = false

//         //console.log('cleared the scheduler')
//     }
//     sessionStorage.removeItem("aiShowNotes")
//     $('#workspace-loader').css('display', 'none')
//     clearWorkspace()
//     state = true
//     addProjectKoolioSpace()
//     clearUI()
//     clearRecordUI()
//     cacheManager.clearJobname()
//     transcriptionCompleted = false
//     enableHistoryOptions(false)
// }

// async function checkOperationTransaction() {
//     checkOperationTransactioncount++
//     if (pingPongConnectivityObj.speed > pingPongConnectivityObj.threshold) {
//         if (isOperationsTransActive || operationList.length > 0) {
//             showStatText('saving your operations...')
//             // eslint-disable-next-line no-unmodified-loop-condition
//             while (operationList.length || isOperationsTransActive) {
//                 if (pingPongConnectivityObj.speed < pingPongConnectivityObj.threshold) {
//                     // eslint-disable-next-line prefer-promise-reject-errors
//                     return Promise.reject('Network Error')
//                 }
//                 await timeout(200)
//             }
//         } else {
//             await sendOperation()
//             showStatText('saving your operations...')
//         }
//         checkOperationTransactioncount = 0
//         return Promise.resolve()
//     } else {
//         if(!operationList.length){
//             showStatText('synchronized')
//             checkOperationTransactioncount = 0
//             return Promise.resolve()
//         }
//         else if (checkOperationTransactioncount <=5){
//             showStatText('Slow internet. Trying to save your operations...')
//             await checkOperationTransaction()
//         }
//         // eslint-disable-next-line prefer-promise-reject-errors
//         //return Promise.reject('Network Error')
//         else {
//             checkOperationTransactioncount = 0
//             showStatText('Slow internet. Operations may not be saved...')
//             return Promise.resolve()
//         }
//     }
// }

// async function closeRecordWorkspace(isDiscardPressed = false, uploadRecordedProject = false) {
//     if (recordPlaylist.micstate === 'recording') $('#record-btn-mic').click()
//     let koolioJobs = await getKSFromLocal()
//     let job = koolioJobs.filter(job => job.jobname === jobname)[0]
//     job.duration = recordPlaylist.duration
//     job.updated_at = new Date()
//     await updateKooliospace(job)
//     if (isDiscardPressed) {
//         $('#confirm-delete-project h2').text('Delete current recording?')
//         $("#modal_5 > .line-height-opt > .action-project >.btn-delete").text("Delete")
//         $("#modal_5 > .line-height-opt > .action-project >.btn-delete").attr('onclick', "deleteJobViewFromRecording()") //closeRecordConfirmModal()
//         $("#modal_5 > .line-height-opt > .action-project >.btn-cancel").attr('onclick', "cancelDeleteJob(true)")
//     } else {
//         $('#confirm-delete-project h2').text("Close recording workspace?")
//         $("#modal_5 > .line-height-opt > .action-project >.btn-delete").text("Close")
//         $("#modal_5 > .line-height-opt > .action-project >.btn-delete").attr('onclick', "closeRecordConfirmModal()") //closeRecordConfirmModal()
//         if (uploadRecordedProject) $("#modal_5 > .line-height-opt > .action-project >.btn-cancel").attr('onclick', "cancelDeleteJob(false, true)")
//         else $("#modal_5 > .line-height-opt > .action-project >.btn-cancel").attr('onclick', "cancelDeleteJob()")
//     }
//     if (uploadRecordedProject) $("#modal_5 > .line-height-opt > .action-project >.btn-cancel").text("Cancel")
//     else $("#modal_5 > .line-height-opt > .action-project >.btn-cancel").text("Record")

//     document.getElementById("annotmodal").style.display = "block"
//     document.getElementById("modal_5").style.display = "block"
// }

// async function closeRecordWorkspaceBackButton() {
//     if (recordPlaylist.micstate === 'recording') $('#record-btn-mic').click()
//     let koolioJobs = await getKSFromLocal()
//     let job = koolioJobs.filter(job => job.jobname === jobname)[0]
//     job.duration = recordPlaylist.duration
//     job.updated_at = new Date()
//     await updateKooliospace(job)
//     showBackButtonModal()
//     document.getElementById("speakers-0-slider").classList.add("slick-current", "slick-active")
// }

// // Display the alert dialog/modal following the condition "operationList.length < 0 && isOperationsTransActive == true"
// function closeKoolioWorkspaceModal() {
//     $('#half-modals').css('visibility', 'visible')
//     $('#half-modals').html(confirmCloseWorkspaceModal)
//     koolioModals()
// }

// async function initiateRecordUploadProgress (){
//     let key = encodeURIComponent('loaded')
//     let value = encodeURIComponent('true')
//     let url = new URL(document.location)
//     url.searchParams.delete('jobname')
//     url.searchParams.set(key, value)
//     window.history.pushState({ page: 'kooliospace', title: 'koolio.ai' }, 'koolio.ai', url)
//     document.getElementById('annotmodal').style.display = 'none'
//     document.getElementById('modal_5').style.display = 'none'
//     destroySliders()
//     destroyProgressBar()
//     destroyVanta()
//     document.getElementById('site-header').style.display = 'none'
//     document.getElementById('site-header-home').style.display = 'block'
//     document.getElementById('transcript-text').style.display = 'none'
//     document.getElementById('transcript').style.display = 'none'
//     document.getElementById('more-options-menu').style.display = 'none'
//     document.getElementById('status-disp').style.display = 'none'
//     document.getElementById('koolio_workspace').style.display = 'none'
//     document.getElementById('koolio_record_workspace').style.display = 'none'
//     if (recordPlaylist && recordPlaylist.tracks.length) ee.emit('stop')
//     OPStack = []
//     flags = []
//     resetFades()
//     cacheManager.clearJobname()
//     addProjectKoolioSpace()
//     clearAnnotWorkspace()
//     updateOperationSpace()
//     viewControls(false)
//     //let uploadProgressStatus = {
//     //    recordUploadProgress : 0,
//     //    uploadedProjectList : [],
//     //    internetSpeed : 1,
//     //    timeout : 30000
//     //}
//     //const uploadProject = {
//     //    progress : 0,
//     //    status: "Uploading"
//     //}
//     //uploadProgressStatus.uploadedProjectList.push(uploadProject)
//     displayUploadWidget()
//     //$('#record-animation').css({ visibility: 'visible', opacity: 1 })
//     //$('#record-playlist').css({ visibility: 'hidden' })
//     //$('#spk-track-controls').css({ visibility: 'hidden' })
//     //$('#record-global-view').css({ visibility: 'hidden' })
//     //clearUI()
//     //clearRecordUI()
//     //cacheManager.clearJobname()
//     //transcriptionCompleted = false
//     //enableHistoryOptions(false)
//     //hiderecordplaylistloader()

// }

// async function closeRecordConfirmModal() {
//     if (document.getElementById("half-modals").style.visibility === "visible") {
//         window.removeEventListener('popstate', closeRecordWorkspaceBackButton, true)
//         recordPlaylist.micstate = 'stopped'
//         closeHalfModal()
//     } else {
//         window.removeEventListener('popstate', closeRecordWorkspaceBackButton, true)
//         recordPlaylist.micstate = 'stopped'
//     }
//     showrecordplaylistloader()
//     document.getElementById('annotmodal').style.display = 'none'
//     document.getElementById('modal_5').style.display = 'none'
//     if (recordPlaylist && recordPlaylist.tracks.length) ee.emit('stop')
//     clearWorkspace()
//     recordPlaylist.clear()
//     clearRecordingSession(true)
//     $('#record-animation').css({ visibility: 'visible', opacity: 1 })
//     $('#record-playlist').css({ visibility: 'hidden' })
//     $('#spk-track-controls').css({ visibility: 'hidden' })
//     $('#record-global-view').css({ visibility: 'hidden' })
//     try {
//         document.getElementById('record-duration').innerHTML = cueFormatters(format)(0)
//         closeSockets()
//     } catch (err) {
//         // console.error(err)
//     }
//     state = true
//     addProjectKoolioSpace()
//     clearUI()
//     clearRecordUI()
//     cacheManager.clearJobname()
//     transcriptionCompleted = false
//     enableHistoryOptions(false)
//     hiderecordplaylistloader()
// }

// function closeKoolioWorkspaceConfirmModal() {
//     operationList = []
//     closeHalfModal()
//     if (playlist && playlist.tracks.length) ee.emit('stop')
//     clearWorkspace()
//     state = true
//     addProjectKoolioSpace()
//     clearUI()
//     cacheManager.clearJobname()
//     transcriptionCompleted = false
//     enableHistoryOptions(false)
// }

// // Control modes

// function viewControls(isUserInitiated = false, autoRestore = false) {
//     if ($('#control-opt-preview').text() === 'Accept' || autoRestore) {
//         changeFlagsPlaylist()
//         showplaylistloader()
//         restoreProjectHistory(currentJobHead, playlist.jobhead, false)
//             .then(res => {
//                 //console.log("restoreProjectHistory accept restore ", res)
//                 if (res.status === 200) {
//                     showStatText('workspace restored')
//                     enableHistoryOptions(false)
//                     destroySliders()
//                     ee.emit('showMoreOptions')
//                     playlist.setJobHead(res.jobhead)
//                     playlist.setMode(document.getElementById('edit-control-options').classList.contains('enabled-ctrl') ? 'edit' : 'view')
//                     updateSlickSliders()
//                     restoreOperationsInIndexedDB(restoredRefid, "deleted", "true")
//                     updateResultstatByJobname(playlist.jobname, res.jobhead)
//                     return Promise.resolve(hideplaylistloader())
//                 } else if (res.status === 201) {
//                     return updateWorkspaceWithOrigin()
//                         .then(() => {
//                             showStatText('restore aborted, origin ahead of local', false)
//                             hideplaylistloader()
//                         })
//                 } else if (res.status === 404) {
//                     showStatText('conflict')
//                     hideplaylistloader()
//                 }
//                 return Promise.resolve()
//             })
//             .catch((_err) => {
//                 // console.error(_err)
//                 hideplaylistloader()
//             })
//         return
//     }
//     if (isOperationsTransActive || operationList.length > 0) {
//         showMessage('View mode is disabled', 'Saving your operations, please wait...', 8000, 'success', 'workspace')
//         return
//     }
//     playlist.mode = 'view'
//     let shouldUnlock = false
//     if (isUserInitiated) {
//         shouldUnlock = true
//         sendLog({
//             activity: 'click',
//             target: 'view button click',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'success',
//             statusMsg: 'success'
//         })
//     }
//     if(!isTheProjectShared) {
//         processingUIForViewOption()
//         return
//     }
//     if(shouldUnlock || autoRestore){
//         showStatText('enabling view mode, please wait...')
//         showplaylistloader()
//         unlockFeatureForOtherCollaborators(editFeatureTitle)
//             .then(() => {
//                 processingUIForViewOption()
//             })
//     }
// }

// function processingUIForViewOption(){
//     const featureLockedByIcon = document.getElementById(editFeatureLockedBy + '-circle')
//     if(featureLockedByIcon){
//         featureLockedByIcon.style.backgroundColor = '#E2522B'
//         featureLockedByIcon.src = 'https://ui-avatars.com/api/?name=' + editFeatureLockedBy + '&background=181818&color=fff&rounded=true&size=28'
//     }

//     // Disabled icons
//     document.getElementById('add-spk-sfx-tracks').style.cssText = 'opacity: 0.4'
//     //document.getElementById('add-spk-sfx-tracks-transcript').style.cssText = 'opacity: 0.4'
//     document.getElementById('menu-vol-ctrl-spk').style.cssText = 'opacity: 0.4'
//     document.getElementById('menu-vol-ctrl-sfx').style.cssText = 'opacity: 0.4'
//     document.getElementById('projectname').title = ''
//     document.getElementById('undo').style.opacity = '0.2'
//     document.getElementById('redo').style.opacity = '0.2'

//     // remove tooltip in view mode
//     $('#projectname').tooltip('dispose')

//     // Paste sub-menu
//     document.getElementById('insert-sub-menu').style.display = 'none'
//     if (document.getElementById('insert-sub-menu').style.display === 'none') {
//         $(insertCont).empty()
//         insertCont.appendChild(insertIcon)
//         insertIconSize = ((23 / 1440) * window.innerWidth) + 'px'
//         $('#btn-insert-icon').css({ width: insertIconSize })
//         document.getElementById('btn-insert').classList.replace('insert-menu-open', 'insert-menu-closed')
//     }

//     // Insert sub-menu
//     document.getElementById('insert-alt-sub-menu').style.display = 'none'
//     if (document.getElementById('insert-alt-sub-menu').style.display === 'none') {
//         $(insertAltCont).empty()
//         insertAltCont.appendChild(insertAltIcon)
//         insertAltIconSize = ((20.3 / 1440) * window.innerWidth) + 'px'
//         $('#btn-insert-alt-icon').css({ width: insertAltIconSize })
//         document.getElementById('btn-insert-alt').classList.replace('insert-alt-menu-open', 'insert-alt-menu-closed')
//     }

//     // Fades sub-menu
//     document.getElementById('waveforms-sub-menu').style.display = 'none'
//     if (document.getElementById('waveforms-sub-menu').style.display === 'none') {
//         document.getElementById('btn-waveform-selector').classList.replace('waveform-enable', 'waveform-disable')
//         $(fadeCont).empty()
//         fadeCont.appendChild(fadeIcon)
//         waveformsSelector = document.getElementById('waveforms-selector')
//         waveformsSelector.style.width = ((((23 / screenWidth) * 100) * window.innerWidth) / 100) + 'px'
//         waveformsSelector.style.height = ((((21.5 / screenWidth) * 100) * window.innerWidth) / 100) + 'px'
//         resetFades()
//     }

//     // Change speakers sub-menu
//     document.getElementById('change-speakers').style.display = 'none'
//     if (document.getElementById('change-speakers').style.display === 'none') {
//         document.getElementById('speakers-change').classList.replace('speakers-change-enabled', 'speakers-change-disabled')
//         $(changeSpeakerCont).empty()
//         changeSpeakerCont.appendChild(changeSpeakerIcon)
//         changeSpeakerIconSize = ((24 / 1440) * window.innerWidth) + 'px'
//         $('#btn-spk-icon').css({ width: changeSpeakerIconSize })
//     }

//     $(fadeCont).empty()
//     fadeCont.appendChild(fadeIcon)
//     waveformsSelector = document.getElementById('waveforms-selector')
//     waveformsSelector.style.width = ((((23 / screenWidth) * 100) * window.innerWidth) / 100) + 'px'
//     waveformsSelector.style.height = ((((21.5 / screenWidth) * 100) * window.innerWidth) / 100) + 'px'

//     resetVolumeCtrlMenu()
//     clearMultiSelect()
//     document.getElementById('btn-annotations').classList.replace('enable', 'disable')
//     document.getElementById('btn-annot-icon').src = 'static/img/workspace/controls/annotations.png'

//     let edit = document.getElementById('edit-control-options')
//     let editAlt = document.getElementById('edit-control-options-alt')
//     let preview = document.getElementById('preview-control-options')
//     let recordPreview = document.getElementById('record-preview-control-options')

//     edit.classList.replace('enabled-ctrl', 'disabled-ctrl')
//     editAlt.classList.replace('enabled-ctrl', 'disabled-ctrl')
//     preview.classList.replace('disabled-ctrl', 'enabled-ctrl')

//     if (preview.classList.contains('enabled-ctrl')) {
//         edit.style.display = 'none'
//         editAlt.style.display = 'none'
//         preview.style.display = 'flex'
//         recordPreview.style.display = 'flex'
//         // btn-annotations
//         document.getElementById('btn-annotations').style.display = 'none'
//         // btn-annotations
//         document.getElementById('control-opt-preview').style.color = '#8A2BE2'
//         document.getElementById('control-opt-edit').style.color = '#494949'
//     }
//     //2977 preventing project title edit in view mode
//     let titleName= document.querySelector('.header-project-name')
//     // hover over title name to show edit copy options
//     titleName.addEventListener('mouseover',()=>{

//         document.getElementById('projectTitle-edit-copy').style.display='none'

//     })
//     //2977 ends here
//     hideplaylistloader()
// }

// async function editControls(header = '', subHeader = '') {
//     if ($('#control-opt-edit').text() === 'Reject') {
//         previousFlagsPlaylist()
//         destroySliders()
//         document.getElementById('transcript').style.visibility='hidden'
//         document.getElementById('track-controls').style.visibility='hidden'
//         $('#koolio_workspace').css('visibility', 'hidden')
//         $('#control-mode').css('visibility', 'hidden')
//         ee.emit('showMoreOptions')
//         document.getElementById('status-disp').style.display = 'flex'
//         createVanta()
//         constantProgress(10, 'Preparing Your Workspace')
//         document.getElementById('progress-detail').innerHTML = 'Please wait...'
//         showplaylistloader()
//         updateWorkspaceWithOrigin(playlist.jobhead)
//             .then(() => {
//                 updateSlickSliders()
//                 document.getElementById('track-controls').style.visibility='visible'
//                 document.getElementById('transcript').style.visibility='visible'
//                 if(isTranscriptDrivenEdit){
//                     switch1()
//                 }
//                 $('#koolio_workspace').css('visibility', 'visible')
//                 $('#control-mode').css('visibility', 'visible')
//                 document.getElementById('status-disp').style.display = 'flex'
//                 message = ''
//                 $('#status-disp  span.status').text(message)
//                 destroyProgressBar()
//                 destroyVanta()
//                 showStatText('loaded workspace')
//                 hideplaylistloader()
//                 enableHistoryOptions(false)
//             })
//         return
//     }
//     if (!await checkAccess()) {
//         sendLog({
//             activity: 'click',
//             target: 'edit button click',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'failed',
//             statusMsg: 'do not have permission to edit'
//         })
//         showStatText('you do not have permission to edit')
//         return false
//     }
//     if (!playlist.jobhead || transcriptionCompleted === false) {
//         let transcriptionProgress
//         if(!transcriptionCompleted && playlist.jobhead) {
//             showplaylistloader()
//             $('#half-modals').html(inProgressModal)
//             koolioModals()
//             // call once in advance
//             transcriptionProgress = await transcriptionProgressLoader(playlist.jobname, 'workspace')

//             if(transcriptionProgress){
//                 transcriptionCompleted = transcriptionProgress
//             }
//             else{
//                 // call repetedly after set interval
//                 const interval = setInterval(async () => transcriptionProgressLoader(playlist.jobname, 'workspace', interval), 5000)
//             }
//             hideplaylistloader()
//         }
//         else {
//             showMessage(header, subHeader, 8000, 'error', 'workspace')
//         }
//         if(!transcriptionProgress || !isOperationSyncedToIndexDB){
//             sendLog({
//                 activity: 'click',
//                 target: 'play button click',
//                 timeStamp: new Date().toISOString(),
//                 jobname,
//                 status: 'failed',
//                 statusMsg: "Transcription in progress. edit mode restricted"
//             })
//             showStatText('edit mode restricted')
//             return false
//         }
//     }
//     //initiateSendOperationBackendScheduler()
//     if(!isOperationSyncedToIndexDB){
//         sendLog({
//             activity: 'click',
//             target: 'play button click',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'failed',
//             statusMsg: "Synchronization in progress. edit mode restricted"
//         })
//         showStatText('edit mode restricted')
//         return false
//     }
//     if(!isTheProjectShared){
//         processingUIForEditOption()
//         return
//     }
//     if(!isEditFeatureEnabled){
//         if (editFeatureLockedBy.startsWith('google_')){
//             const url = _config.api + '/get-email'
//             const params = {
//                 username: editFeatureLockedBy
//             }
//             const response = await axios.get(url, {
//                 params,
//                 headers: {
//                     'Content-Type': 'application/json',
//                 }
//             })

//             if (response.status === 200) {
//                 editFeatureLockedBy = response.data.email.split('@')[0]
//             }
//         }
//         const message = 'Another collaborator, ' + editFeatureLockedBy + ', is currently working on the project. Please wait or request access from them to edit'
//         showMessage('Edit mode disabled', message, 8000, 'error', 'workspace')
//         sendLog({
//             activity: 'click',
//             target: 'play button click',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'failed',
//             statusMsg: 'Transcription in progress. edit mode restricted'
//         })
//         showStatText('edit mode restricted')
//         checkTimeoutOfFeatureLockData(editFeatureTitle)
//         return false
//     }

//     if (document.getElementById('control-opt-edit').style.color === 'rgb(226, 82, 43)') return false
//     showStatText('enabling edit mode, please wait...')
//     showplaylistloader()
//     lockFeatureForOtherCollaborators(editFeatureTitle)
//         .then(()=> {
//             processingUIForEditOption()
//         })
// }

// function processingUIForEditOption(){
//     const featureLockedByIcon = document.getElementById(editFeatureLockedBy + '-circle')
//     if(featureLockedByIcon){
//         featureLockedByIcon.style.backgroundColor = '#E2522B'
//         featureLockedByIcon.src = 'https://ui-avatars.com/api/?name=' + editFeatureLockedBy + '&background=E2522B&color=fff&rounded=true&size=28'
//     }

//     sendLog({
//         activity: 'click',
//         target: 'edit button click',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })
//     playlist.mode = 'edit'
//     //this is call for workspace tour
//     destroytour()
//     startTour('workspace_edit')
//     // Enabled icons
//     document.getElementById('add-spk-sfx-tracks').style.cssText = 'opacity: 1; cursor:pointer'
//     document.getElementById('menu-vol-ctrl-spk').style.cssText = 'opacity: 1; cursor:pointer'
//     document.getElementById('menu-vol-ctrl-sfx').style.cssText = 'opacity: 1; cursor:pointer'
//     updateOperationSpace()
//     const projectName = document.getElementById('projectname')
//     // projectName.title = 'double click to edit'

//     // need to create/update tooltip
//     new bootstrap.Tooltip(projectName)

//     // Paste sub-menu
//     document.getElementById('insert-sub-menu').style.display = 'none'
//     if (document.getElementById('insert-sub-menu').style.display === 'none') {
//         $(insertCont).empty()
//         insertCont.appendChild(insertIcon)
//         insertIconSize = ((23 / 1440) * window.innerWidth) + 'px'
//         $('#btn-insert-icon').css({ width: insertIconSize })
//         document.getElementById('btn-insert').classList.replace('insert-menu-open', 'insert-menu-closed')
//     }

//     // Insert sub-menu
//     document.getElementById('insert-alt-sub-menu').style.display = 'none'
//     if (document.getElementById('insert-alt-sub-menu').style.display === 'none') {
//         $(insertAltCont).empty()
//         insertAltCont.appendChild(insertAltIcon)
//         insertAltIconSize = ((20.3 / 1440) * window.innerWidth) + 'px'
//         $('#btn-insert-alt-icon').css({ width: insertAltIconSize })
//         document.getElementById('btn-insert-alt').classList.replace('insert-alt-menu-open', 'insert-alt-menu-closed')
//     }

//     // Fades sub-menu
//     document.getElementById('waveforms-sub-menu').style.display = 'none'
//     if (document.getElementById('waveforms-sub-menu').style.display === 'none') {
//         document.getElementById('btn-waveform-selector').classList.replace('waveform-enable', 'waveform-disable')
//         $(fadeCont).empty()
//         fadeCont.appendChild(fadeIcon)
//         waveformsSelector = document.getElementById('waveforms-selector')
//         waveformsSelector.style.width = ((((23 / screenWidth) * 100) * window.innerWidth) / 100) + 'px'
//         waveformsSelector.style.height = ((((21.5 / screenWidth) * 100) * window.innerWidth) / 100) + 'px'
//         resetFades()
//     }

//     // Change speakers sub-menu
//     document.getElementById('change-speakers').style.display = 'none'
//     if (document.getElementById('change-speakers').style.display === 'none') {
//         document.getElementById('speakers-change').classList.replace('speakers-change-enabled', 'speakers-change-disabled')
//         $(changeSpeakerCont).empty()
//         changeSpeakerCont.appendChild(changeSpeakerIcon)
//         changeSpeakerIconSize = ((24 / 1440) * window.innerWidth) + 'px'
//         $('#btn-spk-icon').css({ width: changeSpeakerIconSize })
//     }

//     // Record sub menu
//     document.getElementById('record-sub-menu').style.display = 'none'
//     if (document.getElementById('record-sub-menu').style.display === 'none') {
//         document.getElementById('btn-record').classList.replace('record-enabled', 'record-disabled')
//         $(recordCont).empty()
//         recordCont.appendChild(recordIcon)
//         recordIconSize = ((20 / 1440) * window.innerWidth) + 'px'
//         $('#btn-record-icon').css({ width: recordIconSize })
//     }

//     $(fadeCont).empty()
//     fadeCont.appendChild(fadeIcon)
//     waveformsSelector = document.getElementById('waveforms-selector')
//     waveformsSelector.style.width = ((((23 / screenWidth) * 100) * window.innerWidth) / 100) + 'px'
//     waveformsSelector.style.height = ((((21.5 / screenWidth) * 100) * window.innerWidth) / 100) + 'px'

//     resetVolumeCtrlMenu()

//     let edit = document.getElementById('edit-control-options')
//     let editAlt = document.getElementById('edit-control-options-alt')

//     edit.classList.replace('disabled-ctrl', 'enabled-ctrl')
//     editAlt.classList.replace('disabled-ctrl', 'enabled-ctrl')

//     if (edit.classList.contains('enabled-ctrl')) {
//         edit.style.display = 'flex'
//         editAlt.style.display = 'flex'
//         // btn-annotations
//         document.getElementById('btn-annotations').style.display = 'block'
//         // btn-annotations

//         document.getElementById('control-opt-edit').style.color = '#E2522B'
//         document.getElementById('control-opt-preview').style.color = '#494949'
//     }
//     // 2977 starts here
//     let titleName= document.querySelector('.header-project-name')
//     // hover over title name to show edit copy options
//     titleName.addEventListener('mouseover',()=>{

//         document.getElementById('projectTitle-edit-copy').style.display='inline'

//     })
//     titleName.addEventListener('mouseleave',()=>{
//         document.getElementById('projectTitle-edit-copy').style.display='none'
//     })

//     let projectEditBtn = document.getElementById('projectTitle-edit')
//     //edit button implementation in project title
//     projectEditBtn.addEventListener('click',(event)=>{
//         event.preventDefault()
//         projectnameDblClick(event)

//     })
//     let projectCopyBtn = document.getElementById('projectTitle-copy')
//     //copy button implementation in project title
//     projectCopyBtn.addEventListener('click',()=>{
//         let projectContent = document.getElementById('projectname').innerText
//         navigator.clipboard.writeText(projectContent)
//         showMessage("Text copied.","",2000,"success","workspace")
//     })
//     //2977 ends here
//     hideplaylistloader()
// }

// // #2842 api if progress bar is stuck on 99%, if true we will get a new websocket message
// async function getTranscriptionStatusFromSocket(uploadedProject){
//     if(uploadedProject && !uploadedProject.checkTranscriptionThroughAPIcount){
//         uploadedProject.checkTranscriptionThroughAPIcount++
//         uploadProgressStatus.uploadedProjectList.map(project => {
//             if(project.id === jobname){
//                 project.checkTranscriptionThroughAPIcount = uploadedProject.checkTranscriptionThroughAPIcount
//             }
//         })
//         let istranscribed = await isTranscriptionCompleted(uploadedProject.id)
//         if (istranscribed) {
//             uploadedProject.progress = "100"
//         }
//         else {
//             uploadedProject.checkTranscriptionThroughAPIcount = 0
//             uploadProgressStatus.uploadedProjectList.map(project => {
//                 if(project.id === jobname){
//                     project.checkTranscriptionThroughAPIcount = 0
//                 }
//             })
//         }
//     }

// }
// // #2842 end

// function showProgressETA(progressETA, timeRemaining){
//     if(progressETA) {
//         if(timeRemaining <= 60 * 1000) {
//             // it is taking more than our expectation or literally few seconds remaining
//             progressETA.textContent = 'about a minute'
//         }
//         else if(timeRemaining >= 60*60*1000) {
//             // gonna take more than an hour
//             progressETA.textContent = 'more than an hour'
//         }
//         else {
//             // few minutes remaining
//             progressETA.textContent = Math.ceil(timeRemaining / (60*1000)) + ' minutes'
//         }
//     }
// }

// async function transcriptionProgressLoaderNewArch(jobname, displayOn, interval){
//     if(!jobname || !displayOn) {
//         closeHalfModal()
//         return
//     }
//     const progressBar = (displayOn === 'workspace') ? document.getElementById('progress-percentage-set') : document.getElementById(jobname + "_innerProgress")

//     //const progressBarText = (displayOn === 'workspace') ? document.querySelector('.progress p > span') : document.getElementById(jobname + "_innerProgressText")     //commented due to #2405

//     const progressBarText = (displayOn === 'workspace') ? document.getElementById('transcribe-percentage-text') : document.getElementById(jobname + "_innerProgressText")   //implementation #2405

//     const progressETA = (displayOn === 'workspace') ? document.querySelector('.in-progress-modal .half-modals-sub-header span') : undefined

//     if(displayOn === 'workspace' && !progressBar) {
//         closeHalfModal()
//         return
//     }

//     if(!progressBar || !progressBarText)
//         return

//     if(displayOn === 'workspace')
//         $('#half-modals').css('visibility', 'visible')

//     if(displayOn === 'kooliospace') {
//         const infoText = document.querySelector("[id='"+jobname+"_progressBarOuter'].progress p").lastChild
//         if(infoText.textContent !== '% transcribed')
//             infoText.textContent = '% transcribed'
//     }

//     //starting of showing transcription progress
//     getKooliospace()
//         .then(kooliospace => {
//             kooliospace.forEach(kooliojob => {
//                 if(kooliojob.jobname === jobname) {
//                     const uploadedProject = uploadProgressStatus.uploadedProjectList.find(project => project.id === jobname)
//                     const totalTranscriptionTime = kooliojob.duration * 1000 / 3 + 60 * 1000
//                     let timeRemaining = totalTranscriptionTime
//                     let timeRemainingPercent = 100 - Math.ceil(timeRemaining * 100 / totalTranscriptionTime)

//                     showProgressETA(progressETA, timeRemaining)  //to show estimated time in modal for recordings

//                     progressBar.style.width = 1 + '%'
//                     progressBarText.textContent = 1
//                     if(uploadedProject) {
//                         uploadedProject.progress = 1
//                     }
//                     interval = setInterval(() => {
//                         getKooliospace()
//                             .then((kooliospace)=>{
//                                 const kooliojob = kooliospace.find(job => job.jobname === jobname)
//                                 if(kooliojob.status === "COMPLETED"){
//                                     // already got transcription completion socket response
//                                     return clearInterval(interval)
//                                 }
//                                 const progressBar = (displayOn === 'workspace') ? document.getElementById('progress-percentage-set') : document.getElementById(jobname + "_innerProgress")
//                                 const progressBarText = (displayOn === 'workspace') ? document.getElementById('transcribe-percentage-text') : document.getElementById(jobname + "_innerProgressText")

//                                 timeRemaining = timeRemaining - 5000
//                                 timeRemainingPercent = 100 - Math.ceil(timeRemaining * 100 / totalTranscriptionTime)

//                                 showProgressETA(progressETA, timeRemaining) //to show estimated time in modal for recordings

//                                 if(timeRemainingPercent >= 99) {
//                                     //if time exceeded our expectation
//                                     progressBar.style.width = "99%"
//                                     progressBarText.textContent = "99"
//                                     if (uploadedProject) {
//                                         uploadedProject.progress = "99"
//                                     }
//                                     setTimeout(() => {
//                                         //check if transcription stuck in 99%
//                                         const progress = document.getElementById(uploadedProject.id + "_progress")
//                                         if(progress)
//                                             getTranscriptionStatusFromSocket(uploadedProject)
//                                     }, 5000)
//                                 }
//                                 else {
//                                     //progress increment
//                                     progressBar.style.width = timeRemainingPercent + '%'
//                                     progressBarText.textContent = timeRemainingPercent
//                                     if(uploadedProject) {
//                                         uploadedProject.progress = timeRemainingPercent
//                                     }
//                                 }
//                             })
//                     }, 5000)
//                 }
//             })
//         })

// }

// async function transcriptionProgressLoader(jobname, displayOn, interval) {
//     if(!jobname || !displayOn) {
//         clearInterval(interval)
//         closeHalfModal()
//         return
//     }
//     let resultStat
//     try {
//         //resultStat = await getResultStat(jobname,true)
//         resultStat = await getResultStatStatusForTranscription(jobname)

//     }
//     catch {

//         //if !resultStat then clean up will happen below
//     }
//     const progressBar = (displayOn === 'workspace') ? document.getElementById('progress-percentage-set') : document.getElementById(jobname + "_innerProgress")

//     //const progressBarText = (displayOn === 'workspace') ? document.querySelector('.progress p > span') : document.getElementById(jobname + "_innerProgressText")     //commented due to #2405

//     const progressBarText = (displayOn === 'workspace') ? document.getElementById('transcribe-percentage-text') : document.getElementById(jobname + "_innerProgressText")   //implementation #2405

//     const progressETA = (displayOn === 'workspace') ? document.querySelector('.in-progress-modal .half-modals-sub-header span') : undefined
//     if(!resultStat.resultStat || (!resultStat.resultStat.status_message && resultStat.message !==
//         "Transcription not completed" )|| (!resultStat.resultStat.status_message?.startsWith('Transcription') && resultStat.message !==
//         "Transcription not completed") ) {
//         const progress = document.getElementById(jobname + "_progress")
//         const duration = document.getElementById(jobname + "_projectDuration")
//         clearInterval(interval)
//         //await updateTranscriptionIndexDB(jobname)
//         closeHalfModal()
//         if(displayOn === 'kooliospace') {
//             // if(progress)
//             //     progress.remove()
//             if(duration)
//                 duration.style.visibility = 'visible'
//         }

//         return resultStat?.transcription_completed
//     }

//     if(displayOn === 'workspace' && !progressBar) {
//         clearInterval(interval)
//         closeHalfModal()
//         return
//     }

//     if(!progressBar || !progressBarText){
//         clearInterval(interval)
//         //closeHalfModal()
//         return
//     }

//     if(displayOn === 'workspace')
//         $('#half-modals').css('visibility', 'visible')
//     if(displayOn === 'kooliospace') {
//         const infoText = document.querySelector("[id='"+jobname+"_progressBarOuter'].progress p").lastChild
//         if(infoText.textContent !== '% transcribed')
//             infoText.textContent = '% transcribed'
//     }
//     const ta = resultStat.resultStat.start_time.split('_')
//     // adding 12hours in else part
//     const startTime = ta[2].toUpperCase() == 'AM' ?
//         new Date(ta[0].split('-').reverse().join('-') + 'T' + ta[1].split('-').join(':') + 'Z').getTime() :
//         new Date(ta[0].split('-').reverse().join('-') + 'T' + ta[1].split('-').join(':') + 'Z').getTime() + 12*60*60*1000
//     // divide by 3 is our approximation
//     // + 60sec for small files having higher overhead
//     const totalTranscriptionTime = resultStat.resultStat.duration * 1000 / 3 + 60 * 1000
//     const endTime = startTime + totalTranscriptionTime
//     const timeRemaining = endTime - Date.now()
//     const timeRemainingPercent = 100 - Math.ceil(timeRemaining * 100 / totalTranscriptionTime)
//     if(progressETA) {
//         if(timeRemaining <= 60 * 1000) {
//             // it is taking more than our expectation or literally few seconds remaining
//             progressETA.textContent = 'about a minute'
//         }
//         else if(timeRemaining >= 60*60*1000) {
//             // gonna take more than an hour
//             progressETA.textContent = 'more than an hour'
//         }
//         else {
//             // few minutes remaining
//             progressETA.textContent = Math.ceil(timeRemaining / (60*1000)) + ' minutes'
//         }
//     }
//     const uploadedProject = uploadProgressStatus.uploadedProjectList.find(project => project.id === jobname)
//     if(timeRemainingPercent <= 0) {
//         // somehow negative percent...
//         progressBar.style.width = '2%'
//         progressBarText.textContent = '2'
//         if(uploadedProject) {
//             uploadedProject.progress = '2'
//         }
//     }
//     else if(timeRemainingPercent >= 99) {
//         // time exceeded our expectation

//         progressBar.style.width = "99%"
//         progressBarText.textContent = "99"
//         if (uploadedProject) {
//             uploadedProject.progress = "99"
//         }
//         // #2842 api if progress bar is stuck on 99%, if true we will get a new websocket message
//         if(uploadedProject && !uploadedProject.checkTranscriptionThroughAPIcount){
//             uploadedProject.checkTranscriptionThroughAPIcount++
//             uploadProgressStatus.uploadedProjectList.map(project => {
//                 if(project.id === jobname){
//                     project.checkTranscriptionThroughAPIcount = uploadedProject.checkTranscriptionThroughAPIcount
//                 }
//             })
//             let istranscribed = await isTranscriptionCompleted(uploadedProject.id)
//             if (istranscribed) {
//                 uploadedProject.progress = "100"
//             }
//             else {
//                 uploadedProject.checkTranscriptionThroughAPIcount = 0
//                 uploadProgressStatus.uploadedProjectList.map(project => {
//                     if(project.id === jobname){
//                         project.checkTranscriptionThroughAPIcount = 0
//                     }
//                 })
//             }
//         }
//         // #2842 end
//     }
//     else {
//         progressBar.style.width = timeRemainingPercent + '%'
//         progressBarText.textContent = timeRemainingPercent
//         if(uploadedProject) {
//             uploadedProject.progress = timeRemainingPercent
//         }
//     }
// }

// // new function to check transcription completed manually

// async function isTranscriptionCompleted(jobname) {
//     return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
//         .then((tokens) =>
//             axios.get(_config.api + "/transcription-completed", {
//                 params: {
//                     jobname: jobname,
//                     username: Koolioai.username
//                 },
//                 headers: {
//                     Authorization: `${tokens[0]}`,
//                     AccessToken: `${tokens[1]}`,
//                 },
//             })
//         )
//         .then((response) => {
//             return response.data.transcription_completed
//         })
//         .catch((error) => {
//             // console.log(error)
//         })
// }

// // Volume controls
// var knobCreated = false

// function initVolCtrl (track, subVolumeData, subPanningData) {
//     let slider = $('#volume-slider')
//     slider.data('customClass', track.customClass)
//     $knob.knob('value', parseInt(track.panning * 100))
//     const originalGain = track.gain

//     let oldGain = playlist.getTrackKeyValue(String(track.customClass), 'gain')
//     let undoGain = [{ operation: 'volumeChange', customClass: { customClass: String(track.customClass), affectedKey: 'gain', value: oldGain }, id: create_UUID() }]

//     let oldPan = playlist.getTrackKeyValue(String(track.customClass), 'panning')
//     let undoPan = [{ operation: 'stereoPanning', customClass: { customClass: String(track.customClass), affectedKey: 'stereoPan', value: oldPan }, id: create_UUID() }]

//     // let trackDetails = playlist.tracks.filter(t => t.customClass == track.customClass && t.src)[0]

//     //if(playlist.activeTrack.customClass!== track.customClass){
//     if((playlist.activeTrack.annotCueIn === undefined && playlist.activeTrack.annotCueOut === undefined) || playlist.activeTrack.customClass!== track.customClass){

//         //const blankTrack= playlist.tracks.find(track=> track=== playlist.activeTrack)
//         playlist.tracks.forEach(t=>{
//             if(t.customClass===track.customClass) playlist.activeTrack = t
//         })
//     }
//     let trackDetails = playlist.activeTrack
//     $('#vol-ctrl-track').text(trackDetails.name)
//     slider.slider({
//         orientation: 'vertical',
//         min: 0,
//         max: 100,
//         value: parseInt(track.gain * 100 / (2 * playlist.gainReference)),
//         range: 'min',
//         slide: function (event, ui) {
//             const permResponse = ACM.checkPermission('volumeChange')
//             if (!permResponse.success) {
//                 event.preventDefault()
//                 return showStatText(permResponse.message)
//             }
//             $('.' + trackDetails.customClass.split('-')[0] + '-vol').text((ui.value / 10).toFixed(1))
//         },
//         stop: function (event, ui) {
//             if (!playlist.isSegmentSelection() && subVolumeData == undefined && !playlist.isCustomClassSelected && !volumeOnClickActiveTrackSelected) {
//                 updateVolumeText(trackDetails.customClass.split('-')[0], trackDetails.gain)
//                 updateVolumeSlider(trackDetails.gain)
//                 // $('.' + trackDetails.customClass.split('-')[0] + '-vol').text((trackDetails.gain * 10).toFixed(1))
//                 // $('.volume-container  .ui-slider-range').css({ height: (trackDetails.gain * 100).toFixed(1) + '%' })
//                 showStatText("please select segments to add sub volume")
//                 return false
//             }
//             if (playlist.isSegmentSelection() && fadeExists()) {
//                 updateVolumeText(trackDetails.customClass.split('-')[0], trackDetails.gain)
//                 updateVolumeSlider(trackDetails.gain)
//                 // $('.' + trackDetails.customClass.split('-')[0] + '-vol').text((trackDetails.gain * 10).toFixed(1))
//                 // $('.volume-container  .ui-slider-range').css({ height: (trackDetails.gain * 100).toFixed(1) + '%' })
//                 showStatText("cannot add sub volume on fade")
//                 return false
//             }
//             if(trackDetails.src=== undefined){
//                 const hasSrc = playlist.tracks.some(track=>{
//                     return track.customClass===trackDetails.customClass && track.src !== undefined
//                 })
//                 if(!hasSrc){
//                     updateVolumeText(trackDetails.customClass.split('-')[0], trackDetails.gain)
//                     updateVolumeSlider(trackDetails.gain)
//                     showStatText("please assign a track first to change volume")
//                     return false
//                 }
//             }
//             //let value = slider.slider('value') / 100
//             const value = getGainFromVolumeSlider()
//             if (value.toFixed(2) !== originalGain.toFixed(2)) {
//                 if (playlist.isCustomClassSelected) {
//                     // master gain
//                     let selectedTrackType = $('#spk-volume-alt-icon').hasClass('active') ? 'speakers' : 'sfx'
//                     let selectedCC = Array.from(playlist.selectedCustomClasses).filter(cc => cc.split('-')[0] === selectedTrackType)

//                     if (selectedCC.length === 1) {
//                         ee.emit('volumechange', value, String(selectedCC[0]))
//                         playlist.updateCustomClass(String(selectedCC[0]), 'gain', value)
//                         volumeChangeOperation(String(selectedCC[0]), value, undoGain)
//                         updateOperationSpace()
//                     } else if (selectedCC.length > 1) {
//                         ee.emit('volumechange', value, String(masterTracks[selectedTrackType]))
//                         playlist.updateCustomClass(String(masterTracks[selectedTrackType]), 'gain', value)
//                         volumeChangeOperation(String(masterTracks[selectedTrackType]), value, undoGain)
//                         updateOperationSpace()
//                     }
//                 } else if (trackDetails.startTime == playlist.getTimeSelection().start && trackDetails.startTime + trackDetails.cueOut == playlist.getTimeSelection().end) {
//                     // if the track is completely in the selection, change the gain
//                     // console.warn("2")
//                     ee.emit('volumechange', value, String(track.customClass))
//                     playlist.updateCustomClass(String(track.customClass), "gain", value)
//                     volumeChangeOperation(String(track.customClass), value, undoGain)
//                     updateOperationSpace()
//                     return
//                 } else if (volumeOnClickActiveTrackSelected) {
//                     //implementation of #2298
//                     //const selectedActiveTracks = playlist.tracks.filter(t => t.customClass === track.customClass && (t.annotCueIn !== undefined && t.annotCueOut !== undefined))
//                     //const selectedTrackTimes =selectedActiveTracks.map(t=>{return  {"startTime" : t.startTime, "endTime" : t.endTime}})
//                     //const speakerName= selectedActiveTracks[0].name
//                     //if (selectedActiveTracks.length) multiSubVolumeOperation(selectedTrackTimes, value, speakerName)
//                     ee.emit('volumechange', value, String(track.customClass))
//                     playlist.updateCustomClass(String(track.customClass), "gain", value)
//                     volumeChangeOperation(String(track.customClass), value, undoGain)
//                     updateOperationSpace()
//                     return
//                     //implementation of #2298
//                 } else if (playlist.isSegmentSelection()) {
//                     // new sub volume
//                     return subVolumeOperation(playlist.getTimeSelection().start-.01, playlist.getTimeSelection().end, value)
//                 } else {
//                     // update sub volume
//                     if (!subVolumeData) return false
//                     return subVolumeOperation(subVolumeData.start, subVolumeData.end, value)
//                 }
//             }
//         }
//     })

//     if (!knobCreated) {
//         $knob.on('turn', function (e, value, valueNormalized) {
//             // if(!ACM.checkPermission("stereoPanning")) {
//             //   showStatText("please upgrade your account")
//             //   return false
//             // } else {

//             const trackType = $('#volume-slider').data('trackType')
//             if (!trackType) return false

//             if (!(masterTracks && masterTracks[trackType] && masterTracks[trackType].length)) return false
//             if (!(playlist && playlist.tracks && playlist.tracks.length)) return false
//         })

//         $knob.on('release', function (event, value, valueNormalized) {
//             if (!playlist.isSegmentSelection() && subPanningData == undefined && !playlist.isCustomClassSelected) {

//                 $knob.knob('value', parseInt(trackDetails.stereoPan* 100))
//                 showStatText("please select segments to add sub panning")
//                 return false
//             }

//             const trackType = $('#volume-slider').data('trackType')
//             if (!trackType) return false
//             // if(!ACM.checkPermission("stereoPanning")) {
//             //   showStatText("please upgrade your account")
//             //   return false
//             // } else {

//             if (playlist.isCustomClassSelected) {
//                 // console.warn("updating master panning")
//                 // master pan
//                 let selectedTrackType = $('#spk-volume-alt-icon').hasClass('active') ? 'speakers' : 'sfx'
//                 let selectedCC = Array.from(playlist.selectedCustomClasses).filter(cc => cc.split('-')[0] === selectedTrackType)

//                 if (selectedCC.length === 1) {

//                     playlist.updateTrackKeyValue(String(selectedCC[0]), 'stereoPan', valueNormalized)
//                     playlist.updateCustomClass(String(selectedCC[0]), 'panning', valueNormalized)
//                     stereoPanningOperation(String(selectedCC[0]), valueNormalized, undoPan)
//                     updateOperationSpace()

//                 } else if (selectedCC.length > 1) {

//                     playlist.updateTrackKeyValue(String(masterTracks[selectedTrackType]), 'stereoPan', valueNormalized)
//                     playlist.updateCustomClass(String(masterTracks[selectedTrackType]), 'panning', valueNormalized)
//                     stereoPanningOperation(String(masterTracks[selectedTrackType]), valueNormalized, undoPan)
//                     updateOperationSpace()

//                 }
//             }
//             else if (trackDetails.startTime == playlist.getTimeSelection().start && trackDetails.startTime + trackDetails.cueOut == playlist.getTimeSelection().end) {
//                 // if the track is completely in the selection, change the gain
//                 playlist.updateTrackKeyValue(String(track.customClass), 'stereoPan', valueNormalized)
//                 playlist.updateCustomClass(String(track.customClass), 'panning', valueNormalized)
//                 stereoPanningOperation(String(track.customClass), valueNormalized, undoPan)
//                 updateOperationSpace()

//             }
//             else if (playlist.isSegmentSelection()) {
//                 // new sub volume
//                 return subPanningOperation(playlist.getTimeSelection().start, playlist.getTimeSelection().end, valueNormalized)
//             }
//             else {
//                 // update sub volume
//                 if (!subPanningData) return false
//                 return subPanningOperation(subPanningData.start, subPanningData.end, valueNormalized)
//             }
//         })

//         knobCreated = true
//     }
// }

// async function subPanningOperation (selectionStart, selectionEnd, pan) {
//     let operationId = create_UUID()
//     OPStack.push(operationId)

//     let params = {
//         jobname,
//         refid: operationId,
//         parentRefid: playlist.getJobHead(),
//         worker: 'subPanning',
//         inputs: {
//             startTime: selectionStart,
//             endTime: selectionEnd,
//             pan,
//             selectedCustomClasses: [...playlist.filterSelectedCustomClasses('silence')]
//         }
//     }

//     let applyParams = {
//         alias_id: operationId,
//         operation: params,
//         parent_refid: playlist.getJobHead(),
//         refid: operationId
//     }

//     let res = await playlist.subPanning(selectionStart, selectionEnd, pan)
//     let resultsStat = {...indexDBResultStat}
//     let undoRes = await updateLocalResultStat(resultsStat, operationId)
//     playlist.undoStack.push({ refid: operationId, parentRefid: playlist.getJobHead(), operations: res, applyParams, resultsStat: undoRes })
//     updateOperationSpace()
//     playlist.setJobHead(operationId)
//     addOperationsToDB(params, undefined, false, "false", "pending", "false")
//     operationList.unshift({
//         params,
//         containsFile: false,
//         tracks: []
//     })
//     if (!isOperationsTransActive) sendOperation()
//     hideplaylistloader()
// }

// async function subVolumeOperation(selectionStart, selectionEnd, gain) {
//     let operationId = create_UUID()
//     OPStack.push(operationId)

//     updateVolumeText(masterTrackType, gain)
//     // $('.' + masterTrackType + '-vol').text((gain * 10).toFixed(1))
//     $('#vol-ctrl-track').text(selectionStart.toFixed(2) + ' - ' + selectionEnd.toFixed(2))

//     let params = {
//         jobname,
//         refid: operationId,
//         parentRefid: playlist.getJobHead(),
//         worker: 'subVolume',
//         inputs: {
//             startTime: selectionStart,
//             endTime: selectionEnd,
//             gain,
//             selectedCustomClasses: [...playlist.filterSelectedCustomClasses('silence')]
//         }
//     }

//     let applyParams = {
//         alias_id: operationId,
//         operation: params,
//         parent_refid: playlist.getJobHead(),
//         refid: operationId
//     }

//     let res = await playlist.subVolume(selectionStart, selectionEnd, gain)
//     let resultsStat = {...indexDBResultStat}
//     let undoRes = await updateLocalResultStat(resultsStat, operationId)
//     playlist.undoStack.push({ refid: operationId, parentRefid: playlist.getJobHead(), operations: res, applyParams, resultsStat: undoRes })
//     updateOperationSpace()
//     playlist.setJobHead(operationId)
//     addOperationsToDB(params, undefined, false, "false", "pending", "false")
//     operationList.unshift({
//         params,
//         containsFile: false,
//         tracks: []
//     })
//     if (!isOperationsTransActive) sendOperation()
//     hideplaylistloader()
// }

// //commented due to updated #2298
// //async function multiSubVolumeOperation(selectedTrackTimes, gain, speakerName) {
// //    let operationId = create_UUID()
// //    OPStack.push(operationId)
// //    let operations = []

// //    $('.' + masterTrackType + '-vol').text((gain * 10).toFixed(1))

// //    let params = {
// //        jobname,
// //        refid: operationId,
// //        parentRefid: playlist.getJobHead(),
// //        worker: 'multiSubVolume',
// //        inputs: {
// //            selectedTrackTimes,
// //            gain,
// //            selectedCustomClasses: [...playlist.filterSelectedCustomClasses('silence')]
// //        }
// //    }
// //    let applyParams = {
// //        alias_id: operationId,
// //        operation: params,
// //        parent_refid: playlist.getJobHead(),
// //        refid: operationId
// //    }
// //    for (let i=0; i<selectedTrackTimes.length; i++){
// //        let res = await playlist.subVolume(selectedTrackTimes[i].startTime, selectedTrackTimes[i].endTime, gain)
// //        operations.push(...res)
// //    }
// //    playlist.undoStack.push({ refid: operationId, parentRefid: playlist.getJobHead(), operations: operations, applyParams })
// //    updateOperationSpace()
// //    playlist.setJobHead(operationId)
// //    operationList.unshift({
// //        params,
// //        containsFile: false,
// //        tracks: []
// //    })
// //    if (!isOperationsTransActive) {
// //        sendOperation()
// //    }
// //    hideplaylistloader()
// //}
// //commented due to updated #2298

// function mute(trackType) {
//     if (!(masterTracks && masterTracks[trackType] && masterTracks[trackType].length)) {
//         sendLog({
//             activity: 'click',
//             target: 'mute',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'failed',
//             statusMsg: 'empty masterTracks',
//             data: {
//                 trackType
//             }
//         })
//         return false
//     }
//     if (!(playlist && playlist.tracks && playlist.tracks.length)) {
//         sendLog({
//             activity: 'click',
//             target: 'mute',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'failed',
//             statusMsg: 'empty playlist',
//             data: {
//                 trackType
//             }
//         })
//         return false
//     }

//     sendLog({
//         activity: 'click',
//         target: $('.' + trackType + '-mute').hasClass('active') ? 'unmute' : 'mute',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success',
//         data: {
//             customClass: playlist.tracks[playlist.tracks.indexOf(playlist.getTrackMS(masterTracks[trackType]))].customClass
//         }
//     })

//     ee.emit('mute', playlist.tracks[playlist.tracks.indexOf(playlist.getTrackMS(masterTracks[trackType]))])
//     $('.' + trackType + '-mute').toggleClass('active')
//     if ($('.' + trackType + '-solo').hasClass('active')) { $('.' + trackType + '-solo').removeClass('active') }
//     updateMasterTracks()
// }

// function solo(trackType) {
//     if (!(masterTracks && masterTracks[trackType] && masterTracks[trackType].length)) {
//         sendLog({
//             activity: 'click',
//             target: 'solo',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'failed',
//             statusMsg: 'empty masterTracks',
//             data: {
//                 trackType
//             }
//         })
//         return false
//     }
//     if (!(playlist && playlist.tracks && playlist.tracks.length)) {
//         sendLog({
//             activity: 'click',
//             target: 'solo',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'failed',
//             statusMsg: 'empty playlist',
//             data: {
//                 trackType
//             }
//         })
//         return false
//     }

//     sendLog({
//         activity: 'click',
//         target: $('.' + trackType + '-solo').hasClass('active') ? 'unsolo' : 'solo',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success',
//         data: {
//             customClass: playlist.tracks[playlist.tracks.indexOf(playlist.getTrackMS(masterTracks[trackType]))].customClass
//         }
//     })

//     ee.emit('solo', playlist.tracks[playlist.tracks.indexOf(playlist.getTrackMS(masterTracks[trackType]))])
//     $('.' + trackType + '-solo').toggleClass('active')
//     if ($('.' + trackType + '-mute').hasClass('active')) { $('.' + trackType + '-mute').removeClass('active') }
//     updateMasterTracks()
// }

// function volumeControlMenu(event, trackType) {
//     if (event) event.preventDefault()
//     if (playlist.mode !== 'edit') {
//         sendLog({
//             activity: 'click',
//             target: 'open volume control',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'failed',
//             statusMsg: 'operation not permitted in view mode'
//         })
//         showStatText('operation not permitted in view mode')
//         return false
//     }
//     if (event && document.getElementById('vol-ctrl-menu').style.visibility === 'visible') {
//         sendLog({
//             activity: 'click',
//             target: 'close volume control',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'success',
//             statusMsg: 'success',
//             data: {
//                 customClass: masterTracks[trackType]
//             }
//         })
//         return resetVolumeCtrlMenu()
//     }
//     if (masterTrackType !== trackType && !playlist.isCustomClassSelected) {
//         showStatText(`please use ${masterTrackType} volume controller`)
//         return false
//     }
//     if (!(masterTracks && masterTracks[trackType] && masterTracks[trackType].length)) {
//         sendLog({
//             activity: 'click',
//             target: 'open volume control',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'failed',
//             statusMsg: 'empty masterTracks'
//         })
//         return false
//     }
//     if (!(playlist && playlist.tracks && playlist.tracks.length)) {
//         sendLog({
//             activity: 'click',
//             target: 'open volume control',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'failed',
//             statusMsg: 'empty playlist'
//         })
//         return false
//     }
//     $('#volume-slider').data('trackType', '')
//     if (!$('#volume-slider').data('trackType')) $('#volume-slider').data('trackType', trackType)
//     else if ($('#volume-slider').data('trackType') !== trackType) return false
//     sendLog({
//         activity: 'click',
//         target: 'open volume control',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success',
//         data: {
//             customClass: masterTracks[trackType]
//         }
//     })
//     let tracks = playlist.getCustomClasses()
//     let track
//     for (let i = 0; i < tracks.length; i++) {
//         if (tracks[i].customClass === masterTracks[trackType]) { track = tracks[i] }
//     }
//     if (!track) return false

//     if (playlist.isCustomClassSelected) {
//         if (event && event.target && event.target.id) {
//             selectedTrackType = event.target.id === 'spk-volume-alt-icon' ? 'speakers' : event.target.id === 'sfx-volume-alt-icon' ? 'sfx' : ''
//             let selectedCC = Array.from(playlist.selectedCustomClasses).filter(cc => cc.split('-')[0] === selectedTrackType)

//             if (selectedCC.length >= 1) initVolCtrl(track)
//             else {
//                 showStatText('select ' + selectedTrackType + ' segments to update master gain')
//                 return false
//             }
//         }
//     }
//     else if (playlist.isSegmentSelection()) initVolCtrl(track)

//     //implementation of #2298
//     else if (!playlist.isSegmentSelection() && volumeOnClickActiveTrackSelected) {
//         volumeOnClickActiveTrackSelected = true
//         initVolCtrl(track)
//         //if (volumeOnClickActiveTrackSelected) document.getElementById('vol-ctrl-track').innerText= playlist.activeTrack.name
//     }
//     //implementation of #2298

//     else {
//         let subVolumeFound = false, subPanningFound = false
//         let subVolumeData, subPanningData

//         if (!event || !event.target || !event.target.id) return false

//         let volCtrlMenuTrack = event.target.id === 'spk-volume-alt-icon' ? 'speakers' : event.target.id === 'sfx-volume-alt-icon' ? 'sfx' : ''

//         let currentTrack = playlist.tracks.filter(t => t.customClass == masterTracks[!volCtrlMenuTrack ? playlist.activeTrack.customClass.split('-')[0] : volCtrlMenuTrack] && t.startTime <= playlist.cursor && t.endTime >= playlist.cursor && t.src)[0]
//         if (!currentTrack) return

//         for (let i = 0; i < currentTrack.subVolumes.length; i++) {
//             let subVolumeStart = currentTrack.subVolumes[i].start + currentTrack.startTime
//             let subVolumeEnd = currentTrack.subVolumes[i].end + currentTrack.startTime

//             if (subVolumeStart <= playlist.cursor && playlist.cursor <= subVolumeEnd) {
//                 subVolumeFound = true
//                 subVolumeData = currentTrack.subVolumes[i]
//                 break
//             }
//         }

//         for (let i = 0; i < currentTrack.subPannings.length; i++) {
//             let subVolumeStart = currentTrack.subPannings[i].start + currentTrack.startTime
//             let subVolumeEnd = currentTrack.subPannings[i].end + currentTrack.startTime

//             if (subVolumeStart <= playlist.cursor && playlist.cursor <= subVolumeEnd) {
//                 subPanningFound = true
//                 subPanningData = currentTrack.subPannings[i]
//                 break
//             }
//         }

//         if (!subVolumeFound && !subPanningFound || !volumeOnClickActiveTrackSelected) {
//             showStatText('select segment to update volume and panning')
//             return
//         } else initVolCtrl(track, subVolumeData, subPanningData)
//     }

//     document.getElementById('vol-ctrl-menu').style.visibility = 'visible'

//     anime({
//         targets: '#vol-ctrl-menu',
//         easing: 'easeOutQuint',
//         translateX: ['100%', 0],
//         duration: 600
//     })

//     if (trackType === 'speakers') {
//         document.getElementById('spk-volume-alt-icon').src = '/static/img/volume-ctrl-active.svg'
//         document.getElementById('spk-volume-alt-icon').classList.add('active')
//         document.getElementsByClassName('volume-alt-icon-transcript').forEach(e=>e.src = '/static/img/volume-ctrl-active.svg')
//         document.getElementsByClassName('volume-alt-icon-transcript').forEach(e=>e.classList.add('active'))
//         document.getElementById('sfx-volume-alt-icon').classList.remove('active')
//         volumeUpdate(playlist.activeTrack, playlist.cursor)
//     } else if (trackType === 'sfx') {
//         document.getElementById('sfx-volume-alt-icon').src = '/static/img/volume-ctrl-active.svg'
//         document.getElementById('sfx-volume-alt-icon').classList.add('active')
//         document.getElementById('spk-volume-alt-icon').classList.remove('active')
//         document.getElementsByClassName('volume-alt-icon-transcript').forEach(e=>e.classList.remove('active'))
//         volumeUpdate(playlist.activeTrack, playlist.cursor)
//     }
// }

// function resetVolumeCtrlMenu() {
//     $('#volume-slider').data('trackType', '')
//     $('#volume-slider').data('customClass', '')
//     document.getElementById('vol-ctrl-menu').style.visibility = 'hidden'
//     document.getElementById('spk-volume-alt-icon').src = '/static/img/volume-ctrl.svg'
//     document.getElementsByClassName('volume-alt-icon-transcript').forEach(e=>e.src = '/static/img/volume-ctrl.svg')
//     document.getElementById('sfx-volume-alt-icon').src = '/static/img/volume-ctrl.svg'
//     document.getElementById('spk-volume-alt-icon').classList.remove('active')
//     document.getElementsByClassName('volume-alt-icon-transcript').forEach(e=>e.classList.remove('active'))
//     document.getElementById('sfx-volume-alt-icon').classList.remove('active')
// }

// function userProfileModal(event,modal11) {
//     //<---implementation of #3037 start-->
//     if (document.getElementById('cont').style.display == 'none' && modal11) {
//         showStatText('button disabled')
//         return false
//     }
//     //<----end--->
//     if (event) event.preventDefault()
//     hideNotificationWindow()
//     sendLog({
//         activity: 'click',
//         target: 'open user profile modal',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })
//     document.getElementById('annotmodal').style.display = 'block'
//     document.getElementById('modal_8').style.display = 'block'
//     initiateACM().then(acm => {
//         checkAccountDetails()
//     }).catch(exp => {
//         document.getElementById('account-role').innerHTML = ACM.planName + ' Account' //'Admin Account'
//     })
// }

// audioPlayFlag = false

// // More options page
// function moreOptionsMenu() {
//     //<---implementation of #3037 start-->
//     if(document.getElementById('cont').style.display == 'none')
//     {
//         showStatText('button disabled')
//         return false
//     }
//     //<-----end--->
//     getSharedUser()
//     forceHideMessage()
//     document.getElementById('more-options-menu').style.display = 'flex'
//     //tour start
//     destroytour()
//     startTour('more_options_tour')
//     if (!(transcriptionCompleted)) {
//         shareOptions()
//         sendLog({
//             activity: 'click',
//             target: 'open more options (view mode only)',
//             timeStamp: new Date().toISOString(),
//             jobname,
//             status: 'success',
//             statusMsg: 'success'
//         })
//         return
//     }
//     exportProjectTitle()
//     exportOptions()
//     getSizeOfTheProject()
//     if (document.getElementById('history').style.display === 'block') {
//         historyOptions(0,0)
//     }
//     if (document.getElementById('share').style.display === 'block') {
//         historyOptions(0,0)
//     }
//     sendLog({
//         activity: 'click',
//         target: 'open more options',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })
//     if ((playCont.classList.contains('btn-pause'))) {
//         pauseAudio()
//         audioPlayFlag = true
//     }
// }

// function closeMoreOptions() {
//     sendLog({
//         activity: 'click',
//         target: 'close more options',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })
//     forceHideMessage()
//     document.getElementById('more-options-menu').style.display = 'none'
//     // if($('#ai-enhancement-checkbox-uo').hasClass('checked')){
//     //     $('#ai-enhancement-checkbox-uo').addClass('unchecked').removeClass('checked')
//     //     $('#ai-enhancement-checkbox-uo').attr('src', '/static/img/checkbox-unchecked.png')
//     // }
//     destroytour()
//     if (audioPlayFlag) {
//         playAudio()
//         audioPlayFlag = false
//     }
// }

// function editAInotesDblClick (event,id) {
//     if (event) event.preventDefault()
//     const $element = $(`#${id}`)
//     const oldItemText = $element.text()

//     // Set contenteditable to true and focus the element
//     $element.attr('contenteditable', 'true').focus()

//     // Move the cursor to the end of the text segment - 2885 issue starts here
//     const textNode = $element.get(0).childNodes[0]
//     const range = document.createRange()
//     const selection = window.getSelection()
//     range.setStart(textNode, oldItemText.length)
//     range.collapse(true)
//     selection.removeAllRanges()
//     selection.addRange(range)
//     //moving the cursor at the end of text segment - 2885 issue ends here

//     // Handle Enter key press
//     $element.keypress(function (e) {
//         if (e.which === 13 || e.keyCode === 13) {
//             e.target.blur()
//             e.preventDefault()
//         }
//     })
//     $(`#${id}`).off('blur').on('blur', function () {
//         let newItemText = this.textContent
//         if (!(newItemText.trim().length)) {
//             document.getElementById(`${id}`).textContent = oldItemText
//             showStatText('Notes cannot be empty')
//             return false
//         }
//         else this.textContent = this.textContent.trim()
//         this.setAttribute('contenteditable', false)
//         const aiShowNotes = JSON.parse(sessionStorage.getItem('aiShowNotes'))
//         if(this.id === "keywordsBox"){
//             const oldKeywords = JSON.parse(aiShowNotes.keywords)
//             const newKeywords = JSON.stringify(this.textContent.split(","))
//             aiShowNotes.keywords = newKeywords
//             sessionStorage.setItem("aiShowNotes", JSON.stringify(aiShowNotes))
//             if (oldItemText !== this.textContent){
//                 sendEditedAiNotesToBackend({keywords : newKeywords})
//             }

//         }
//         else if(id.split("-")[0] === "social_media_postings") {
//             const [key, index] = id.split("-")
//             const item = JSON.parse(aiShowNotes[key])
//             const oldItem = item[index]
//             if(typeof item[index] === "string"){
//                 item[index] = this.textContent
//             }
//             else {
//                 const newSocialMediaPost = {}
//                 const newSocialMediaPostKey = document.getElementById(id).parentNode.innerText.split(". ")[1].split(" -")[0]
//                 newSocialMediaPost[newSocialMediaPostKey] = this.textContent
//                 item[index] = newSocialMediaPost
//             }
//             aiShowNotes[key] = JSON.stringify(item)
//             sessionStorage.setItem("aiShowNotes", JSON.stringify(aiShowNotes))
//             if (oldItemText !== this.textContent){
//                 sendEditedAiNotesToBackend({[key] : JSON.stringify(item)})
//             }
//         }
//         else {
//             const [key, index] = id.split("-")
//             const item = JSON.parse(aiShowNotes[key])
//             const oldItem = item[index]
//             item[index] = this.textContent
//             aiShowNotes[key] = JSON.stringify(item)
//             sessionStorage.setItem("aiShowNotes", JSON.stringify(aiShowNotes))
//             if (oldItemText !== this.textContent){
//                 sendEditedAiNotesToBackend({[key] : JSON.stringify(item)})
//             }
//         }
//     })
// }
// function editAInotesDblClick (event,id) {
//     if (event) event.preventDefault()
//     const $element = $(`#${id}`)
//     const oldItemText = $element.text()

//     // Set contenteditable to true and focus the element
//     $element.attr('contenteditable', 'true').focus()

//     // Move the cursor to the end of the text segment - 2885 issue starts here
//     const textNode = $element.get(0).childNodes[0]
//     const range = document.createRange()
//     const selection = window.getSelection()
//     range.setStart(textNode, oldItemText.length)
//     range.collapse(true)
//     selection.removeAllRanges()
//     selection.addRange(range)
//     //moving the cursor at the end of text segment - 2885 issue ends here

//     // Handle Enter key press
//     $element.keypress(function (e) {
//         if (e.which === 13 || e.keyCode === 13) {
//             e.target.blur()
//             e.preventDefault()
//         }
//     })
//     $(`#${id}`).off('blur').on('blur', function () {
//         let newItemText = this.textContent
//         if (!(newItemText.trim().length)) {
//             document.getElementById(`${id}`).textContent = oldItemText
//             showStatText('Notes cannot be empty')
//             return false
//         }
//         else this.textContent = this.textContent.trim()
//         this.setAttribute('contenteditable', false)
//         const aiShowNotes = JSON.parse(sessionStorage.getItem('aiShowNotes'))
//         if(this.id === "keywordsBox"){
//             const oldKeywords = JSON.parse(aiShowNotes.keywords)
//             const newKeywords = JSON.stringify(this.textContent.split(","))
//             aiShowNotes.keywords = newKeywords
//             sessionStorage.setItem("aiShowNotes", JSON.stringify(aiShowNotes))
//             if (oldItemText !== this.textContent){
//                 sendEditedAiNotesToBackend({keywords : newKeywords})
//             }

//         }
//         else if(id.split("-")[0] === "social_media_postings") {
//             const [key, index] = id.split("-")
//             const item = JSON.parse(aiShowNotes[key])
//             const oldItem = item[index]
//             if(typeof item[index] === "string"){
//                 item[index] = this.textContent
//             }
//             else {
//                 const newSocialMediaPost = {}
//                 const newSocialMediaPostKey = document.getElementById(id).parentNode.innerText.split(". ")[1].split(" -")[0]
//                 newSocialMediaPost[newSocialMediaPostKey] = this.textContent
//                 item[index] = newSocialMediaPost
//             }
//             aiShowNotes[key] = JSON.stringify(item)
//             sessionStorage.setItem("aiShowNotes", JSON.stringify(aiShowNotes))
//             if (oldItemText !== this.textContent){
//                 sendEditedAiNotesToBackend({[key] : JSON.stringify(item)})
//             }
//         }
//         else {
//             const [key, index] = id.split("-")
//             const item = JSON.parse(aiShowNotes[key])
//             const oldItem = item[index]
//             item[index] = this.textContent
//             aiShowNotes[key] = JSON.stringify(item)
//             sessionStorage.setItem("aiShowNotes", JSON.stringify(aiShowNotes))
//             if (oldItemText !== this.textContent){
//                 sendEditedAiNotesToBackend({[key] : JSON.stringify(item)})
//             }
//         }
//     })
// }

// // Export project
// function exportOptions() {
//     if (!(transcriptionCompleted)) {
//         moreOptionsAlert('transcription in progress, operation disabled')
//         return
//     }
//     sendLog({
//         activity: 'click',
//         target: 'open export tab',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })
//     document.getElementById('export').style.display = 'block'
//     document.getElementById('share').style.display = 'none'
//     document.getElementById('delete').style.display = 'none'
//     document.getElementById('history').style.display = 'none'
//     //document.getElementById('genAiContent').style.display = 'none'
//     //document.getElementById('genAi-icon').style.opacity = '0.5'
//     document.getElementById('delete-icon').style.opacity = '0.5'
//     document.getElementById('export-icon').style.opacity = '1'
//     document.getElementById('share-icon').style.opacity = '0.5'
//     document.getElementById('history-icon').style.opacity = '0.5'
// }

// function exportFormatSettings() {
//     if (document.getElementById('wav').checked) {
//         document.getElementById('wav-options').style.display = 'block'
//         document.getElementById('mp3-options').style.display = 'none'
//         document.getElementById('m4a-options').style.display = 'none'
//         document.getElementById('btn-lq-download').disabled = false
//         $('#btn-lq-download').css({ opacity: '1', cursor: 'pointer' })
//     } else if (document.getElementById('mp3').checked) {
//         document.getElementById('wav-options').style.display = 'none'
//         document.getElementById('mp3-options').style.display = 'block'
//         document.getElementById('m4a-options').style.display = 'none'
//         document.getElementById('btn-lq-download').disabled = true
//         document.getElementById('btn-lq-download').style.marginLeft = '0'
//         document.getElementById('btn-lq-download').style.marginRight = '0'
//         $('#btn-lq-download').css({ opacity: '0.5', cursor: 'not-allowed' })
//     } else if (document.getElementById('m4a').checked) {
//         document.getElementById('wav-options').style.display = 'none'
//         document.getElementById('mp3-options').style.display = 'none'
//         document.getElementById('m4a-options').style.display = 'block'
//         document.getElementById('btn-lq-download').disabled = true
//         document.getElementById('btn-lq-download').style.marginLeft = '0'
//         document.getElementById('btn-lq-download').style.marginRight = '0'
//         $('#btn-lq-download').css({ opacity: '0.5', cursor: 'not-allowed' })
//     }
//     getSizeOfTheProject()
// }

export function getFormat() {
  let format;
  for (let i = 0; i < supportedExportFormats.length; i++) {
    if (document.getElementById(supportedExportFormats[i]).checked) {
      format = supportedExportFormats[i];
      break;
    }
  }
  return format || "wav";
}

// async function exportProject(event, audioQuality, aiEnabled = false) {
//     if (event) event.preventDefault()
//     const format = getFormat()
//     const filename = document.getElementById('exportprojectname').innerText
//     const bitDepthorRate = document.getElementById(format + '-bit-depth').value
//     const sampleRate = document.getElementById(format + '-sample-rate').value
//     const channels = document.getElementById(format + '-channel').value
//     const normalize = $('#normalize-checkbox-uo').hasClass('checked')
//     const options = {
//         format,
//         filename,
//         sampleRate: parseInt(sampleRate),
//         channels: parseInt(channels),
//         normalize
//     }
//     if (format === 'wav') {
//         options.bitDepth = bitDepthorRate
//         if (options.bitDepth === 32) {
//             options.bitRate = '2048'
//         }
//     } else options.bitRate = bitDepthorRate
//     // var fileSize = await calculateFileSize(options)
//     if (audioQuality === 'high') {
//         try {
//             $('#half-modals').html(highQualityExportModal)
//             koolioModals()
//             let response = await exportHighQualityAudio(options, aiEnabled, false)
//             document.getElementById('hq-export-modal-header').innerText = response.data.status_message
//         } catch (error) {
//             document.getElementById('hq-export-modal-header').innerText = error
//             document.getElementById('hq-export-modal-sub-header').innerText = 'Please try again'
//         }
//         $('#half-modals').css('visibility', 'visible')
//     } else {
//         options.format = 'wav'
//         ee.emit('startaudiorendering', options)
//     }
// }

// let autoLevelInfo
// function startAutoLevel() {
//     autoLevelInfo = {}
//     playlist.customClasses.forEach(customClass => {
//         // count number of tracks under this customClass
//         const count = playlist.tracks.reduce((count, track) => {
//             if(track.customClass === customClass.customClass && track.buffer)
//                 return count + 1
//             else
//                 return count
//         }, 0)
//         if(count === 0)
//             return
//         autoLevelInfo[customClass.customClass] = {
//             totalTrackCount: count,
//             remainingRenderedTrackCount: count,
//             renderedBuffer: undefined
//         }
//     })
//     playlist.tracks.forEach(track => {
//         if(!track.buffer)
//             return
//         const options = {
//             "bitDepth": 16,
//             "channels": 2,
//             "customClass": track.customClass,
//             "expectedLUFS": -20,
//             "format": "buffer",
//             "normalize": true,
//             "sampleRate": playlist.sampleRate,
//             "trackId": track.id
//         }
//         playlist.startOfflineRenderTrack(options)
//     })
// }

// function createMeters (source, modes, options) {
//     var loudnessMeter = new LoudnessMeter({
//         source: source,
//         workerUri: 'static/js/vendor/needles-worker.js',
//         workletUri: 'static/js/vendor/needles-worklet.js',
//         modes: modes
//     })

//     loudnessMeter.on('dataavailable', event => {
//         const mode = event.data.mode
//         const currentLUFS = event.data.value
//         if(mode === 'integrated') {
//             calculateAndFixTrackLoudness(options, currentLUFS)
//         }
//     })

//     return loudnessMeter
// }

// function calculateAndFixTrackLoudness(options, currentLUFS) {
//     autoLevelInfo[options.customClass].isDone = true
//     if(!isFinite(currentLUFS)) {
//         // infinity. most likely buffer was shorter than 3seconds
//         return
//     }
//     if(currentLUFS <= (options.expectedLUFS + 1) && currentLUFS >= (options.expectedLUFS -1)) {
//         // we're within +/- 1 LU offset, nothing to do
//         showStatText('Auto levelling already done...')
//         enableEditPreviewBtns()
//         return
//     }

//     const customClassObj = playlist.customClasses.find(customClass => customClass.customClass === options.customClass)
//     if(customClassObj) {
//         const oldGain = playlist.getTrackKeyValue(String(options.customClass), 'gain')
//         const dBGain = options.expectedLUFS - currentLUFS
//         const newGain = oldGain * Math.pow(10, dBGain / 20)
//         const volumeChangeOperation = {
//             newValue: newGain,
//             undo: {
//                 operation: 'volumeChange',
//                 customClass: {
//                     customClass: String(options.customClass),
//                     affectedKey: 'gain',
//                     value: oldGain
//                 },
//                 id: create_UUID()
//             }
//         }
//         autoLevelInfo[options.customClass]['volumeChangeOperation'] = volumeChangeOperation
//         ee.emit('volumechange', newGain, String(options.customClass))
//         playlist.updateCustomClass(String(options.customClass), 'gain', newGain)
//         // volumeChangeOperation(String(options.customClass), newGain, [undoGain])
//         // updateOperationSpace()
//     }
//     // check if we have processed all customClasses
//     // if so, create a combined operation
//     for (const [customClass, data] of Object.entries(autoLevelInfo)) {
//         if(!data.isDone)
//             return // some auto level operations still pending
//     }
//     // all auto level operations are done
//     const newGainRef = calculateNewGainRef()
//     const oldGainRef = playlist.gainReference
//     const gainRefOperation = {
//         newValue: newGainRef,
//         undo: {
//             operation: 'gainReferenceChange',
//             customClass: {
//                 affectedKey: 'gainReference',
//                 value: oldGainRef
//             },
//             id: create_UUID()
//         }
//     }
//     //apply gainReferenceChange
//     playlist.gainReference = newGainRef

//     const operations = []
//     for (const [customClass, data] of Object.entries(autoLevelInfo)) {
//         if(data.volumeChangeOperation)
//             operations.unshift(data.volumeChangeOperation)
//     }
//     multiVolumeChangeOperation(operations, gainRefOperation)
//     updateOperationSpace()
// }

// function calculateNewGainRef() {
//     // take max of all gain
//     // and return that as the new reference
//     let gainReference = playlist.gainReference
//     playlist.customClasses.forEach(customClass => {
//         if(customClass.gain > gainReference)
//             gainReference = customClass.gain
//     })
//     return gainReference
// }

// function publishProject(event, apiProvider = "") {
//     isNativeExport = false
//     if(apiProvider === '' && 'btn-invoke-headliner' === event.target.id) apiProvider = apiProviderHeadliner
//     if (event) event.preventDefault()
//     const format = getFormat()
//     const filename = document.getElementById('exportprojectname').innerText.trim().replaceAll(' ', '_')
//     const bitDepthorRate = parseInt(document.getElementById(format + '-bit-depth').getAttribute('value'))
//     const sampleRate = document.getElementById(format + '-sample-rate').value
//     const channels = document.getElementById(format + '-channel').value
//     const normalize = $('#normalize-checkbox-uo').hasClass('checked')
//     const options = {
//         format,
//         filename,
//         sampleRate: parseInt(sampleRate),
//         channels: parseInt(channels),
//         normalize
//     }
//     selectedFileName = filename
//     selectedFileFormat = format
//     if (format === 'wav') options.bitDepth = bitDepthorRate
//     else options.bitRate = bitDepthorRate
//     if (apiProviderPodbean === apiProvider) {
//         if (document.getElementById('wav').checked) options.format = 'mp3'
//     }
//     else options.format = 'wav'
//     downloadFromURL ('', options)
//     /*if(apiProviderHeadliner === apiProvider) downloadFromURL ('', options)
//     else ee.emit('startaudiorendering', options)*/
// }

export async function getSizeOfTheProject() {
  const format = getFormat();
  const filename = document
    .getElementById("exportprojectname")
    .innerText.trim()
    .replaceAll(" ", "_");
  const bitDepthorRate = parseInt(
    document.getElementById(format + "-bit-depth").getAttribute("value"),
  );
  const sampleRate = document.getElementById(format + "-sample-rate").value;
  const channels = document.getElementById(format + "-channel").value;
  const normalize = $("#normalize-checkbox-uo").hasClass("checked");
  const options = {
    format,
    filename,
    sampleRate: parseInt(sampleRate),
    channels: parseInt(channels),
    normalize,
  };
  if (format === "wav") options.bitDepth = bitDepthorRate;
  else options.bitRate = bitDepthorRate;
  let fileSize = await calculateFileSize(options);
  let str = "";
  if (fileSize >= 1) {
    str = "File size: " + fileSize.toFixed(2) + " MB (approx.)";
  } else {
    str = "File size: " + (fileSize * 1024).toFixed(2) + " KB (approx.)";
  }
  document.getElementById("project-size").innerHTML = str;
}
export function openDropdown(ddOpt) {
  if (document.getElementById("dd-items")) {
    document.getElementById("dd-items").remove();
  }
  $(".dropdown-format-options").css("display", "block");

  if (ddOpt === "wav-bit-depth") {
    let wav = document.getElementById("wav-d11");
    let wavSelectedParent = document.getElementById("wav-bit-depth-parent");
    let wavSelected = document.getElementById("wav-bit-depth");

    wavSelectedParent.style.display = "none";

    let dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items");
    dropDownItems.id = "dd-items";

    let dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "8bit (int)";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "wav_110";
    dropItem1.value = "8";
    dropItem1.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem1.value,
      );
    };

    let dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "16bit (int)";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "wav_11";
    dropItem2.value = "16";
    dropItem2.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem2.value,
      );
    };

    let dropItem3 = document.createElement("li");
    dropItem3.innerHTML = "32bit (float)";
    dropItem3.classList.add("dd-item");
    dropItem3.id = "wav_13";
    dropItem3.value = "32";
    dropItem3.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem3.value,
      );
    };

    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    dropDownItems.appendChild(dropItem3);
    wav.appendChild(dropDownItems);

    dropDownItems.style.display = "block";
  } else if (ddOpt === "wav-sample-rate") {
    let wav = document.getElementById("wav-d12");
    let wavSelectedParent = document.getElementById("wav-sample-rate-parent");
    let wavSelected = document.getElementById("wav-sample-rate");

    wavSelectedParent.style.display = "none";

    let dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items");
    dropDownItems.id = "dd-items";

    let dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "32khz";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "wav_21";
    dropItem1.value = "32000";
    dropItem1.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem1.value,
      );
    };

    let dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "44.1khz";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "wav_22";
    dropItem2.value = "44100";
    dropItem2.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem2.value,
      );
    };

    let dropItem3 = document.createElement("li");
    dropItem3.innerHTML = "48khz";
    dropItem3.classList.add("dd-item");
    dropItem3.id = "wav_23";
    dropItem3.value = "48000";
    dropItem3.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem3.value,
      );
    };

    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    dropDownItems.appendChild(dropItem3);
    wav.appendChild(dropDownItems);
    dropDownItems.style.display = "block";
  } else if (ddOpt === "wav-channel") {
    let wav = document.getElementById("wav-d13");
    let wavSelectedParent = document.getElementById("wav-channels-parent");
    let wavSelected = document.getElementById("wav-channel");

    wavSelectedParent.style.display = "none";

    let dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items");
    dropDownItems.id = "dd-items";

    let dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "mono (1)";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "wav_31";
    dropItem1.value = "1";
    dropItem1.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem1.value,
      );
    };

    let dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "stereo (2)";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "wav_32";
    dropItem2.value = "2";
    dropItem2.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem2.value,
      );
    };

    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    wav.appendChild(dropDownItems);

    dropDownItems.style.display = "block";
  } else if (ddOpt === "mp3-bit-depth") {
    let wav = document.getElementById("mp3-d21");
    let wavSelectedParent = document.getElementById("mp3-bit-depth-parent");
    let wavSelected = document.getElementById("mp3-bit-depth");

    wavSelectedParent.style.display = "none";

    let dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items");
    dropDownItems.id = "dd-items";

    let dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "128kbps";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "mp3_11";
    dropItem1.value = "128";
    dropItem1.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem1.value,
      );
    };

    let dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "160kbps";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "mp3_12";
    dropItem2.value = "160";
    dropItem2.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem2.value,
      );
    };

    let dropItem3 = document.createElement("li");
    dropItem3.innerHTML = "192kbps";
    dropItem3.classList.add("dd-item");
    dropItem3.id = "mp3_13";
    dropItem3.value = "192";
    dropItem3.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem3.value,
      );
    };

    let dropItem4 = document.createElement("li");
    dropItem4.innerHTML = "256kbps";
    dropItem4.classList.add("dd-item");
    dropItem4.id = "mp3_14";
    dropItem4.value = "256";
    dropItem4.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem4.value,
      );
    };

    let dropItem5 = document.createElement("li");
    dropItem5.innerHTML = "320kbps";
    dropItem5.classList.add("dd-item");
    dropItem5.id = "mp3_15";
    dropItem5.value = "320";
    dropItem5.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem5.value,
      );
    };

    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    dropDownItems.appendChild(dropItem3);
    dropDownItems.appendChild(dropItem4);
    dropDownItems.appendChild(dropItem5);
    wav.appendChild(dropDownItems);

    dropDownItems.style.display = "block";
  } else if (ddOpt === "mp3-sample-rate") {
    let wav = document.getElementById("mp3-d22");
    let wavSelectedParent = document.getElementById("mp3-sample-rate-parent");
    let wavSelected = document.getElementById("mp3-sample-rate");

    wavSelectedParent.style.display = "none";

    let dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items");
    dropDownItems.id = "dd-items";

    let dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "32khz";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "mp3_21";
    dropItem1.value = "32000";
    dropItem1.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem1.value,
      );
    };

    let dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "44.1khz";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "mp3_22";
    dropItem2.value = "44100";
    dropItem2.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem2.value,
      );
    };

    let dropItem3 = document.createElement("li");
    dropItem3.innerHTML = "48khz";
    dropItem3.classList.add("dd-item");
    dropItem3.id = "mp3_23";
    dropItem3.value = "48000";
    dropItem3.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem3.value,
      );
    };

    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    dropDownItems.appendChild(dropItem3);
    wav.appendChild(dropDownItems);

    dropDownItems.style.display = "block";
  } else if (ddOpt === "mp3-channel") {
    let wav = document.getElementById("mp3-d23");
    let wavSelectedParent = document.getElementById("mp3-channel-parent");
    let wavSelected = document.getElementById("mp3-channel");

    wavSelectedParent.style.display = "none";

    let dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items");
    dropDownItems.id = "dd-items";

    let dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "mono (1)";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "mp3_31";
    dropItem1.value = "1";
    dropItem1.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem1.value,
      );
    };

    let dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "stereo (2)";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "mp3_32";
    dropItem2.value = "2";
    dropItem2.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem2.value,
      );
    };

    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    wav.appendChild(dropDownItems);

    dropDownItems.style.display = "block";
  } else if (ddOpt === "m4a-bit-depth") {
    let wav = document.getElementById("m4a-d31");
    let wavSelectedParent = document.getElementById("m4a-bit-depth-parent");
    let wavSelected = document.getElementById("m4a-bit-depth");

    wavSelectedParent.style.display = "none";

    let dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items");
    dropDownItems.id = "dd-items";

    let dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "128kbps";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "m4a_11";
    dropItem1.value = "128";
    dropItem1.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem1.value,
      );
    };

    let dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "160kbps";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "m4a_12";
    dropItem2.value = "160";
    dropItem2.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem2.value,
      );
    };

    let dropItem3 = document.createElement("li");
    dropItem3.innerHTML = "192kbps";
    dropItem3.classList.add("dd-item");
    dropItem3.id = "m4a_13";
    dropItem3.value = "192";
    dropItem3.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem3.value,
      );
    };

    let dropItem4 = document.createElement("li");
    dropItem4.innerHTML = "256kbps";
    dropItem4.classList.add("dd-item");
    dropItem4.id = "m4a_14";
    dropItem4.value = "256";
    dropItem4.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem4.value,
      );
    };

    let dropItem5 = document.createElement("li");
    dropItem5.innerHTML = "320kbps";
    dropItem5.classList.add("dd-item");
    dropItem5.id = "m4a_15";
    dropItem5.value = "320";
    dropItem5.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem5.value,
      );
    };

    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    dropDownItems.appendChild(dropItem3);
    dropDownItems.appendChild(dropItem4);
    dropDownItems.appendChild(dropItem5);
    wav.appendChild(dropDownItems);

    dropDownItems.style.display = "block";
  } else if (ddOpt === "m4a-sample-rate") {
    let wav = document.getElementById("m4a-d32");
    let wavSelectedParent = document.getElementById("m4a-sample-rate-parent");
    let wavSelected = document.getElementById("m4a-sample-rate");

    wavSelectedParent.style.display = "none";

    let dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items");
    dropDownItems.id = "dd-items";

    let dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "32khz";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "m4a_21";
    dropItem1.value = "32000";
    dropItem1.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem1.value,
      );
    };

    let dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "44.1khz";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "m4a_22";
    dropItem2.value = "44100";
    dropItem2.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem2.value,
      );
    };

    let dropItem3 = document.createElement("li");
    dropItem3.innerHTML = "48khz";
    dropItem3.classList.add("dd-item");
    dropItem3.id = "m4a_23";
    dropItem3.value = "48000";
    dropItem3.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem3.value,
      );
    };

    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    dropDownItems.appendChild(dropItem3);
    wav.appendChild(dropDownItems);

    dropDownItems.style.display = "block";
  } else if (ddOpt === "m4a-channel") {
    let wav = document.getElementById("m4a-d33");
    let wavSelectedParent = document.getElementById("m4a-channel-parent");
    let wavSelected = document.getElementById("m4a-channel");

    wavSelectedParent.style.display = "none";

    let dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items");
    dropDownItems.id = "dd-items";

    let dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "mono (1)";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "m4a_31";
    dropItem1.value = "1";
    dropItem1.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem1.value,
      );
    };

    let dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "stereo (2)";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "m4a_32";
    dropItem2.value = "2";
    dropItem2.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem2.value,
      );
    };

    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    wav.appendChild(dropDownItems);

    dropDownItems.style.display = "block";
  } else if (ddOpt === "export-trans") {
    let wav = document.getElementById("export-transcript-select");
    let wavSelectedParent = document.getElementById("export-trans-parent");
    let wavSelected = document.getElementById("export-trans");

    wavSelectedParent.style.display = "none";

    let dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items", "export-transcript-options");
    dropDownItems.id = "dd-items";

    let dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "SRT (SubRip Titles)";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "srt";
    dropItem1.value = "srt";
    dropItem1.style.opacity = "0.5";
    dropItem1.style.cursor = "not-allowed";
    dropItem1.onclick = function () {};

    let dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "VTT Web Video Text Track";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "vtt";
    dropItem2.value = "vtt";
    dropItem2.style.opacity = "0.5";
    dropItem2.style.cursor = "not-allowed";
    dropItem2.onclick = function () {};

    let dropItem3 = document.createElement("li");
    dropItem3.innerHTML = "TXT (Text Document)";
    dropItem3.classList.add("dd-item");
    dropItem3.id = "txt";
    dropItem3.value = "txt";
    dropItem3.style.opacity = "0.5";
    dropItem3.style.cursor = "not-allowed";
    dropItem3.onclick = function () {};

    let dropItem4 = document.createElement("li");
    dropItem4.innerHTML = "PDF (PDF Document)";
    dropItem4.classList.add("dd-item");
    dropItem4.id = "pdf";
    dropItem4.value = "pdf";
    dropItem4.onclick = function () {
      selectItem(
        this.id,
        ddOpt,
        wavSelectedParent,
        wavSelected,
        dropItem4.value,
      );
    };

    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    dropDownItems.appendChild(dropItem3);
    dropDownItems.appendChild(dropItem4);
    wav.appendChild(dropDownItems);

    dropDownItems.style.display = "block";
  } else if (ddOpt === "share-modes") {
    const selectGrandParent = document.getElementById(`${ddOpt}-grandparent`);
    const selectedParent = document.getElementById(`${ddOpt}-parent`);
    const selected = document.getElementById(ddOpt);

    selectedParent.style.display = "none";

    const dropDownItems = document.createElement("ul");
    dropDownItems.classList.add("dd-items-alt", `${ddOpt}-options`);
    dropDownItems.id = "dd-items";

    const dropItem1 = document.createElement("li");
    dropItem1.innerHTML = "View";
    dropItem1.classList.add("dd-item");
    dropItem1.id = "view";
    dropItem1.value = "view";
    dropItem1.onclick = function () {
      selectItem(this.id, ddOpt, selectedParent, selected, dropItem1.value);
    };
    const dropItem2 = document.createElement("li");
    dropItem2.innerHTML = "Edit";
    dropItem2.classList.add("dd-item");
    dropItem2.id = "edit";
    dropItem2.value = "edit";
    dropItem2.onclick = function () {
      selectItem(this.id, ddOpt, selectedParent, selected, dropItem2.value);
    };
    dropDownItems.appendChild(dropItem1);
    dropDownItems.appendChild(dropItem2);
    selectGrandParent.appendChild(dropDownItems);
    dropDownItems.style.display = "block";
  }

  // const optionsSubMenusLeft =
  //   ((230 / screenWidth) * 100 * window.innerWidth) / 100;
  // const optionsSubMenusLeftAlt =
  //   ((140 / screenWidth) * 100 * window.innerWidth) / 100;
  // const dropdownTextIndent =
  //   ((18 / screenWidth) * 100 * window.innerWidth) / 100;
  // const dropdownItemsPadding =
  //   ((3 / screenWidth) * 100 * window.innerWidth) / 100;

  // $("#dd-items").css({
  //   "margin-left": optionsSubMenusLeft,
  //   width: optionsSubMenusLeft,
  //   "text-indent": dropdownTextIndent,
  // });
  // $(".dd-items-alt").css({
  //   "margin-left": 0,
  //   width: optionsSubMenusLeftAlt,
  //   "text-indent": dropdownTextIndent,
  // });
  // $(".dd-selected").css({
  //   width: optionsSubMenusLeft,
  //   "text-indent": dropdownTextIndent,
  // });
  // $(".dd-selected-alt").css({
  //   width: optionsSubMenusLeftAlt,
  //   "text-indent": dropdownTextIndent,
  // });
  // $(".dd-items li").css({
  //   "padding-top": dropdownItemsPadding,
  //   "padding-bottom": dropdownItemsPadding,
  // });
}

export async function calculateFileSize(options) {
  let bitDepth = options.format === "wav" ? options.bitDepth : options.bitRate;
  let fileSize = 0;
  let props = store.getState().propsSlice.props;
  let duration = props.wavesurferObj.getDuration();
  if (options.format !== "wav") {
    // bitDepth = bitDepth / options.sampleRate
    fileSize = (bitDepth * duration) / (8 * 1024);
  } else {
    fileSize =
      (bitDepth * options.sampleRate * duration * options.channels) / 8000000;
  }
  return Promise.resolve(fileSize);
}

function selectItem(item, select, wavSelectedParent, wavSelected, value) {
  let dropDownItems = document.getElementById("dd-items");
  let selectedItem = document.getElementById(select);

  wavSelectedParent.style.display = "block";
  wavSelected.style.display = "block";
  //<------Implementation of 2929---start----->
  if (select === "wav-bit-depth" || select === "wav-channel")
    selectedItem.innerHTML = `${document.getElementById(item).innerText} <i class="dropdown-arrow-eq fas fa-caret-down"></i>`;

  if (select === "wav-sample-rate") {
    selectedItem.innerHTML = `${document.getElementById(item).innerText} <i class="dropdown-arrow-uneq fas fa-caret-down"></i>`;
  }
  if (select === "share-modes") {
    selectedItem.innerHTML = `${document.getElementById(item).innerText} <i class="fas fa-caret-down" style="position:absolute; top:22%; right:9%;"></i>`;
  }
  if (select === "m4a-channel") {
    selectedItem.innerHTML = `${document.getElementById(item).innerText} <i class="fas fa-caret-down" style="position:absolute; top:22%; right:9%;"></i>`;
  }
  //selectedItem.innerText = document.getElementById(item).innerText
  //code commented for dropdown arrow icon-2929 fix
  //<------Implementation of 2929---end----->
  dropDownItems.remove();
  selectedItem.value = value;
  // getSizeOfTheProject()
}

// function shareOptionsPrev() {
//     sendLog({
//         activity: 'click',
//         target: 'open share tab',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })

//     if (document.getElementById('share').style.display === 'none') document.getElementById('shareEmailListMsg').value = ''

//     document.getElementById('export').style.display = 'none'
//     document.getElementById('share').style.display = 'block'
//     document.getElementById('delete').style.display = 'none'
//     document.getElementById('history').style.display = 'none'
//     //document.getElementById('aiAnalytics').style.display = 'none'
//     //document.getElementById('show-notes-icon').style.opacity = '0.5'
//     document.getElementById('share-icon').style.cssText = 'opacity:1 !important'
//     document.getElementById('export-icon').style.opacity = '0.5'
//     document.getElementById('delete-icon').style.opacity = '0.5'
//     document.getElementById('history-icon').style.opacity = '0.5'
// }

// function closeShareModal() {
//     closeMoreOptions()
//     document.getElementById('annotmodal').style.display = 'none'
//     document.getElementById('modal_3').style.display = 'none'
// }

// async function selectUser(username, email) {
//     // var projectname = document.getElementById("projectname").innerText
//     document.getElementById('shareEmailListMsg').value = username
//     document.getElementById('shareEmailListMsg').setAttribute('isVerified', true)
//     document.getElementById('shareEmailListMsg').setAttribute('email', email)

//     // document.getElementById('share-project').setAttribute('onclick', 'shareProject("' + jobname + '", "' + username + '", "' + email + '", "' + projectname +'")')

//     clearSuggestions()
//     suggestionListClose()
// }

export function shareInputKeyDown() {
  document
    .getElementById("shareEmailListMsg")
    .setAttribute("isVerified", false);
}

export async function selectUserName() {
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  const jobname = resultStat.jobname;
  const permResponse = ACM.checkPermission("share-collaboration");

  if (!permResponse.success) {
    showMessage(
      permResponse.message,
      "Share and collaboration is not available in the free plan",
      2000,
      "error",
      "more-options",
    );
    return;
  }
  if (store.getState().viewEditMode.mode === "view") {
    showMessage(
      "Error",
      "Share and collaboration is not available in the View mode",
      2000,
      "error",
      "more-options",
    );
    return;
  }
  if (!(await checkFileConversionStatus(jobname))) {
    showMessage(
      "Share and collaboration is not available currently",
      "File conversion in progress. Please, try after some time",
      2000,
      "error",
      "more-options",
    );
    // return;
  }

  // // Add logic for React state management
  // setShareProjectActive(true);
  // setShowTopBar(true);
  // animateTopBar();
  document.getElementById("share-project").classList.add("btn-active");

  let isVerified = document
    .getElementById("shareEmailListMsg")
    .getAttribute("isVerified");
  let userEmail = document
    .getElementById("shareEmailListMsg")
    .value.toLowerCase();

  if (userEmail === Koolioai.email || userEmail === Koolioai.username) {
    showMessage(
      "Invalid request",
      "Email or username cannot be same as yours!",
      2000,
      "error",
      "more-options",
    );
    return;
  }

  if (isVerified === "false") {
    if (userEmail !== null && userEmail !== "") {
      if (!validateEmail(userEmail)) {
        showMessage(
          "Invalid email address",
          "please check your email address",
          2000,
          "error",
          "more-options",
        );
        return;
      }
      const projectname = document.getElementById("projectname").innerText;
      shareProject(jobname, null, userEmail, projectname);
      getSharedUser();
      showStatText("updating collaborator list...");

      if (store.getState().viewEditMode.mode === "edit")
        lockFeatureForOtherCollaborators(editFeatureTitle);

      setTimeout(() => {
        checkIsTheProjectShared();
      }, 7000);

      setTimeout(() => {
        shareOptions();
      }, 10000);
    }
  } else {
    let username = document.getElementById("shareEmailListMsg").value;
    let email = document
      .getElementById("shareEmailListMsg")
      .getAttribute("email");
    const projectname = document.getElementById("projectname").innerText;
    shareProject(jobname, username, email, projectname);
    getSharedUser();
    showStatText("updating collaborator list...");

    if (store.getState().viewEditMode.mode === "edit")
      lockFeatureForOtherCollaborators(editFeatureTitle);

    setTimeout(() => {
      checkIsTheProjectShared();
    }, 7000);

    setTimeout(() => {
      shareOptions();
    }, 10000);
  }
  document
    .getElementById("shareEmailListMsg")
    .setAttribute("isVerified", false);
  document.getElementById("shareEmailListMsg").setAttribute("email", null);
}

export function setSharedUserList() {
  let susers = [];
  susers = listofSharedUser;
  susers = susers.map((u) => [
    u.username,
    u.email,
    u.username + " (" + u.email + ")",
  ]);
  const inputVal = document.getElementById("shareEmailListMsg");
  inputVal.onkeyup = function () {
    if (inputVal.value.length === 0) {
      suggestionListClose();
    } else {
      suggestionListClose();
      clearSuggestions();
      let suggestions = susers.filter((user) =>
        user[2].includes(inputVal.value),
      );
      suggestions.forEach((user) => {
        if (user[0] && user[1]) {
          let li = document.createElement("li");
          let text = document.createTextNode(user[2]);
          li.appendChild(text);
          li.setAttribute(
            "onclick",
            "selectUser('" + user[0] + "', '" + user[1] + "')",
          );
          document.getElementById("searchSuggestionList").appendChild(li);
        }
      });
      if (suggestions.length > 0)
        document.getElementById("suggestionList").style.cssText =
          "visibility: visible";
    }
  };
}

function clearSuggestions() {
  var suggestionList = document.querySelectorAll("#searchSuggestionList > li");
  suggestionList.forEach(function (item) {
    item.parentNode.removeChild(item);
  });
}

function suggestionListClose() {
  clearSuggestions();
  document.getElementById("suggestionList").style.cssText =
    "visibility: hidden";
}

// // Delete project
// function deleteOptions() {
//     sendLog({
//         activity: 'click',
//         target: 'open delete tab',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })
//     document.getElementById('export').style.display = 'none'
//     document.getElementById('share').style.display = 'none'
//     document.getElementById('delete').style.display = 'block'
//     document.getElementById('history').style.display = 'none'
//     //document.getElementById('aiAnalytics').style.display = 'none'
//     //document.getElementById('show-notes-icon').style.opacity = '0.5'
//     document.getElementById('delete-icon').style.cssText = 'opacity:1 !important'
//     document.getElementById('export-icon').style.opacity = '0.5'
//     document.getElementById('share-icon').style.opacity = '0.5'
//     document.getElementById('history-icon').style.opacity = '0.5'
// }

// // History

// function loadPrevHistoryPage(){
//     if ($('#history-page-arrow-left').css('opacity') != 1) return

//     const permResponse = ACM.checkPermission('restore')
//     if (!permResponse.success)
//         return showMessage(permResponse.message, 'History restore is not available in the free plan', 2000, 'error', 'more-options')

//     const count =  (parseInt($(".pagination").find('.btn-active')[0].innerText.split('-')[1])/20)-1
//     historyOptions((count-1)*20, count-1)
// }

// function loadNextHistoryPage(){
//     if ($('#history-page-arrow-right').css('opacity') != 1) return

//     const permResponse = ACM.checkPermission('restore')
//     if (!permResponse.success)
//         return showMessage(permResponse.message, 'History restore is not available in the free plan', 2000, 'error', 'more-options')

//     const count =  (parseInt($(".pagination").find('.btn-active')[0].innerText.split('-')[1])/20)-1
//     historyOptions((count+1)*20, count+1)
// }

// async function historyOptions(offset, target) {
//     if (!(transcriptionCompleted)) {
//         moreOptionsAlert('transcription in progress, operation disabled')
//         return
//     }
//     $('#history-main').empty()
//     $("#historyPageContainer").empty()
//     $('#history-loading').text('Loading history...')
//     $('#history-page-arrow-left').css('visibility', 'hidden')
//     $('#history-page-arrow-right').css('visibility', 'hidden')
//     sendLog({
//         activity: 'click',
//         target: 'open history tab',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })
//     topbar.show();
//     (function step() {
//         setTimeout(function () {
//             if (topbar.progress('+.01') < 1) step()
//         }, 30)
//     })()
//     fetchHistoryData(offset, target).then(() => {
//         $('#history-loading').text('')
//         $('#history-main').css({'display': ''})
//         topbar.hide()
//     })
//     document.getElementById('export').style.display = 'none'
//     document.getElementById('share').style.display = 'none'
//     document.getElementById('delete').style.display = 'none'
//     document.getElementById('history').style.display = 'block'
//     //document.getElementById('aiAnalytics').style.display = 'none'
//     //document.getElementById('show-notes-icon').style.opacity = '0.5'
//     document.getElementById('delete-icon').style.opacity = '0.5'
//     document.getElementById('export-icon').style.opacity = '0.5'
//     document.getElementById('share-icon').style.opacity = '0.5'
//     document.getElementById('history-icon').style.cssText = 'opacity:1 !important'
// }

export async function shareOptions() {
  // if (!(transcriptionCompleted)) {
  //     moreOptionsAlert('transcription in progress, operation disabled')
  //     return
  // }
  const shareHistoryMain = document.getElementById("share-history-main");
  if (shareHistoryMain) {
    while (shareHistoryMain.firstChild) {
      shareHistoryMain.removeChild(shareHistoryMain.firstChild);
    }
  }
  const shareHistoryLoading = document.getElementById("share-history-loading");
  if (shareHistoryLoading) {
    shareHistoryLoading.textContent = "Loading collaborators...";
  }
  const jobname = store.getState().projectData.resultStat.jobname;
  sendLog({
    activity: "click",
    target: "open share tab",
    timeStamp: new Date().toISOString(),
    jobname,
    status: "success",
    statusMsg: "success",
  });
  // topbar.show();
  // (function step() {
  //     setTimeout(function () {
  //         if (topbar.progress('+.01') < 1) step()
  //     }, 30)
  // })()
  await fetchShareholdersData().then(() => {
    if (document.getElementById("share-history-loading"))
      document.getElementById("share-history-loading").innerText = "";
    // topbar.hide()
  });
  // document.getElementById('export').style.display = 'none'
  // document.getElementById('history').style.display = 'none'
  // document.getElementById('delete').style.display = 'none'
  // document.getElementById('share').style.display = 'block'
  //document.getElementById('aiAnalytics').style.display = 'none'
  //document.getElementById('show-notes-icon').style.opacity = '0.5'
  // document.getElementById('delete-icon').style.opacity = '0.5'
  // document.getElementById('export-icon').style.opacity = '0.5'
  // document.getElementById('history-icon').style.opacity = '0.5'
  // document.getElementById('share-icon').style.cssText = 'opacity:1 !important'
}

// async function fetchHistoryData(offset,target=0) {
//     $('#history-main').css({'display':'none'})
//     let data = await getHistory(offset)
//     for (let i = 0; i < data.operations.length; i++) {
//         let historyData = document.createElement('div')
//         historyData.classList.add('history-version', 'col-12', 'd-flex', 'text-center', 'align-items-center')

//         let creators = document.createElement('div')
//         creators.classList.add('history-data', 'col-3')
//         let creatorsData = document.createElement('img')
//         creatorsData.classList.add('user-profile-border')
//         creatorsData.setAttribute('id', 'history-user-profile')

//         // Used '/get-email' api to fetch user email who did
//         // operations on workspace (prominently used if have collaboraters)
//         // providing username as parameter and getting email & username as response
//         let user_email
//         const url = _config.api + '/get-email'
//         const params = {
//             username: data.operations[i].created_by
//         }
//         try {
//             const response = await axios.get(url, {
//                 params,
//                 headers: {
//                     'Content-Type': 'application/json',
//                 }
//             })

//             if (response.status === 200) {
//                 user_email = response.data.email
//             }
//         }catch (error) {
//             return
//         }
//         creatorsData.src = 'https://ui-avatars.com/api/?name=' + user_email + '&background=181818&color=fff&rounded=true&size=28'
//         creators.appendChild(creatorsData)

//         let timestamp = document.createElement('div')
//         timestamp.classList.add('history-data', 'col-3')
//         let timeData = document.createTextNode(new Date(data.operations[i].created_at).toLocaleString())
//         timestamp.appendChild(timeData)

//         let operations = document.createElement('div')
//         operations.classList.add('history-data', 'col-3')
//         let opsData = document.createTextNode(customOperationTitle(data.operations[i].operation.worker))
//         operations.appendChild(opsData)

//         let action = document.createElement('div')
//         let actionData = document.createElement('img')
//         actionData.classList.add('view-history-icon')
//         actionData.setAttribute('id', 'view-history')
//         actionData.src = '/static/img/view-history.png'
//         action.appendChild(actionData)

//         if (i === 0 || playlist.mode === 'view') {
//             action.classList.add('history-data', 'col-3')
//             actionData.classList.add('restore-disabled')
//             if (i === 0 && offset ===0) {
//                 actionData.title = 'Current Version'
//             }
//             else {
//                 actionData.title = 'Only available in Edit Mode'
//             }
//             actionData.setAttribute('data-bs-placement', 'bottom')
//             new bootstrap.Tooltip(actionData)
//         } else {
//             action.classList.add('history-data', 'col-3')
//             actionData.classList.add('restore-enabled')
//         }
//         actionData.onclick = async () => {
//             const permResponse = ACM.checkPermission('restore')
//             if (!permResponse.success)
//                 return showMessage(permResponse.message, 'History restore is not available in the free plan', 2000, 'error', 'more-options')
//             if(!await checkFileConversionStatus(playlist.jobname)){
//                 return showMessage("History restore is not available currently", 'File conversion in progress. Please, try after some time', 2000, 'error', 'more-options')
//             }
//             const isTranscriptionInProgress = checkIfTranscriptionInProgressFromPlaylist()
//             if(isTranscriptionInProgress) return showMessage('Please wait', 'Transcription is in progress', 2000, 'error', 'more-options')
//             if (actionData.classList.contains('restore-enabled')) {
//                 currentJobHead = playlist.jobhead
//                 if(isTranscriptDrivenEdit) {
//                     let spkSliderWidth = document.getElementById("speakers-sliders").clientWidth
//                     let spkSlider = document.getElementById("speakers-sliders").parentElement.removeChild(document.getElementById("speakers-sliders"))
//                     let sfxSlider = document.getElementById("sfx-slider-cont").parentElement.removeChild(document.getElementById("sfx-slider-cont"))
//                     document.getElementById("add-spk-sfx").parentElement.insertBefore(spkSlider, document.getElementById("add-spk-sfx"))
//                     $("#speakers-sliders").css({"top":""})
//                     speakersSegmentHeight = isTranscriptDrivenEdit? 0 : ((waveHeight / 2) - (((((31 / screenWidth)) * window.innerWidth)) / 2))
//                     $("#speakers-sliders").css({"top":speakersSegmentHeight, "width":"60%"})
//                     document.getElementById("add-spk-sfx").parentElement.insertBefore(sfxSlider, document.getElementById("add-spk-sfx").nextSibling)

//                     sfxSegmentHeight = ((waveHeight / 2) + addSpkHeight)
//                     $("#sfx-slider-cont").css({"top":sfxSegmentHeight,"width":""})
//                     $("#transcriptToggler").attr("src","static/img/workspace/controls/toggle-off-solid.svg")
//                     closeTranscriptLoader()
//                 }
//                 $('#koolio_workspace').css('visibility', 'hidden')
//                 $('#control-mode').css('visibility', 'hidden')

//                 // hides fade sub-menu
//                 if (document.getElementById('btn-waveform-selector').classList.contains('waveform-enable')) {
//                     document.getElementById('btn-waveform-selector').classList.replace('waveform-enable', 'waveform-disable')
//                     document.getElementById('waveforms-sub-menu').style.display = 'none'
//                     $(fadeCont).empty()
//                     fadeCont.appendChild(fadeIcon)
//                     waveformsSelector = document.getElementById('waveforms-selector')
//                     waveformsSelector.style.width = ((((23 / screenWidth) * 100) * window.innerWidth) / 100) + 'px'
//                     waveformsSelector.style.height = ((((21.5 / screenWidth) * 100) * window.innerWidth) / 100) + 'px'
//                     document.getElementById('waveforms-selector').title = 'fades'
//                     resetFades()
//                 }
//                 document.getElementById('track-controls').style.display = 'none'
//                 document.getElementById('status-disp').style.display = 'flex'
//                 // message = 'loading workspace preview'
//                 // $('#status-disp  span.status').text(message);
//                 createVanta()
//                 constantProgress(100, 'Loading Your Workspace')
//                 // document.getElementById("progress-content").innerHTML='Loading Workspace'
//                 document.getElementById('progress-detail').innerHTML = 'Please wait...'
//                 $('#undoBtn').css('display', 'none')
//                 $('#redoBtn').css('display', 'none')
//                 viewControls()
//                 audioPlayFlag=false
//                 restoreHistory(data.operations[i].refid, true)
//                     .then(() => {
//                         $('#koolio_workspace').css('visibility', 'visible')
//                         $('#control-mode').css('visibility', 'visible')
//                         document.getElementById('track-controls').style.display = 'block'
//                         ee.emit('removeMoreOptions')
//                         document.getElementById('status-disp').style.display = 'flex'
//                         message = ''
//                         $('#status-disp  span.status').text(message)
//                         destroyProgressBar()
//                         destroyVanta()
//                         enableHistoryOptions(true)
//                     })
//                     //.catch(e=> console.log(e))
//                 // eslint-disable-next-line no-dupe-else-if
//             } else if (actionData.classList.contains('restore-enabled') && playlist.mode === 'view') {
//                 document.getElementById('more-options-menu').style.display = 'none'
//                 showStatText('restore not permitted in view mode')
//             } else if (actionData.classList.contains('restore-disabled') && playlist.mode === 'edit') {
//                 moreOptionsAlert('this operation cannot be restored')
//             } else {
//                 moreOptionsAlert('restore not permitted in view mode')
//             }
//         }

//         historyData.appendChild(creators)
//         historyData.appendChild(timestamp)
//         historyData.appendChild(operations)
//         historyData.appendChild(action)

//         document.getElementById('history-main').appendChild(historyData)
//     }

//     const pages= Math.ceil(data.total_number_of_records/20)

//     if(pages>1){
//         $('#history-page-arrow-left').css('visibility', 'visible')
//         $('#history-page-arrow-right').css('visibility', 'visible')
//         if(target === 0){
//             $('#history-page-arrow-left').css({ opacity: '0.2', cursor: 'not-allowed' })
//             $('#history-page-arrow-right').css({ opacity: '1', cursor: 'pointer' })
//         }
//         else if(target === pages-1){
//             $('#history-page-arrow-left').css({ opacity: '1', cursor: 'pointer' })
//             $('#history-page-arrow-right').css({ opacity: '0.2', cursor: 'not-allowed' })
//         }
//         else {
//             $('#history-page-arrow-left').css({ opacity: '1', cursor: 'pointer' })
//             $('#history-page-arrow-right').css({ opacity: '1', cursor: 'pointer' })
//         }
//     }

//     if(pages>0){
//         for(let i = 0; i<pages;i++){
//             const li = document.createElement("li")
//             li.classList.add("page-item")
//             if(target === i) {
//                 li.innerHTML=`<span class="page-link btn-active">${i==0?i+1 :((i)*20)+1}-${(i+1)*20}</span></li>`
//             }
//             else{
//                 li.innerHTML=`<span class="page-link">${i==0?i+1 :((i)*20)+1}-${(i+1)*20}</span></li>`
//             }
//             li.onclick = function(e){
//                 const permResponse = ACM.checkPermission('restore')
//                 if (!permResponse.success)
//                     return showMessage(permResponse.message, 'History restore is not available in the free plan', 2000, 'error', 'more-options')

//                 historyOptions(i*20,i)
//             }
//             document.getElementById("historyPageContainer").appendChild(li)
//         }
//     }

//     if(target<5 && pages>11){
//         document.getElementById("historyPageContainer").childNodes.forEach((c,i)=>{
//             if (i>10) {
//                 return c.classList.add('d-none')
//             }
//         })
//         const liDot = document.createElement("li")
//         liDot.classList.add("page-item")
//         liDot.innerHTML=`<span class="page-link">...</span></li>`

//         const liLast = document.createElement("li")
//         liLast.classList.add("page-item")
//         liLast.innerHTML=`<span class="page-link">${((pages-1)*20)+1}-${(pages)*20}</span></li>`
//         liLast.onclick = function(e){
//             const permResponse = ACM.checkPermission('restore')
//             if (!permResponse.success)
//                 return showMessage(permResponse.message, 'History restore is not available in the free plan', 2000, 'error', 'more-options')
//             historyOptions((pages-1)*20,pages-1)
//         }
//         document.getElementById("historyPageContainer").appendChild(liDot)
//         document.getElementById("historyPageContainer").appendChild(liLast)
//     }
//     else if(target && target>=5){
//         const leftPages = pages - (target +1)
//         if (leftPages>=5) {
//             for(let i= target; i<pages ; i++){
//                 document.getElementById("historyPageContainer").childNodes[i].classList.remove('d-none')
//                 if(i<= target+5){

//                     document.getElementById("historyPageContainer").childNodes[i].classList.add('d-inline-block')
//                 }
//                 else{
//                     document.getElementById("historyPageContainer").childNodes[i].classList.add('d-none')
//                 }

//             }
//             for(let i= target; i>=0 ; i--){
//                 document.getElementById("historyPageContainer").childNodes[i].classList.remove('d-none')
//                 if(i>= target-5){

//                     document.getElementById("historyPageContainer").childNodes[i].classList.add('d-inline-block')
//                 }
//                 else{
//                     document.getElementById("historyPageContainer").childNodes[i].classList.add('d-none')
//                 }

//             }
//             if(leftPages>5){
//                 const liDot = document.createElement("li")
//                 liDot.classList.add("page-item")
//                 liDot.innerHTML=`<span class="page-link">...</span></li>`

//                 const liLast = document.createElement("li")
//                 liLast.classList.add("page-item")
//                 liLast.innerHTML=`<span class="page-link">${((pages-1)*20+1)}-${(pages)*20}</span></li>`
//                 liLast.onclick = function(e){
//                     const permResponse = ACM.checkPermission('restore')
//                     if (!permResponse.success)
//                         return showMessage(permResponse.message, 'History restore is not available in the free plan', 2000, 'error', 'more-options')
//                     historyOptions((pages-1)*20,pages-1)
//                 }
//                 document.getElementById("historyPageContainer").appendChild(liDot)
//                 document.getElementById("historyPageContainer").appendChild(liLast)
//             }
//         }
//         else{
//             for(let i= target; i<pages ; i++){
//                 document.getElementById("historyPageContainer").childNodes[i].classList.remove('d-none')

//                 document.getElementById("historyPageContainer").childNodes[i].classList.add('d-inline-block')

//             }
//             for(let i= target; i>=0 ; i--){
//                 document.getElementById("historyPageContainer").childNodes[i].classList.remove('d-none')
//                 if(i> target-(11-leftPages)){
//                     document.getElementById("historyPageContainer").childNodes[i].classList.add('d-inline-block')
//                 }
//                 else{
//                     document.getElementById("historyPageContainer").childNodes[i].classList.add('d-none')
//                 }
//             }
//         }
//     }

//     koolioRes()
// }

async function fetchShareholdersData() {
  let jobname = store.getState().projectData.resultStat.jobname;
  let data = await getLiveShareholders(jobname, Koolioai.username, "LIST");
  if (document.getElementById("share-history-main"))
    document.getElementById("share-history-main").innerHTML = "";

  let deleteViewIconWidth =
    ((19.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  let deleteViewIconHeight =
    ((21.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";

  if (data.shareholders) {
    for (let i = 0; i < data.shareholders.length; i++) {
      let shareholderUsername = data.shareholders[i];
      let sharingMode = "Owner";
      if (data.shareholders[i].username) {
        shareholderUsername = data.shareholders[i].username;
        sharingMode = "";
      }

      if (Koolioai.username === shareholderUsername) continue;

      //isTheProjectShared = true
      let shareholderData = document.createElement("div");
      shareholderData.classList.add(
        "history-version",
        "col-9",
        "d-flex",
        "text-center",
        "align-items-center",
      );

      let creators = document.createElement("div");
      creators.classList.add("history-data", "col-3");
      let creatorsData = document.createElement("img");
      creatorsData.classList.add("user-profile-border");
      creatorsData.setAttribute("id", "history-user-profile");
      try {
        if (shareholderUsername.startsWith("google")) {
          const url = _config.api + "/get-email";
          const params = {
            username: data.operations[i].created_by,
          };
          const response = axios.get(url, {
            params,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.status === 200) {
            let user_email = response.data.email;
            shareholderUsername = user_email.split("@")[0];
          }
        }
        creatorsData.src =
          "https://ui-avatars.com/api/?name=" +
          shareholderUsername.replaceAll(" ", "+") +
          "&background=181818&color=fff&rounded=true&size=28";
        creatorsData.setAttribute("title", shareholderUsername);
      } catch (e) {
        creatorsData.src =
          "https://ui-avatars.com/api/?name=" +
          data.shareholders[i].email +
          "&background=181818&color=fff&rounded=true&size=28";
        creatorsData.setAttribute("title", data.shareholders[i].email);
      }
      creatorsData.setAttribute("data-bs-placement", "right");
      // new bootstrap.Tooltip(creatorsData)
      creators.appendChild(creatorsData);

      let mode = document.createElement("div");
      mode.classList.add("history-data", "col-3");

      if (data.shareholders[i].mode && data.shareholders[i].mode.length == 2) {
        sharingMode = "Edit";
      } else if (
        data.shareholders[i].mode &&
        data.shareholders[i].mode.length == 1
      ) {
        sharingMode = "View";
      }
      let modeData = document.createTextNode(sharingMode);
      mode.appendChild(modeData);

      let timestamp = document.createElement("div");
      timestamp.classList.add("history-data", "col-3");
      let sharedAt = "";
      if (data.shareholders[i].shared_at)
        sharedAt = new Date(data.shareholders[i].shared_at).toLocaleString();
      let timeData = document.createTextNode(sharedAt);
      timestamp.appendChild(timeData);

      let action = document.createElement("div");
      let actionData = document.createElement("img");
      actionData.classList.add("view-history-icon");
      actionData.setAttribute(
        "id",
        "dissociate-shareholder-" + shareholderUsername,
      );
      actionData.src = "/image/delete.png";
      actionData.title = "delete collaborator";
      actionData.style.width = deleteViewIconWidth;
      actionData.style.height = deleteViewIconHeight;
      action.appendChild(actionData);

      if (i === 0 || store.getState().viewEditMode.mode === "view") {
        action.classList.add("history-data", "col-3");
        actionData.classList.add("restore-disabled");
      } else {
        action.classList.add("history-data", "col-3");
        actionData.classList.add("restore-enabled");
      }

      actionData.addEventListener("click", function () {
        if (
          actionData.classList.contains("restore-enabled") &&
          store.getState().viewEditMode.mode === "view"
        ) {
          document.getElementById("more-options-menu").style.display = "none";
          showStatText("dissociation is not permitted in view mode");
        } else if (actionData.classList.contains("restore-enabled")) {
          let dissociatedUser = data.shareholders[i];
          if (data.shareholders[i].username)
            dissociatedUser = data.shareholders[i].username;
          // later uncomment this one
          showDissociationConfirmationModal(
            jobname,
            dissociatedUser,
            Koolioai.username,
            data.shareholders.length,
          );
          // dissociateProject(jobname, dissociatedUser, Koolioai.username).then(
          //   () => {
          //     shareOptions();
          //   },
          // );
        } else if (
          actionData.classList.contains("restore-disabled") &&
          store.getState().viewEditMode.mode === "edit"
        ) {
          moreOptionsAlert("this collaborator cannot be dissociated");
        } else {
          moreOptionsAlert("dissociation is not permitted in view mode");
        }
      });

      shareholderData.appendChild(creators);
      shareholderData.appendChild(mode);
      shareholderData.appendChild(timestamp);
      shareholderData.appendChild(action);

      document
        .getElementById("share-history-main")
        .appendChild(shareholderData);
    }
  }
}

function showDissociationConfirmationModal(
  jobname,
  dissociatedUser,
  username,
  count,
) {
  store.dispatch(
    ShowProjectModalActions.updateDissociationModal({
      showDissociationModal: true,
      dissociationInfo: { jobname, dissociatedUser, count, username },
    }),
  );
  // $('#half-modals').html(dissociationConfirmationModal)
  // $('#btn-dissociation-confirm').click(async function () {
  //     closeHalfModal()
  //     dissociateProject(jobname, dissociatedUser, username)
  //         .then(() => {
  //             if (count-1 < 2) isTheProjectShared = false
  //             shareOptions ()
  //         })
  // })
  // $('#half-modals').css('visibility', 'visible')
  // koolioModals()
}

// function customOperationTitle(operartionTitle) {
//     switch (operartionTitle) {
//     case 'cutDeleteCustomClass':
//         return 'cut'
//     default:
//         return operartionTitle
//     }
// }

// async function publishProjectModal() {
//     if(ACM.checkAllowedNumberOfPublishingCount() !== -1){
//         const allowed = ACM.checkAllowedNumberOfPublishingCount()
//         const current = await getCurrentFeatureWiseCount("allowed_number_of_publishing")
//         if (allowed <= current) return showMessage('Please upgrade your account', 'You can publish maximum 5 times in the free plan', 2000, 'error', 'more-options')
//     }
//     if(!await checkFileConversionStatus(playlist.jobname)){
//         return showMessage("Publish is not available currently", 'File conversion in progress. Please, try after some time', 2000, 'error', 'more-options')
//     }
//     const dataSynced = await syncOperationsInBackend()
//     if(!dataSynced){
//         return showMessage('Operations synchronizing...', 'Operations synchronization in progress. Please, try after some time', 2000, 'error', 'more-options')
//     }
//     else{
//         $('#half-modals').html(publishingApiModals)
//         koolioModals()
//         $('#half-modals').css('visibility', 'visible')
//     }
// }

// TODO: Make this into JSX
// The .appendChild() requires a Node element, not JSX
const successIcon = document.createElement("img");
successIcon.src = "/img/workspace/success.png";
successIcon.id = "pop-icon";
successIcon.style.position = "absolute";

const errorIcon = document.createElement("img");
errorIcon.src = "/img/workspace/error.png";
errorIcon.id = "pop-icon";
errorIcon.style.position = "absolute";

export function hideMessage() {
  if (
    document.getElementById("pop-up-message").getAttribute("displayOn") ===
    "workspace"
  ) {
    anime({
      targets: "#pop-up-message",
      easing: "easeInExpo",
      translateX: [0, "-120%"],
    });
  } else if (
    document.getElementById("pop-up-message").getAttribute("displayOn") ===
      "more-options" ||
    document.getElementById("pop-up-message").getAttribute("displayOn") ===
      "gen-options" ||
    document.getElementById("pop-up-message").getAttribute("displayOn") ===
      "workspace-transcript"
  ) {
    anime({
      targets: "#pop-up-message",
      easing: "easeInExpo",
      translateX: [0, "120%"],
    });
  }

  toastRes();

  let clearPopupMsg = setTimeout(() => {
    document.getElementById("pop-up-message").style.display = "none";
    $("#pop-up-title").text("");
    $("#pop-up-sub-title").text("");
  }, 1000);

  clearTimeout(clearPopupMsg);
  clearTimeout(closePopUp);
}

let closePopUp;

export function showMessage(
  header,
  subHeader,
  seconds = 7000,
  type = "success",
  displayOn = "workspace",
) {
  if (
    // (displayOn === "workspace" || displayOn === "workspace-transcript") &&
    // document.getElementById("more-options-menu").style.display === "none"
    displayOn === "workspace" ||
    displayOn === "workspace-transcript"
  ) {
    document
      .getElementById("pop-up-message")
      .setAttribute("displayOn", displayOn);
    toastRes();
    anime({
      targets: "#pop-up-message",
      easing: "easeOutExpo",
      translateX: displayOn === "workspace" ? ["-120%", 0] : ["100%", 0],
    });
    clearTimeout(closePopUp);
    closePopUp = setTimeout(() => {
      hideMessage();
    }, seconds);
  } else if (
    // displayOn === "more-options" &&
    // document.getElementById("more-options-menu").style.display === "flex"
    displayOn === "more-options"
  ) {
    document
      .getElementById("pop-up-message")
      .setAttribute("displayOn", "more-options");
    toastRes();
    anime({
      targets: "#pop-up-message",
      easing: "easeOutExpo",
      translateX: ["100%", 0],
    });
    clearTimeout(closePopUp);
    closePopUp = setTimeout(() => {
      hideMessage();
    }, seconds);
  } else if (
    // displayOn === "gen-options" &&
    // document.getElementById("genAi-options-menu").style.display === "flex"
    displayOn === "gen-options"
  ) {
    document
      .getElementById("pop-up-message")
      .setAttribute("displayOn", "gen-options");
    toastRes();
    anime({
      targets: "#pop-up-message",
      easing: "easeOutExpo",
      translateX: ["100%", 0],
    });
    clearTimeout(closePopUp);
    closePopUp = setTimeout(() => {
      hideMessage();
    }, seconds);
  } else {
    return;
  }
  $("#active-progress").removeClass("one");
  $("#active-progress").width();
  $("#active-progress").addClass("one");

  document.getElementById("pop-up-message").style.display = "flex";

  $("#pop-up-title").text(header);
  $("#pop-up-sub-title").html(subHeader);

  switch (type) {
    case "success":
      $("#pop-icon-cont").empty();
      // document.getElementById("pop-icon-cont").appendChild(icons.success);
      document.getElementById("pop-icon-cont").appendChild(successIcon);
      break;

    case "error":
      $("#pop-icon-cont").empty();
      // document.getElementById("pop-icon-cont").appendChild(icons.error);
      document.getElementById("pop-icon-cont").appendChild(errorIcon);
      break;

    default:
      break;
  }
  toastRes();
}

export function setTitle(title) {
  document.getElementById("podcast-id").value = title;
  document.getElementById("episode-title").disabled = false;
  document.getElementById("episode-content").disabled = false;
  titleListClose();
}

function titleListClose() {
  clearSuggestions();
  document.getElementById("showList").style.cssText = "visibility: hidden";
}

function clearTitles() {
  let listItems = document.querySelectorAll("#showTitleList li");
  listItems.forEach((item) => item.remove());
}

export function setTitleList(list, apiProvider) {
  let susers = [];
  susers = list;
  susers = susers.map((u) => [u.id, u.title]);
  clearTitles();
  susers.forEach((user) => {
    let li = document.createElement("li");
    let text = document.createTextNode(user[1]);
    li.appendChild(text);
    // eslint-disable-next-line no-useless-escape
    li.addEventListener("click", () => {
      setTitle(user[1].replace(/[$-\/?[-^{|}]/g, "\\$&"));
    });
    document.getElementById("showTitleList").appendChild(li);
  });
  if (apiProvider !== apiProviderBuzzsprout) {
    document.getElementById("showList").style.cssText = "visibility: visible";
  }
}
export async function dissociateProject(
  jobname,
  dissociatedUser,
  dissociatedBy,
) {
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .delete(_config.api + "/share-job", {
          data: {
            jobname: jobname,
            username: dissociatedUser,
            dissociateBy: dissociatedBy,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          showStatText("Collaborator is successfully dissociated", false);
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          showStatText(
            "Sorry! could not dissociate the collaborator. please try again later",
            false,
          );
          return Promise.reject(e);
        });
    },
  );
}

let isTheProjectShared;
let isEditFeatureEnabled;

export async function prepareShareholderStatusView(data) {
  if (
    data.online_shareholders.length > 0 ||
    data.offline_shareholders.length > 0
  )
    isTheProjectShared = true;
  if (!data.online_shareholders.length && isTheProjectShared)
    isEditFeatureEnabled = true;
  let creators = document.createElement("span");
  creators.setAttribute("id", "shareholder-profiles");

  data.online_shareholders.map(async (shareholder) => {
    if (shareholder.username !== Koolioai.username) {
      let creatorsData = document.createElement("img");
      creatorsData.id = shareholder.username + "-circle";
      creatorsData.classList.add("online-shareholder-profile-border");
      try {
        if (shareholder.username.startsWith("google")) {
          const url = _config.api + "/get-email";
          const params = {
            // eslint-disable-next-line no-undef
            username: shareholder.username, //!here
          };
          const response = await axios.get(url, {
            params,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.status === 200) {
            let user_email = response.data.email;
            shareholder.username = user_email.split("@")[0];
          }
        }
        creatorsData.src =
          "https://ui-avatars.com/api/?name=" +
          shareholder.username.replaceAll(" ", "+") +
          "&background=181818&color=fff&rounded=true&size=28";
        creatorsData.setAttribute("title", shareholder.username);
      } catch (e) {
        creatorsData.src =
          "https://ui-avatars.com/api/?name=" +
          shareholder.details.email +
          "&background=181818&color=fff&rounded=true&size=28";
        creatorsData.setAttribute("title", shareholder.details.email);
      }
      creatorsData.setAttribute("data-bs-placement", "right");
      new Tooltip(creatorsData, {
        trigger: "hover",
        boundary: "scrollParent",
        animation: true,
        delay: { show: 500, hide: 100 },
      });
      creatorsData.addEventListener("mouseleave", async function () {
        $(".online-shareholder-profile-border").tooltip("hide");
      });
      creators.appendChild(creatorsData);
    }
  });
  data.offline_shareholders.map(async (shareholder) => {
    if (shareholder.username !== Koolioai.username) {
      let creatorsData = document.createElement("img");
      creatorsData.id = shareholder.username + "-circle";
      creatorsData.classList.add("offline-shareholder-profile-border");
      try {
        if (shareholder.username.startsWith("google")) {
          const url = _config.api + "/get-email";
          const params = {
            // eslint-disable-next-line no-undef
            username: shareholder.username, //!here
          };
          const response = await axios.get(url, {
            params,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.status === 200) {
            let user_email = response.data.email;
            shareholder.username = user_email.split("@")[0];
          }
        }
        creatorsData.src =
          "https://ui-avatars.com/api/?name=" +
          shareholder.username.replaceAll(" ", "+") +
          "&background=181818&color=fff&rounded=true&size=28";
        creatorsData.setAttribute("title", shareholder.username);
      } catch (e) {
        creatorsData.src =
          "https://ui-avatars.com/api/?name=" +
          shareholder.details.email +
          "&background=181818&color=fff&rounded=true&size=28";
        creatorsData.setAttribute("title", shareholder.details.email);
      }
      creatorsData.setAttribute("data-bs-placement", "right");
      new Tooltip(creatorsData, {
        trigger: "hover",
        boundary: "scrollParent",
        animation: true,
        delay: { show: 500, hide: 100 },
      });
      // creatorsData.addEventListener("mouseleave", async function () {
      //   $(".offline-shareholder-profile-border").tooltip("hide");
      // });

      creatorsData.addEventListener("mouseleave", async function () {
        var tooltips = document.querySelectorAll(
          ".offline-shareholder-profile-border",
        );
        tooltips.forEach(function (tooltip) {
          tooltip.removeAttribute("title");
        });
      });
      creators.appendChild(creatorsData);
    }
  });
  let element = document.getElementById("shareholder-profile-cont");
  let child = document.getElementById("shareholder-profiles");
  if (child) element.removeChild(child);
  if(element){element.appendChild(creators);}
  let editFeatureLockedBy = store.getState().shareAndCollab.editFeatureLockedBy;
  const featureLockedByIcon = document.getElementById(
    editFeatureLockedBy + "-circle",
  );
  if (featureLockedByIcon) {
    featureLockedByIcon.style.backgroundColor = "#E2522B";
    featureLockedByIcon.src =
      "https://ui-avatars.com/api/?name=" +
      editFeatureLockedBy +
      "&background=E2522B&color=fff&rounded=true&size=28";
  }
}

export function updateOnlineShareholderView() {
  let jobname = store.getState().projectData.resultStat.jobname;
  getLiveShareholders(jobname, Koolioai.username, "LIVE")
    .then((data) => {
      prepareShareholderStatusView(data);
    })
    .catch((error) => {
      //console.log(error)
    });
}

export async function getAINotesFromDB() {
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  const jobname = resultStat.jobname;
  const params = {
    jobname,
    username: Koolioai.username,
  };
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/analytics-chatgpt-status", {
          params,
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          sessionStorage.setItem("aiShowNotes", JSON.stringify(response.data));
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}

// function showNotesGenerate(){
//     const permResponse = ACM.checkPermission('generate-notes')
//     if (!permResponse.success)
//         return showMessage(permResponse.message, 'AI analytics is not available in the free plan', 2000, 'error', 'more-options')
//     if (!(transcriptionCompleted)) {
//         moreOptionsAlert('transcription in progress, operation disabled')
//         return
//     }
//     const params = {
//         jobname: playlist.jobname,
//         username: Koolioai.username
//     }
//     $('#show-notes-project-button').addClass('btn-active')
//     document.getElementById("show-notes-project-button").disabled = true

//     document.getElementById("aiTitles").innerHTML=""
//     document.getElementById("aiTitlesLabel").style.display ="none"
//     document.getElementById("aiKeywords").innerHTML=""
//     document.getElementById("aiKeywordsLabel").style.display ="none"
//     document.getElementById("aiHighlights").innerHTML=""
//     document.getElementById("aihighlightsLabel").style.display ="none"
//     document.getElementById("aiSocials").innerHTML=""
//     document.getElementById("aiSocialsLabel").style.display ="none"
//     //document.getElementById("aiSoundbites").innerHTML=""
//     //document.getElementById("aiSoundbitesLabel").style.display ="none"
//     document.getElementById("show-notes-spinner").style.display = "block"
//     return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
//         .then(tokens => {
//             return axios
//                 .get(_config.api+'/analytics-chatgpt', {
//                     params,
//                     headers: {
//                         Authorization: `${tokens[0]}`,
//                         AccessToken: `${tokens[1]}`
//                     }
//                 })
//                 .then(response => {
//                     return Promise.resolve(response.data)
//                 })
//                 .catch(e=>{
//                     document.getElementById("show-notes-spinner").style.display = "none"
//                     $('#show-notes-project-button').removeClass('btn-active')
//                     document.getElementById("show-notes-project-button").disabled = false
//                     return Promise.reject(e)
//                 })
//         })
// }

export async function sendEditedAiNotesToBackend(item) {
  let jobname = store.getState().projectData.resultStat.jobname;
  const params = {
    jobname,
    username: Koolioai.username,
  };
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .post(_config.api + "/analytics-chatgpt", item, {
          params,
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}
// // ShowNotes

// async function getAllPrevGenMusics(word = null){
//     const permResponse = ACM.checkPermission('gen-ai')
//     if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
//         if(fromMoreOptions) {
//             return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
//         else {
//             return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
//         }
//     }
//     if(document.getElementById("prev_gen_musics").children.length) {
//         handlePrevGenMusics(document.getElementById("prev_gen_musics").children)
//         //$("#gen_music_citation > div").removeClass("gen-ai-text-slide")
//         return
//     }

//     let musicTracks
//     initiateGenLoader("music")
//     return Koolioai.authToken()
//         .then(token => axios
//             .get(_config.api + "/gen-ai", {
//                 params: {
//                     sentence: word,
//                     combineResult: true,
//                     offset: 0,
//                     size : 8,
//                     jobname,
//                     username : Koolioai.username,
//                     getPreviousGenAi :  true,
//                     get_music : true
//                 },
//                 headers: {
//                     Authorization: `${token}`
//                 }
//             })
//             .then(response => {
//                 musicTracks = response.data[word] || response.data.data || []
//                 searchMusicSuccessHandler(musicTracks, word, playlist.getTimeSelection().start, playlist.getTimeSelection().end, false, null, true)
//                     .catch(e => {
//                         // console.error(e)
//                     })
//             }).catch(() => Promise.resolve([])))
// }
// async function getAllPrevGenImage(idno = null,create= false,history=false){
//     const permResponse = ACM.checkPermission('gen-ai')
//     if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
//         if(fromMoreOptions) {
//             return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
//         else {
//             return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
//         }
//     }
//     if(document.getElementById("prev_gen_image").children.length) {
//         if(!idno && !history){
//             handlePrevGenImage(document.getElementById("prev_gen_image").children)
//             return
//         }
//     }

//     let imageTracks
//     let getPregen = true
//     if(create == true){
//         getPregen=false
//     }
//     if(!idno && !history)
//         initiateGenLoader("image")
//     return Koolioai.authToken()
//         .then(token => axios
//             .get(_config.api + "/img-gen-ai", {
//                 params: {
//                     id:idno,
//                     jobname,
//                     username : Koolioai.username,
//                     getPreviousGenAi :  getPregen,
//                     desiredImgCount : 1
//                 },
//                 headers: {
//                     Authorization: `${token}`
//                 }
//             })
//             .then(response => {
//                 imageTracks = response.data.data || []
//                 searchImageSuccessHandler(imageTracks, getPregen)
//                     .catch(e => {
//                         // console.error(e)
//                     })

//             }).catch(() => Promise.resolve([])))
// }
// async function getAllPrevGenSFX(word = null){
//     const permResponse = ACM.checkPermission('gen-ai')
//     if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
//         if(fromMoreOptions) {
//             return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
//         else {
//             return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
//         }
//     }
//     if(document.getElementById("prev_gen_sfx").children.length) {
//         handlePrevGenSFX(document.getElementById("prev_gen_sfx").children)
//         return
//     }

//     let sfxTracks
//     initiateGenLoader("sfx")
//     return Koolioai.authToken()
//         .then(token => axios
//             .get(_config.api + "/gen-ai", {
//                 params: {
//                     sentence: word,
//                     combineResult: true,
//                     offset: 0,
//                     size : 8,
//                     jobname,
//                     username : Koolioai.username,
//                     getPreviousGenAi :  true,
//                     get_music : false
//                 },
//                 headers: {
//                     Authorization: `${token}`
//                 }
//             })
//             .then(response => {
//                 sfxTracks = response.data[word] || response.data.data || []
//                 searchSfxSuccessHandler(sfxTracks, word, playlist.getTimeSelection().start, playlist.getTimeSelection().end, false, null, true)
//                     .catch(e => {
//                         // console.error(e)
//                     })
//             }).catch(() => Promise.resolve([])))
// }

// async function genMusicOptions() {
//     //const permResponse = ACM.checkPermission('gen-ai')
//     //if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
//     //    if(fromMoreOptions) {
//     //        return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
//     //    else {
//     //        return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
//     //    }
//     //}
//     sendLog({
//         activity: 'click',
//         target: 'open gen music tab',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })

//     topbar.show();
//     (function step() {
//         setTimeout(function () {
//             if (topbar.progress('+.01') < 1) step()
//         }, 30)
//     })()

//     document.getElementById('genMusic').style.display = 'block'
//     document.getElementById('genSFX').style.display = 'none'
//     document.getElementById('aiAnalytics').style.display = 'none'
//     document.getElementById('genImage').style.display = 'none'

//     document.getElementById('music-gen-icon').style.opacity = '1'
//     document.getElementById('sfx-gen-icon').style.opacity = '0.5'
//     document.getElementById('show-notes-icon').style.opacity = '0.5'
//     document.getElementById('image-gen-icon').style.opacity = '0.5'

//     const permResponse = ACM.checkPermission('gen-ai')
//     if (permResponse.success)
//         await getAllPrevGenMusics()
//     else handlePrevGenMusics([])

//     topbar.hide()
// }

// async function genSFXOptions() {
//     //const permResponse = ACM.checkPermission('gen-ai')
//     //if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
//     //    if(fromMoreOptions) {
//     //        return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
//     //    else {
//     //        return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
//     //    }
//     //}
//     sendLog({
//         activity: 'click',
//         target: 'open gen sfx tab',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })

//     topbar.show();
//     (function step() {
//         setTimeout(function () {
//             if (topbar.progress('+.01') < 1) step()
//         }, 30)
//     })()

//     document.getElementById('genMusic').style.display = 'none'
//     document.getElementById('genSFX').style.display = 'block'
//     document.getElementById('aiAnalytics').style.display = 'none'
//     document.getElementById('genImage').style.display = 'none'

//     document.getElementById('music-gen-icon').style.opacity = '0.5'
//     document.getElementById('sfx-gen-icon').style.opacity = '1'
//     document.getElementById('show-notes-icon').style.opacity = '0.5'
//     document.getElementById('image-gen-icon').style.opacity = '0.5'

//     const permResponse = ACM.checkPermission('gen-ai')
//     if (permResponse.success)
//         await getAllPrevGenSFX()
//     else handlePrevGenSFX([])

//     topbar.hide()
// }
// async function genImageOptions(){

//     sendLog({
//         activity: 'click',
//         target: 'open gen image tab',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })
//     topbar.show();
//     (function step() {
//         setTimeout(function () {
//             if (topbar.progress('+.01') < 1) step()
//         }, 30)
//     })()
//     document.getElementById('genMusic').style.display = 'none'
//     document.getElementById('genSFX').style.display = 'none'
//     document.getElementById('aiAnalytics').style.display = 'none'
//     document.getElementById('genImage').style.display = 'block'

//     document.getElementById('music-gen-icon').style.opacity = '0.5'
//     document.getElementById('sfx-gen-icon').style.opacity = '0.5'
//     document.getElementById('show-notes-icon').style.opacity = '0.5'
//     document.getElementById('image-gen-icon').style.opacity = '1'
//     const permResponse = ACM.checkPermission('gen-ai')
//     if (permResponse.success)
//         await getAllPrevGenImage()
//     else handlePrevGenImage([])

//     topbar.hide()

// }
// async function showNotesOptions() {
//     //const permResponse = ACM.checkPermission('generate-notes')
//     //if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2")
//     //    return showMessage(permResponse.message, 'Show Notes is not available in free plan', 2000, 'error', 'gen-options')
//     if (!(transcriptionCompleted)) {
//         moreOptionsAlert('transcription in progress, operation disabled')
//         return
//     }

//     sendLog({
//         activity: 'click',
//         target: 'open analytics tab',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })

//     document.getElementById("aiTitles").innerHTML=""
//     document.getElementById("aiKeywords").innerHTML=""
//     document.getElementById("aiHighlights").innerHTML=""
//     document.getElementById("aiSocials").innerHTML=""
//     //document.getElementById("aiSoundbites").innerHTML=""
//     document.getElementById("aiTitlesLabel").style.display ="none"
//     document.getElementById("aiKeywordsLabel").style.display ="none"
//     document.getElementById("aihighlightsLabel").style.display ="none"
//     document.getElementById("aiSocialsLabel").style.display ="none"
//     //document.getElementById("aiSoundbitesLabel").style.display ="none"

//     topbar.show();
//     (function step() {
//         setTimeout(function () {
//             if (topbar.progress('+.01') < 1) step()
//         }, 30)
//     })()

//     document.getElementById('genMusic').style.display = 'none'
//     document.getElementById('genSFX').style.display = 'none'
//     document.getElementById('aiAnalytics').style.display = 'block'
//     document.getElementById('genImage').style.display = 'none'

//     document.getElementById('music-gen-icon').style.opacity = '0.5'
//     document.getElementById('sfx-gen-icon').style.opacity = '0.5'
//     document.getElementById('show-notes-icon').style.opacity = '1'
//     document.getElementById('image-gen-icon').style.opacity = '0.5'

//     checkAINotes().then(() => {
//         topbar.hide()
//         document.getElementById("show-notes-project-button").disabled = false
//     })

// }

// function showCitation(){
//     $('#half-modals').html(genAiCitationModal)
//     $('#half-modals').css('visibility', 'visible')
//     koolioModals()
// }

// async function genAIMenu() {
//     //const permResponse = ACM.checkPermission('gen-ai')
//     //if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
//     //    if(fromMoreOptions) {
//     //        return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
//     //    else {
//     //        return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
//     //    }
//     //}
//     forceHideMessage()
//     document.getElementById('annotmodal').style.display = 'none'
//     document.getElementById('genAi-options-menu').style.display = 'flex'
//     if ((playCont.classList.contains('btn-pause'))) {
//         pauseAudio()
//         audioPlayFlag = true
//     }
//     masterTrackType = 'sfx'
//     updateMasterTracks()
//     const trackType = document.getElementById('addon-opt-sfx').classList.contains('active') ? 'sfx' : 'music'
//     if(trackType === "sfx")
//         genSFXOptions()
//     else
//         genMusicOptions()
// }

// function openGenAiContent(){
//     //const permResponse = ACM.checkPermission('gen-ai')
//     //if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
//     //    if(fromMoreOptions) {
//     //        return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
//     //    else {
//     //        return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
//     //    }
//     //}
//     document.getElementById('musicSearchCommand').style.display = 'none'
//     document.getElementById('imageSearchCommand').style.display = 'none'
//     document.getElementById('sfxSearchCommand').style.display = 'none'
//     if(!document.getElementById("genMusicSearchBar").disabled) {
//         document.getElementById('genMusicSearchBar').value = ''
//         $("#genMusicInfoText").css({"visibility" : ""})
//         if(document.getElementById("modal_gen_music").children.length){
//             for (let i = document.getElementById("modal_gen_music").children.length-1; i>=0 ; i--){
//                 document.getElementById("prev_gen_musics").insertBefore(document.getElementById("modal_gen_music").children[i], document.getElementById("prev_gen_musics").childNodes[0])

//             }
//             handlePrevGenMusics([1])
//         }else{
//             $("#modal_gen_music a").remove()
//         }
//     }
//     if(!document.getElementById("genImageSearchBar").disabled) {
//         document.getElementById('genImageSearchBar').value = ''
//         $("#genImageInfoText").css({"visibility" : ""})
//         if(document.getElementById("modal_gen_image").children.length){
//             getAllPrevGenImage(null,false,true)
//             document.getElementById("modal_gen_image").innerHTML=""
//             handlePrevGenImage([1])
//         }else{
//             $("#prev_gen_musics a").remove()
//         }
//     }
//     if(!document.getElementById("genSFXSearchBar").disabled) {
//         document.getElementById('genSFXSearchBar').value = ''
//         $("#genSFXInfoText").css({"visibility" : ""})
//         if(document.getElementById("modal_gen_sfx").children.length){
//             for (let i = document.getElementById("modal_gen_sfx").children.length-1; i>=0 ; i--){
//                 document.getElementById("prev_gen_sfx").insertBefore(document.getElementById("modal_gen_sfx").children[i], document.getElementById("prev_gen_sfx").childNodes[0])

//             }
//             handlePrevGenSFX([1])
//         }else{
//             $("#modal_gen_sfx a").remove()
//         }
//     }
//     if(fromMoreOptions) {
//         document.getElementById('more-options-menu').style.display = 'none'
//     }
//     else{
//         //document.getElementById('modal_1').style.display = 'none'
//         document.getElementById('annotmodal').style.display= "none"
//     }

//     genAIMenu()
// }

// function closeGenAiContent(){
//     sendLog({
//         activity: 'click',
//         target: 'close genAi options',
//         timeStamp: new Date().toISOString(),
//         jobname,
//         status: 'success',
//         statusMsg: 'success'
//     })

//     forceHideMessage()
//     document.getElementById('genAi-options-menu').style.display = 'none'
//     document.getElementById('genMusic').style.display = 'none'
//     document.getElementById('genImage').style.display = 'none'
//     document.getElementById('genSFX').style.display = 'none'
//     if(fromMoreOptions) {
//         document.getElementById('more-options-menu').style.display = 'flex'
//     }
//     else {
//         //document.getElementById('modal_1').style.display = 'block'
//         document.getElementById('annotmodal').style.display= "block"
//     }

// }

// function genAiOptions(){
//     //const permResponse = ACM.checkPermission('gen-ai')
//     //if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
//     //    return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')
//     //}
//     fromMoreOptions = true
//     openGenAiContent()
// }

export async function updateWorkspaceWithOrigin(
  jobname,
  refid = null,
  updateIndexDbOp = false,
  aliasIdFromBackend = false,
  props,
) {
  if (jobname === undefined || jobname === null) {
    jobname = store.getState().projectData.resultStat.jobname;
  }

  if (refid == null) {
    const resultStat = store.getState().projectData.resultStat;
    refid = resultStat.refid;
  }
  return getOpListFromIndexedDb(jobname, refid, aliasIdFromBackend).then(
    (res) => {
      if (res.status === 200) {
        //return loadFlags(jobname, true, true)
        //    .then(() => {
        //        showStatText('synchronized')
        //        hideplaylistloader()
        //        enableEditPreviewBtns()
        //        return Promise.resolve()
        //    })
        store.dispatch(
          frontendApplySliceActions.frontendApplyReducerData(true),
        );
      } else if (res.status === 201) {
        applyOperations(
          res.operations,
          undefined,
          [],
          updateIndexDbOp,
          false,
          props,
        ).then(() => {
          store.dispatch(
            frontendApplySliceActions.frontendApplyReducerData(true),
          );
        });

        //if (playlist.jobCode === 101) {
        //    return applyOperations(res.operations, undefined, [], updateIndexDbOp)
        //        .then(() => {
        //            return loadFlags(jobname, true, true)
        //                .then(() => {
        //                    playlist.reDrawAllPlaylist()
        //                    hideplaylistloader()
        //                    enableEditPreviewBtns()
        //                })
        //        })
        //} else if (recordPlaylist.jobCode === 102) {
        //    return applyRecordOperations(res.operations)
        //        .then(() => {
        //            return loadFlags(jobname, true, true)
        //                .then(() => {
        //                    playlist.reDrawAllPlaylist()
        //                    hideplaylistloader()
        //                    enableEditPreviewBtns()
        //                })
        //        })
        //} else {
        //    hideplaylistloader()
        //    enableEditPreviewBtns()
        //    showStatText('unknown jobCode', res.status)
        //    return Promise.resolve()
        //}
      } else if (res.status === 404) {
        //if(aliasIdFromBackend){
        //    syncOperationsInBackend()
        //        .then(()=>{
        //            return updateWorkspaceWithOrigin(refid, updateIndexDbOp, aliasIdFromBackend)
        //        })
        //}
        //else {
        //    hideplaylistloader()
        //    enableEditPreviewBtns()
        //    showStatText('conflict', false)
        //    return Promise.resolve()
        //}
      } else {
        //hideplaylistloader()
        //enableEditPreviewBtns()
        //showStatText('unknown status', res.status)
        return Promise.resolve();
      }
    },
  );
}
