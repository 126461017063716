import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPortal } from "react-dom";
import "../../css/dark/SfxModal.css";
import "bootstrap/dist/css/bootstrap.css";
import { Koolioai, _config } from "../../utils/cognitoAuth";
import axios from "axios"; // Import axios for making API calls
import WaveSurfer from "wavesurfer.js";
import { CopyBufferActions } from "../../redux/slice/copyBuffer";
import { getArrayBufferFromSrcURL } from "../../utils/indexedDButils";
import { create_UUID, pasteOperation } from "../../utils/utils";
import { copiedAnnotActions } from "../../redux/slice/copiedAnnotation";

import {
  showplaylistloader,
  hideplaylistloader,
} from "../pages/kooliospace/kooliospaceUtils";
import { setSfxDurationSliceActions } from "../../redux/slice/SfxDurationSlice";
import store from "../../redux/Store";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";
import GenAi from "../pages/moreoptions/GenAi";
import { offset } from "@popperjs/core";
import GenAIPortal from "../pages/moreoptions/GenAiPortal";

const SfxModal = ({ closeModal, clickedAnnot }) => {
  useBootstrapTooltips();
  useEffect(() => {
    if (clickedAnnot) {
      setWord(clickedAnnot);
      performSearch(clickedAnnot);
    }
  }, [clickedAnnot]);
  const dispatch = useDispatch();
  const [word, setWord] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const searchTimeout = useRef(null);
  const audioRef = useRef(null);
  const [showMusicGenres, setShowMusicGenres] = useState(false);
  const [ldr, setldr] = useState(false);
  const [showGenres, setShowGenres] = useState(true); // State to manage the visibility of music genre buttons
  const [openGenaiPage, setopenGenaiPage] = useState(false); // State to manage the genai pages when custom button is clicked
  const [currentOffset, setCurrentOffset] = useState(0);
  const handleToggleMusicGenres = () => {
    setShowMusicGenres((prevState) => !prevState);
    setWord("");
    document.getElementById("sfxSearchBar").value = "";
    if (!showMusicGenres) {
      performSearchMusic("");
    } else {
      performSearch("");
    }
  };
  const handleToggleSFXGenres = () => {
    setldr(false);
    setShowMusicGenres((prevState) => !prevState);
    setCurrentOffset(0);
    setWord("");
    document.getElementById("sfxSearchBar").value = "";
    if (!showGenres) {
      performSearchMusic("");
    } else {
      performSearch("");
    }
  };
  const performSearch = async (
    word,
    audioCraft = false,
    sfxMusicOffset = 0,
    size = 5,
  ) => {
    // setWord(word);
    if (!word) {
      setSearchResults([]);
      document.getElementById("sfx-music-arrow-left").classList.add("d-none");
      document.getElementById("sfx-music-arrow-right").classList.add("d-none");
      return; // If the query is empty, reset search results and return
    }

    setLoading(true);
    setldr(false);

    try {
      const token = await Koolioai.authToken();
      const jobname = store.getState().projectData.resultStat.jobname;
      const response = await axios.get(_config.api + "/search-sfx", {
        params: {
          sentence: word,
          combineResult: true,
          offset: sfxMusicOffset,
          size: size,
          jobname,
          username: Koolioai.username,
          getPreviousGenAi: false,
          get_music: false,
          desiredSFXMusicCount: 0,
        },
        headers: {
          Authorization: `${token}`,
        },
      });

      const sfxTracks = response.data[word] || response.data.data || [];
      const signedUrls = await Promise.all(
        sfxTracks.map(async (track) => {
          const signedUrl = await Koolioai.getSignedPreview(
            track.filename,
            track.sfxlib_bucket,
          );

          const signedUrlCompressed = await Koolioai.getSignedCompressed(
            track.filename,
            track.sfxlib_bucket,
          );
          return { ...track, signedUrl, signedUrlCompressed };
        }),
      );
      setSearchResults(signedUrls);
      //pagination icon highlight code
      if (currentOffset === 0) {
        document.getElementById("sfx-music-arrow-left").style.opacity = "0.2";
        document.getElementById("sfx-music-arrow-left").style.pointerEvents =
          "none";
      } else {
        document.getElementById("sfx-music-arrow-left").style.opacity = "1";
        document.getElementById("sfx-music-arrow-left").style.pointerEvents =
          "auto";
      }
      document
        .getElementById("sfx-music-arrow-left")
        .classList.remove("d-none");
      document
        .getElementById("sfx-music-arrow-right")
        .classList.remove("d-none");
      if (sfxTracks.length !== 5 && sfxTracks.length !== 0) {
        document.getElementById("sfx-music-arrow-right").style.opacity = "0.2";
        document.getElementById("sfx-music-arrow-right").style.pointerEvents =
          "none";
      } else if (sfxTracks.length === 0) {
        document.getElementById("sfx-music-arrow-left").classList.add("d-none");
        document
          .getElementById("sfx-music-arrow-right")
          .classList.add("d-none");
        return;
      }
      //pagination icon highlight code
      setldr(false);
    } catch (error) {
      console.error("Error occurred while performing search:", error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const performSearchMusic = async (
    word,
    is_a_predefined_genre = false,
    sfxMusicOffset = 0,
    size = 5,
  ) => {
    setWord(word);
    if (!word) {
      document.getElementById("sfx-music-arrow-left").classList.add("d-none");
      document.getElementById("sfx-music-arrow-right").classList.add("d-none");
      setSearchResults([]);
      return; // If the query is empty, reset search results and return
    }

    setLoading(true);
    setldr(false);

    try {
      const token = await Koolioai.authToken();
      const jobname = store.getState().projectData.resultStat.jobname;
      const response = await axios.get(_config.api + "/search-music", {
        params: {
          sentence: word,
          combineResult: true,
          offset: sfxMusicOffset,
          size: size,
          jobname,
          username: Koolioai.username,
          getPreviousGenAi: false,
          get_music: true,
          desiredSFXMusicCount: 0,
          is_a_predefined_genre: is_a_predefined_genre,
        },
        headers: {
          Authorization: `${token}`,
        },
      });

      const MusicTracks = response.data[word] || response.data.data || [];
      const signedUrls = await Promise.all(
        MusicTracks.map(async (track) => {
          const signedUrl = await Koolioai.getSignedPreview(
            track.filename,
            track.sfxlib_bucket,
          );

          const signedUrlCompressed = await Koolioai.getSignedCompressed(
            track.filename,
            track.sfxlib_bucket,
          );
          return { ...track, signedUrl, signedUrlCompressed };
        }),
      );
      setSearchResults(signedUrls);
      //pagination code
      if (currentOffset === 0) {
        document.getElementById("sfx-music-arrow-left").style.opacity = "0.2";
        document.getElementById("sfx-music-arrow-left").style.pointerEvents =
          "none";
      } else {
        document.getElementById("sfx-music-arrow-left").style.opacity = "1";
        document.getElementById("sfx-music-arrow-left").style.pointerEvents =
          "auto";
        document.getElementById("sfx-music-arrow-right").style.opacity = "1";
        document.getElementById("sfx-music-arrow-right").style.pointerEvents =
          "auto";
      }
      document
        .getElementById("sfx-music-arrow-left")
        .classList.remove("d-none");
      document
        .getElementById("sfx-music-arrow-right")
        .classList.remove("d-none");
      if (MusicTracks.length !== 5 && MusicTracks.length !== 0) {
        document.getElementById("sfx-music-arrow-right").style.opacity = "0.2";
        document.getElementById("sfx-music-arrow-right").style.pointerEvents =
          "none";
      } else if (MusicTracks.length === 0) {
        document.getElementById("sfx-music-arrow-left").classList.add("d-none");
        document
          .getElementById("sfx-music-arrow-right")
          .classList.add("d-none");
        return;
      }

      //pagination code
      setldr(false);
    } catch (error) {
      console.error("Error occurred while performing search:", error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setWord(query);

    // Clear previous timeout
    clearTimeout(searchTimeout.current);

    // Set a new timeout for 500 milliseconds
    searchTimeout.current = setTimeout(() => {
      if (showMusicGenres) {
        performSearchMusic(query);
      } else {
        performSearch(query);
      }
    }, 500);
  };

  const playSfxOnHover = (signedUrl) => {
    const url = signedUrl;
    if (!audioRef.current || audioRef.current.paused) {
      audioRef.current = new Audio(url);
      audioRef.current.play().catch((error) => {
        // console.error("Failed to play audio:", error);
      });
    }
  };

  const addSfx = async (result, selectedRangeForSfx = 3) => {
    showplaylistloader();
    let copiedAnnotations = [];
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    let context = new AudioContext();
    let sampleRate = context.sampleRate;
    let samplesPerPixel = parseInt((256 * 1440) / window.innerWidth);
    let pixPerSecond = sampleRate / samplesPerPixel;
    const wavesurferObjForNestedRecord = WaveSurfer.create({
      container: "#nested-file-import",
      waveColor: "#8A2BE2",
      responsive: true,
      sampleRate: sampleRate,
      minPxPerSec: pixPerSecond,
    });
    closeModal();
    stopSfxOnLeave();
    let arrayBuffer;
    try {
      arrayBuffer = await getArrayBufferFromSrcURL(result.signedUrlCompressed);
    } catch {
      hideplaylistloader();
      // return;
    }

    const audioBlob = new Blob([arrayBuffer]);
    const blobURL = URL.createObjectURL(audioBlob);
    wavesurferObjForNestedRecord.load(blobURL);

    wavesurferObjForNestedRecord.on("ready", function () {
      // Get the duration of the audio file
      let duration = wavesurferObjForNestedRecord.getDuration();
      dispatch(setSfxDurationSliceActions.setSfxDuration(duration));
      // Update the span with the actual duration

      const originalBuffer = wavesurferObjForNestedRecord.backend.buffer;
      const copiedBuffer = new Float32Array(
        duration * originalBuffer.sampleRate,
      );

      // Copy the audio data into the new buffer
      originalBuffer.copyFromChannel(
        copiedBuffer,
        0,
        Math.floor(0 * originalBuffer.sampleRate),
      );

      dispatch(CopyBufferActions.updateCopyBuffer({ copiedBuffer }));
      dispatch(CopyBufferActions.updatedCreatedFrom({ createdFrom: "sfx" }));
      let annot = {
        confidence: 1,
        content: result.name,
        end_time: "",
        id: `sfx_0_${create_UUID()}`,
        is_silenced: false,
        speaker_label: "sfx_0",
        start_time: "",
      };
      let sfxseg = {
        annotations: [annot],
        backend_filename: "",
        cueIn: 0,
        cueOut: 3,
        end_time: "",
        fades: [],
        filename: "",
        id: create_UUID(),
        speaker_label: "sfx-0",
        src: "",
        start_time: "",
        sub_pannings: [],
        sub_volumes: [],
        to_upload: true,
      };

      copiedAnnotations.push(sfxseg);
      dispatch(copiedAnnotActions.updAnnotclk({ copiedAnnotations }));
      dispatch(copiedAnnotActions.updCopiedFrom("SFX"));
      hideplaylistloader();
      pasteOperation(true);
    });
  };

  const stopSfxOnLeave = () => {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  function openGenAiContent() {
    setopenGenaiPage(true);
  }
  function closeGenaiContent() {
    setopenGenaiPage(false);
  }
  function closeSfxModal() {
    closeModal();
  }
  //pagination for sfx and music

  function NextPagination() {
    let offset = currentOffset + 5;
    setCurrentOffset(offset);
    showMusicGenres
      ? performSearchMusic(word, false, offset, 5)
      : performSearch(word, false, offset, 5);
  }
  function prevPagination() {
    let offset = currentOffset - 5;
    setCurrentOffset(offset);
    showMusicGenres
      ? performSearchMusic(word, false, offset, 5)
      : performSearch(word, false, offset, 5);
  }
  return createPortal(
    <>
      {openGenaiPage && (
        <GenAIPortal
          isWhat={showMusicGenres ? "music" : "sfx"}
          onClose={closeGenaiContent}
          onCloseMoreOptions={closeSfxModal}
        ></GenAIPortal>
      )}
      <div className="recordingName-wrapper-sfx"></div>
      <div id="annotmodal" className="sfx-modal">
        <span className="col-12 close-modal" id="annotCloseModal">
          <img
            className="img-fluid"
            id="close-modal-window"
            src="/img/exit-workspace.svg"
            onClick={closeModal}
            alt=""
          />
        </span>
        <div
          id="modal_1"
          className="modal-content border-0"
          style={{ zIndex: "1005", display: "block" }}
        >
          <span
            id="sound_effects_title"
            className="d-flex row justify-content-center align-items-center"
          >
            <div className="col-2 addon-opt-sfx active" id="addon-opt-sfx">
              <h2
                className={`modal-header-text ${!showMusicGenres ? "purple-heading" : ""}`}
                id="addon-opt-sfx-text"
                onClick={handleToggleSFXGenres}
              >
                SFX
              </h2>
            </div>
            <div className="addon-separator col-1 addonSep">
              <img
                src="/img/sfxfolder/control-separator.png"
                id="addonSep"
                alt=""
              />
            </div>
            <div className="col-2 addon-opt-music" id="addon-opt-music">
              <h2
                className={`modal-header-text ${showMusicGenres ? "orange-heading" : ""}`}
                id="addon-opt-music-text"
                onClick={handleToggleMusicGenres}
              >
                Music
              </h2>
            </div>
          </span>
          <input
            id="sfxSearchBar"
            value={word}
            onChange={handleSearchInputChange}
            autoComplete="off"
            className="transcriptSearch sfx"
            type="text"
            placeholder="Search..."
          />
          {!loading && searchResults.length === 0 && word && (
            <p
              style={{
                color: "white",
                fontsize: "13px",
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              No results, please try or create with a new keyword
            </p>
          )}
          {!loading && searchResults.length === 0 && showMusicGenres && (
            <div id="music-genres">
              <div className="action-project line-height-music-genres">
                <button
                  className="music-action-project-button"
                  onClick={() => performSearchMusic("Comedy", true)}
                >
                  Comedy
                </button>
                <button
                  className="music-action-project-button"
                  onClick={() => performSearchMusic("Electronic", true)}
                >
                  Electronic
                </button>
                <button
                  className="music-action-project-button"
                  onClick={() => performSearchMusic("Epic drama", true)}
                >
                  Epic drama
                </button>
                <button
                  className="music-action-project-button"
                  onClick={() => performSearchMusic("Fantasy", true)}
                >
                  Fantasy
                </button>
                <button
                  className="music-action-project-button"
                  onClick={() => performSearchMusic("Horror", true)}
                >
                  Horror
                </button>
                <button
                  className="music-action-project-button"
                  onClick={() =>
                    performSearchMusic("Miscellaneous classical", true)
                  }
                >
                  Miscellaneous classical
                </button>
                <button
                  className="music-action-project-button"
                  onClick={() => performSearchMusic("Miscellaneous jazz", true)}
                >
                  Miscellaneous jazz
                </button>
                <button
                  className="music-action-project-button"
                  onClick={() => performSearchMusic("Miscellaneous rock", true)}
                >
                  Miscellaneous rock
                </button>
                <button
                  id="miscellaneous"
                  className="music-action-project-button"
                  onClick={() => performSearchMusic("miscellaneous", true)}
                >
                  Miscellaneous
                </button>
              </div>
            </div>
          )}

          {loading && (
            <div
              id="sfx-loader"
              style={{
                position: "relative",
                backgroundColor: "rgba(0,0,0,0)",
                zIndex: "15",
              }}
            >
              <img
                src="../../img/sfxfolder/loadingspinner.gif"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "3%",
                }}
                className="ajax-loader"
                alt=""
              />
            </div>
          )}
          {!loading && searchResults.length > 0 && (
            <>
              {searchResults.map((result, index) => (
                <div
                  key={index}
                  className="prev-searched-list"
                  id={`sfxSearchList-div-${index}`}
                >
                  <ul className="prev-searched-sfx sfxSearchList">
                    <li className="sfxcontent" key={index}>
                      {/* Render each text element with hover and leave events */}
                      <p
                        onClick={() => addSfx(result)}
                        onMouseOver={() => playSfxOnHover(result.signedUrl)}
                        onMouseLeave={() => stopSfxOnLeave()}
                      >
                        {result.description.toLowerCase()}
                      </p>
                    </li>
                  </ul>
                </div>
              ))}
            </>
          )}

          {/* genAI custom create btn */}
          {!loading && (
            <div id="createYourMusic" style={{ margin: "0.5vh" }}>
              <div
                style={{
                  margin: "2.5vh",
                  textAlign: "center",
                  color: "blueviolet",
                }}
                id="create-music-or"
              >
                OR
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ margin: "2.5vh" }}
              >
                <button
                  id="create-music-button"
                  className=""
                  onClick={() => openGenAiContent()}
                >
                  {showMusicGenres
                    ? "Create your own music"
                    : "Create your own sfx"}
                </button>
              </div>
            </div>
          )}
          {ldr && (
            <p
              style={{ color: "white", fontsize: "13px", textAlign: "center" }}
            >
              No results, please try or create with a new keyword
            </p>
          )}
          <img
            className="sfx-music-arrows d-none"
            id="sfx-music-arrow-left"
            src="/image/arrow-right.png"
            alt="right"
            onClick={prevPagination}
          />
          <img
            className={`sfx-music-arrows d-none`}
            id="sfx-music-arrow-right"
            src="/image/arrow-left.png"
            alt="left"
            onClick={NextPagination}
          ></img>
          {/* genAI custom create btn */}
        </div>
      </div>
    </>,
    document.body,
  );
};

export default SfxModal;
