import { createSlice } from "@reduxjs/toolkit";

const initialProjectData = {
  projectTitle: "",
  env: "",
  nestedRecordTitle: "",
};

const projectTitleSlice = createSlice({
  name: "projectName",
  initialState: initialProjectData,
  reducers: {
    updateProjectTitle(state, action) {
      state.projectTitle = action.payload.projectTitle;
      state.env = action.payload.env;
    },
    updateProjectTitleEnv(state, action) {
      state.env = action.payload.env;
    },
    updateNestedRecordTitle(state, action) {
      state.nestedRecordTitle = action.payload.nestedRecordTitle;
      console.log(state.nestedRecordTitle);
    },
    clearProjectData(state) {
      state.env = "";
      state.projectTitle = "";
      state.nestedRecordTitle = "";
    },
  },
});

export const projectTitleSliceActions = projectTitleSlice.actions;
export default projectTitleSlice.reducer;
